import { Box, Button } from "@chakra-ui/react";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";
import { Descendant } from "slate"; // Import Descendant type from slate
import { useState } from "react";
import PropTypes from "prop-types";
import infoIcon from "../../assets/svgs/info-icon.svg";
import profileIcon from "../../assets/svgs/profile-icon.svg";
import arrowTopIcon from "../../assets/svgs/arrow-top.svg";

// Define the structure of the personalization prop
type Personalization = {
  type: string;
  children: { text: string }[];
};

interface EditPresetProps {
  personalization: Personalization[];
  setPersonalization: (newPersonalization: Descendant[]) => void; // Expecting Descendant[]
}

const EditPreset = ({
  personalization,
  setPersonalization,
}: EditPresetProps) => {
  const [isLoading, setIsLoading] = useState(false); // Track the loading state

  // Convert personalization to Descendant format before passing to setSlateValue
  const convertToSlateDescendants = (
    personalization: Personalization[],
  ): Descendant[] => {
    return personalization.map((item) => ({
      type: item.type,
      children: item.children.map((child) => ({
        text: child.text,
      })),
    }));
  };

  // Handle saving of the preset
  const handleSavePreset = async () => {
    try {
      setIsLoading(true); // Set loading state to true when save starts

      // Simulate save operation (replace this with actual save logic)
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating delay

      // After successful save operation
      console.log("Personalization saved:", personalization);
    } catch (error) {
      console.error("Error saving preset:", error);
    } finally {
      setIsLoading(false); // Set loading state to false after operation completes
    }
  };

  return (
    <Box className="m-3 rounded-lg bg-gradient-to-r from-[#8E32A4] to-[#5024DC] p-[1px]">
      <div className="overflow-hidden rounded-lg bg-white">
        <div className="flex flex-col gap-2 bg-white p-4">
          <h3 className="text-sm font-medium text-[#0F0D1C]">
            Custom template
          </h3>
          <p className="flex items-center gap-2 text-[13px] text-[#7E889F]">
            <img src={infoIcon} alt="info" />
            Generate tailored responses to your leads by setting prompts.
          </p>
        </div>
        <div className="border border-x-0 border-b-0 border-[#F0EFF1] bg-white p-4">
          <div className="overflow-hidden rounded-md border border-[#D0D4DC]">
            <div className="flex items-center justify-between bg-[#F5F3FF] p-4">
              <div className="flex items-center gap-2 ">
                <img src={profileIcon} alt="profile" />
                <p className="text-sm text-[#7E889F]">Edit Preset</p>
              </div>
              <img src={arrowTopIcon} alt="arrow" className="cursor-pointer" />
            </div>
            <div className="p-2">
              <CustomTextEditor
                slateValue={convertToSlateDescendants(personalization)} // Convert personalization to Descendant[] format
                setSlateValue={setPersonalization} // This expects Descendant[]
                isInline
                placeholder="Type your input"
              />
            </div>
          </div>
          <Button
            onClick={handleSavePreset}
            className="mt-4 flex items-center gap-3 rounded-lg border border-[#BD90F4] !bg-[#C03EDC] p-4 py-2 !text-[#fff]"
            mt={4}
            isLoading={isLoading} // Set loading based on state
            isDisabled={isLoading} // Disable the button when loading to prevent multiple clicks
          >
            Save Preset
          </Button>
        </div>
      </div>
    </Box>
  );
};

// Optionally add PropTypes for runtime validation (helpful in JavaScript projects)
EditPreset.propTypes = {
  personalization: PropTypes.array.isRequired, // Array of personalization objects
  setPersonalization: PropTypes.func.isRequired,
};

export default EditPreset;
