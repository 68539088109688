import Icons from "@/components/Icons";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import {
  Text,
  useDisclosure,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Checkbox,
  Collapse,
  Button,
} from "@chakra-ui/react";
import { Select, CreatableSelect } from "chakra-react-select";
import {
  ColumnSchema,
  getDescriptionDesc,
  summaryField,
  useColumnStore,
} from "./schema.store";
import { useEffect, useMemo } from "react";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";
import { shuffle } from "lodash";
import { colorList } from "@/lib/utils";
import { convertSlateToText } from "@/utils";

export const EditableRow = ({
  column,
  isOpen,
  index,
  onEditing,
  readonly,
}: {
  column: ColumnSchema;
  isOpen: boolean;
  index: number;
  onEditing: (editing?: boolean) => void;
  readonly: boolean;
}) => {
  const {
    updateColumn,
    removeColumn,
    addToTable,
    toggleAddToTable,
    isSummaryAdded,
    mainColumn,
    columns,
  } = useColumnStore();
  const editDisclosure = useDisclosure();
  const isMainField = useMemo(() => {
    if (isSummaryAdded()) {
      return column.name === summaryField.name;
    } else {
      return column.name === mainColumn;
    }
  }, [column.name, isSummaryAdded, mainColumn, columns]);
  const isEditing = column.readonly ? false : isOpen;
  const handleInputChange = <T extends keyof ColumnSchema>(
    index: number,
    key: T,
    value: ColumnSchema[T],
  ) => {
    updateColumn(index, { [key]: value });
  };
  const selectOptions = [
    {
      value: "string",
      label: (
        <div className="flex items-center gap-1">
          <tableActionIcons.text /> <Text>String</Text>
        </div>
      ),
    },
    {
      value: "number",
      label: (
        <div className="flex items-center gap-1">
          <tableActionIcons.number /> <Text>Number</Text>
        </div>
      ),
    },
    {
      value: "boolean",
      label: (
        <div className="flex items-center gap-1">
          <Icons.FaCheckSquare /> <Text>Boolean</Text>
        </div>
      ),
    },
    {
      value: "email",
      label: (
        <div className="flex items-center gap-1">
          <tableActionIcons.email /> <Text>Email</Text>
        </div>
      ),
    },
    {
      value: "url",
      label: (
        <div className="flex items-center gap-1">
          <tableActionIcons.url /> <Text>URL</Text>
        </div>
      ),
    },
    {
      value: "array",
      label: (
        <div className="flex items-center gap-1">
          <Icons.QueueListIcon /> <Text>List</Text>
        </div>
      ),
    },
    {
      value: "enumSingle",
      label: (
        <div className="flex items-center gap-1">
          <Icons.IoRadioButtonOnSharp /> <Text>Choice</Text>
        </div>
      ),
    },
    {
      value: "enumMulti",
      label: (
        <div className="flex items-center gap-1">
          <Icons.BsUiRadiosGrid /> <Text>Choices</Text>
        </div>
      ),
    },
  ] as const;

  useEffect(() => {
    if (column?.name === "" && isOpen) {
      editDisclosure.onOpen();
    }
  }, [column, isOpen]);
  return (
    <Box
      border={isEditing ? "1px solid #E2E8F0" : "none"}
      key={index}
      className={`group relative flex w-full flex-col gap-2  rounded p-2 ${isMainField ? "bg-[#aa6bfa24]" : ""} `}
      onFocus={() => onEditing()}
      onClick={(e) => e.isDefaultPrevented() || onEditing()}
      onKeyUp={(e) => e.key === "Escape" && onEditing(false)}
    >
      {!column.readonly && !readonly && (
        <Icons.EditIcon
          color="var(--chakra-colors-purple-500)"
          className="absolute left-[-1.5em] top-[1em] opacity-25 group-hover:opacity-100"
        />
      )}
      <Box className=" flex w-full flex-row justify-between gap-2">
        <Box className=" min-w-[6em] grow">
          <Select
            menuPosition="fixed"
            className="grow !rounded-lg [&_svg]:!size-[1em]"
            colorScheme="purple"
            isDisabled={column.readonly}
            isReadOnly={!isEditing || readonly}
            size={"sm"}
            menuPlacement="top"
            value={selectOptions.find((option) => option.value === column.type)}
            onChange={(selectedOption) =>
              handleInputChange(
                index,
                "type",
                selectedOption?.value || "string",
              )
            }
            options={selectOptions}
            components={{
              IndicatorsContainer: () => null,
            }}
            chakraStyles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
              }),
              container: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "16px",
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                px: 0,
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                w: "10em",
              }),
            }}
          />
        </Box>
        <InputGroup
          colorScheme="blue"
          size={"sm"}
          variant={isEditing ? "outline" : "flush"}
          className="!rounded"
        >
          <Input
            colorScheme="teal"
            tabIndex={3}
            isReadOnly={!isEditing || readonly}
            isDisabled={column.readonly}
            autoFocus
            flexGrow={1}
            value={column.name}
            onChange={(e) => handleInputChange(index, "name", e.target.value)}
            placeholder="Column Name"
            className="grow !rounded !bg-white"
          />
          <InputRightElement hidden={!isEditing} bg={"transparent"}>
            <IconButton
              isDisabled={!column.name || readonly}
              size={"xs"}
              aria-label="Save"
              icon={<Icons.FaCheck />}
              colorScheme="teal"
              onClick={(e) => {
                e.preventDefault();
                onEditing(false);
              }}
              variant="ghost"
            />
          </InputRightElement>
        </InputGroup>
        <label className="ml-auto flex select-none flex-row items-center gap-1 text-xs">
          <Checkbox
            isDisabled={isMainField || readonly}
            variant={"ghost"}
            isChecked={addToTable.includes(index) || isMainField}
            onChange={() => toggleAddToTable(index)}
            size="sm"
            colorScheme="purple"
          />
          <Text className="whitespace-nowrap">Create Column</Text>
        </label>
      </Box>

      {["enumSingle", "enumMulti"].includes(column.type) && (
        <label className="flex w-full flex-row items-center gap-1 p-2">
          <Text className="flex flex-row items-center gap-1 text-xs">
            {column.type === "enumSingle" ? (
              <Icons.IoRadioButtonOnSharp />
            ) : (
              <Icons.BsUiRadiosGrid />
            )}
            Choose from
          </Text>

          <CreatableSelect
            isReadOnly={!isEditing || readonly}
            className="w-full !rounded-lg"
            isMulti
            placeholder="Add options"
            value={
              column.options?.map((o) => ({
                value: o.value,
                label: o.value,
                color: o.color,
              })) ?? []
            }
            colorScheme="purple"
            menuPosition="fixed"
            size="sm"
            chakraStyles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                bg: data.color,
              }),
              container: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "16px",
                bg: "white",
              }),
            }}
            onCreateOption={(newOption) => {
              let colorPalette = column.colorPalette;
              if (!colorPalette?.length) {
                colorPalette = shuffle(colorList);
              }
              const color = colorPalette.shift() as string;
              handleInputChange(index, "colorPalette", colorPalette);
              handleInputChange(index, "options", [
                ...(column.options ?? []),
                {
                  value: newOption,
                  color,
                },
              ]);
            }}
            onChange={(selectedOptions) => {
              handleInputChange(
                index,
                "options",
                selectedOptions.map((o) => o),
              );
            }}
            isClearable
            components={{
              DropdownIndicator: () => null,
            }}
          />
        </label>
      )}
      <Collapse in={isEditing} animateOpacity>
        <Box
          sx={{
            "--tw-border-opacity": "0.2 !important",
          }}
          className="flex w-full flex-row justify-stretch gap-2"
        >
          {readonly ? (
            <Text className="!font-thin">
              {
                convertSlateToText(column.description ?? getDescriptionDesc(""))
                  .text
              }
            </Text>
          ) : editDisclosure.isOpen ? (
            <Box
              pointerEvents={readonly ? "none" : "auto"}
              className="!w-full !bg-white"
            >
              <CustomTextEditor
                slateValue={column.description ?? getDescriptionDesc("")}
                setSlateValue={(newInputText) => {
                  handleInputChange(index, "description", newInputText);
                }}
                placeholder="Description (explain what this column should do)"
              />
            </Box>
          ) : (
            <Button
              variant={"ghost"}
              size="xs"
              className="!font-thin hover:underline"
              onClick={() => editDisclosure.onOpen()}
            >
              {column.description ? "Edit Description" : "Add Description"}
            </Button>
          )}
        </Box>
      </Collapse>
      <IconButton
        hidden={readonly || column.readonly}
        onFocus={(e) => e.stopPropagation()}
        aria-label="Delete"
        icon={<Icons.FaTimes />}
        colorScheme="red"
        onClick={() => removeColumn(index)}
        size="sm"
        variant="flushed"
        className="!absolute right-[-2em] top-[1.1em] opacity-75 group-hover:opacity-100"
      />
    </Box>
  );
};
