import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { useModalStore } from "@/stores/modal.store";
import Swal from "sweetalert2";
import { queryClient } from "@/lib/queryClient";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";

export const SidebarRowMenu = ({
  tableId,
  handleCloneCallback,
  setIsRefetchingTables,
  ...props
}: any) => {
  const toast = useToast();
  const menuDisclosure = useDisclosure();
  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync } = useMutation({
    mutationFn: (tableId: string) => tableService.deleteTable(tableId),
  });

  const handleDeleteTable = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(tableId, {
          onSuccess: async (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }
            toast({
              title: "Table deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            setIsRefetchingTables(true);
            await queryClient.refetchQueries({
              queryKey: ["tables"],
            });
            setIsRefetchingTables(false);
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
        });
      },
    });
  };

  return (
    <Menu
      {...menuDisclosure}
      placement="bottom-end"
      gutter={8}
      variant={"persana"}
      closeOnBlur
    >
      <MenuButton
        onClickCapture={(e) => e.preventDefault()}
        {...props}
        as={IconButton}
        size={"xs"}
        icon={<Icons.MoreIcon />}
        onClick={() => {
          menuDisclosure.onToggle();
        }}
      />
      <Portal>
        <MenuList zIndex={"dropdown"} transition={"all 0s ease"} as="ul">
          <MenuItem
            onClick={() => {
              if (!tableId) return;

              updateState({
                isOpenModal: true,
                modalData: {
                  modalType: "clone-workflow",
                  metaData: {
                    tableId,
                    handleCloneCallback: handleCloneCallback,
                  },
                },
              });
              menuDisclosure.onClose();
            }}
            icon={<Icons.CopyIcon className="text-black-500" />}
          >
            Clone Workflow
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!tableId) return;
              handleDeleteTable();
              menuDisclosure.onClose();
            }}
            icon={<Icons.DeleteBinIcon className="text-red-500" />}
          >
            Delete Workflow
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
