import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import PeopleSearch from "../PeopleSearch";

const PeopleSearchModal = () => {
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };
  return (
    <Modal
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "search-people"}
      isCentered
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent>
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalBody className="overflow-auto">
          <PeopleSearch />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PeopleSearchModal;
