/* eslint-disable react-hooks/exhaustive-deps */
import {
  ColDef,
  RowClassRules,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import NoResult from "../Common/NoResult";
import ResultScreenLoader from "../Common/ResultScreenLoader";

import { userStore } from "@/stores/user.store";

import BeginSearch from "../Common/BeginSearch";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import PricingPopup from "../Common/PricingPopup/PricingPopup";
import { CompanyNameRenderer } from "./CompanyNameRenderer";
import Pagination from "./Pagination";
import CompanySearchResultTableActions from "./CompanySearchResultTableActions";
import Icons from "../Icons";
import CustomSelection from "./CustomSelection";
import { CompanyDescriptionRenderer } from "./CompanyDescriptionRenderer";
import { CompanyWebsiteRenderer } from "./CompanyWebsiteRenderer";

// type CompanySearchResultTableProps = {
//   uniqueSocketId: string;
// };

const CompanySearchResultTable = () => {
  const [showPopup, setShowPopup] = useState(false); // State for showing/hiding popup
  const { searchLeadsResult, updateState } = useSearchLeadsStore();
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.currentSearchPayload,
  );
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;

  const gridRef = useRef<AgGridReact>(null);
  const [formattedProfilesData, setFormattedProfilesData] = useState<any[]>([]);
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "",
        checkboxSelection: true,
        pinned: "left",
        headerCheckboxSelection: false,
        width: 80,
      },
      {
        field: "name",
        headerName: "Name",
        cellRenderer: CompanyNameRenderer,
        pinned: false,
        minWidth: 250,
        maxWidth: 300,
      },
      {
        field: "website",
        headerName: "Website",
        cellRenderer: CompanyWebsiteRenderer,
        minWidth: 250,
        maxWidth: 300,
        autoHeight: true,
        cellStyle: {
          wordBreak: "break-word",
          whiteSpace: "normal",
          lineHeight: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
      {
        field: "description",
        headerName: "Description",
        cellRenderer: CompanyDescriptionRenderer,
        minWidth: 400,
        maxWidth: 500,
        autoHeight: true,
        cellStyle: {
          wordBreak: "break-word",
          whiteSpace: "normal",
          lineHeight: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
        resizable: false,
      },
      {
        field: "staffCount",
        headerName: "Total Staff",
        minWidth: 250,
        maxWidth: 300,
      },
      {
        field: "followers",
        headerName: "Followers",
        minWidth: 250,
        maxWidth: 300,
      },
      // {
      //   field: "revenue",
      //   headerName: "Revenue",
      //   minWidth: 250,
      //   maxWidth: 300,
      // },
      {
        field: "tagline",
        headerName: "Tagline",
        minWidth: 250,
        maxWidth: 300,
        autoHeight: true,
        cellStyle: {
          wordBreak: "break-word",
          whiteSpace: "normal",
          lineHeight: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
      {
        field: "location",
        headerName: "Location",
        minWidth: 250,
        maxWidth: 300,
      },
      {
        field: "industries",
        headerName: "Industries",
        minWidth: 350,
        maxWidth: 400,
        autoHeight: true,
        cellStyle: {
          wordBreak: "break-word",
          whiteSpace: "normal",
          lineHeight: "15px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      cellStyle: {
        borderRight: "1px solid #e2e8f0",
        display: "flex",
        alignItems: "center",
      },
      editable: false,
      cellDataType: false,
    };
  }, []);

  const rowClassRules: RowClassRules =
    planType?.toLocaleLowerCase() === "free"
      ? {
          "blurred-row": (params) => params.rowIndex > 24, // Apply class to rows after index 24
        }
      : {};

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api?.getSelectedRows();
    updateState({ selectedLeads: selectedRows });
  }, []);

  const formatData = (profiles: any) => {
    const formattedData = profiles?.map((item: any) => ({
      id: item?._id || "",
      name: item?.name || "",
      linkedInUrl: item?.li_url || "",
      website: item?.website || "",
      description: item?.description || "",
      staffCount: item?.staff_count || "",
      tagline: item?.tagline || "",
      location: item?.location_city || "",
      followers: item?.followers || "",
      industries: item?.industries || "",
      revenue: item?.revenue || "",
    }));
    setFormattedProfilesData(formattedData);
  };

  useEffect(() => {
    if (searchLeadsResult && searchLeadsResult?.profiles?.length > 0) {
      formatData(searchLeadsResult?.profiles);
    }
  }, [searchLeadsResult]);

  const onBodyScroll = useCallback(() => {
    if (planType?.toLocaleLowerCase() === "free") {
      const rowIndexToWatch = 25;
      const rowNode =
        gridRef.current?.api?.getDisplayedRowAtIndex(rowIndexToWatch);

      if (rowNode) {
        const rowElement = document.querySelector(
          `.ag-center-cols-container [row-index='${rowIndexToWatch}']`,
        );

        const gridBody = document.querySelector(".ag-body-viewport");
        if (rowElement && gridBody) {
          const rowRect = rowElement.getBoundingClientRect();
          const gridRect = gridBody.getBoundingClientRect();

          if (rowRect.top <= gridRect.top) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const api = gridRef.current?.api;
    if (api) {
      api?.addEventListener("bodyScroll", onBodyScroll);
      return () => api?.removeEventListener("bodyScroll", onBodyScroll);
    }
  }, [onBodyScroll]);

  useEffect(() => {
    const maxSelectableRows =
      planType?.toLocaleLowerCase() === "free" ? 25 : Infinity;

    gridRef.current?.api?.forEachNode((row) => {
      gridRef.current?.api?.getRowNode(row.id!)?.setSelected(false);
    });

    if (selectionAction === "select-all") {
      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index < maxSelectableRows) {
            gridRef.current?.api?.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNode((row) => {
          gridRef.current?.api?.getRowNode(row.id!)?.setSelected(true);
        });
      }
    } else if (selectionAction === "clear-all") {
      gridRef.current?.api?.forEachNode((row) => {
        gridRef.current?.api?.getRowNode(row.id!)?.setSelected(false);
      });
      updateState({
        selectionCount: 0,
      });
    } else if (selectionAction === "select-page") {
      const currentPage =
        gridRef?.current?.api?.paginationGetCurrentPage() || 0;
      const pageSize = gridRef?.current?.api?.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (
            index >= startRow &&
            index < endRow &&
            index < maxSelectableRows
          ) {
            gridRef?.current?.api?.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index >= startRow && index < endRow) {
            gridRef?.current?.api?.getRowNode(row.id!)?.setSelected(true);
          }
        });
      }
    } else if (selectionAction === "range-selection") {
      const currentPage =
        gridRef?.current?.api?.paginationGetCurrentPage() || 0;
      const pageSize = gridRef?.current?.api?.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      const rowsToSelect =
        planType?.toLocaleLowerCase() === "free"
          ? Math.min(selectionCount, maxSelectableRows)
          : Math.min(selectionCount, pageSize);
      let selectedCount = 0;

      gridRef?.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
        if (
          index >= startRow &&
          index < endRow &&
          selectedCount < rowsToSelect
        ) {
          gridRef?.current?.api?.getRowNode(row.id!)?.setSelected(true);
          selectedCount++;
        }
      });
    }
  }, [selectionAction, selectionCount, planType]);

  useEffect(() => {
    if (selectionAction === "select-all") {
      const maxSelectableRows =
        planType?.toLocaleLowerCase() === "free" ? 25 : Infinity;

      gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
        if (index < maxSelectableRows) {
          row.setSelected(true);
        } else {
          row.setSelected(false);
        }
      });
    }
  }, [
    selectionAction,
    searchLeadsResult?.pagination?.page,
    formattedProfilesData,
    planType,
  ]);

  if (searchingFor === "COMPANY") {
    return <ResultScreenLoader />;
  }

  if (!searchLeadsResult?.profiles) {
    return <BeginSearch />;
  }

  if (searchLeadsResult?.profiles?.length === 0) {
    return <NoResult />;
  }

  return (
    <>
      <div className="my-2 flex items-center gap-[12px]">
        <CustomSelection />
        <CompanySearchResultTableActions
          buttonIcon={<Icons.BsMagic />}
          buttonText={"Enrich with Persana"}
          currentSearchPayload={currentSearchPayload}
          variant="secondary"
        />
      </div>
      <div className="relative flex size-full flex-col justify-between">
        <div
          className="ag-theme-quartz custom-ag-grid relative"
          style={{
            width: "100%",
            height: "calc(100vh - 150px)",
          }}
        >
          {showPopup && planType?.toLocaleLowerCase() === "free" && (
            <PricingPopup setShowPopup={setShowPopup} />
          )}
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={formattedProfilesData}
            defaultColDef={defaultColDef}
            rowClassRules={rowClassRules}
            headerHeight={40}
            rowHeight={50}
            rowClass="border-1 border-gray-200"
            rowSelection="multiple"
            rowMultiSelectWithClick
            suppressColumnMoveAnimation
            suppressRowClickSelection
            onSelectionChanged={onSelectionChanged}
            pagination={false}
            autoSizeStrategy={{
              type: "fitCellContents",
            }}
            onBodyScroll={onBodyScroll}
          />
          <Pagination
            paginationData={searchLeadsResult.pagination}
            className="mb-3 rounded-b-[6px] bg-[#f7f7f7] px-4 py-2"
          />
        </div>
      </div>
    </>
  );
};

export default CompanySearchResultTable;
