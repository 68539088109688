import CompanyIcon from "@/assets/svgs/company-icon.svg";
import CompanyRevenue from "@/assets/svgs/company-revenue.svg";
import FindWork from "@/assets/svgs/3-find-work-image.svg";
import CompanyHiring from "@/assets/svgs/4-companies-hiring-image.svg";
import CompanyNews from "@/assets/svgs/11-company-personalization-image.svg";
import CompanyPricing from "@/assets/svgs/8-company-pricing-image.svg";
import CompanyPersonalization from "@/assets/svgs/11-company-personalization-image.svg";
import CompanyCompetitors from "@/assets/svgs/4-companies-hiring-image.svg";
import WebsiteInsight from "@/assets/svgs/9-website-insights-image.svg";

import Icons from "@/components/Icons";

export type TemplateGroup =
  (typeof TemplateGroups)[keyof typeof TemplateGroups];
export const TemplateGroups = {
  COMPANY_SEARCH: {
    name: "Company Search & Tracking",
    icon: Icons.FaBuildingCircleCheck,
    title: "Company Search",
  },
  // PROFILE_ENRICHMENT: "Profile Enrichment & People Search",
  // SAAS_MARKET_INSIGHTS: "SaaS & Market Insights",
  PROFILE_ENRICHMENT: {
    name: "Profile Enrichment & People Search",
    icon: Icons.PeopleIcon,
    title: "People Search",
  },
  SAAS_MARKET_INSIGHTS: {
    name: "SaaS & Market Insights",
    icon: Icons.CompanyDetailsIcon,
    title: "Insights",
  },
} as const;

export type TemplateTypes = {
  templateId: string;
  heading: string;
  title: string;
  url: string;
  image: string | ((props: any) => JSX.Element);
  group: TemplateGroup;
  isInternalRedirection: boolean;
  redirectionLink?: string;
  isPlaybookTemplate?: boolean;
  credits: string;
};

export const allTemplates: TemplateTypes[] = [
  {
    templateId: "1",
    heading: "Find Companies",
    title: "Find whether companies are SOC 2 compliant",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZlYjQyY2NiZTczNTczM2I1NThmNDJiIiwiaWF0IjoxNzI2Njk3NzE3LCJleHAiOjE3NTgyNTUzMTd9.aI_LpLdebpg3xh1gHqFxlpNnTewxnd0pF1V5A8kinf0",
    image: CompanyIcon,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "2",
    heading: "Find LinkedIn URL",
    title: "Find linkedIn URL from work email",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZlYzJmM2E4ZWU1M2RlMjIyZTM5MTc1IiwiaWF0IjoxNzI2NzU0OTA1LCJleHAiOjE3NTgzMTI1MDV9.oTqrg-cWW_hvdmsoCKlta_hMzhQqoYsAPLElZwGgPDE",
    image: (props) => <Icons.LinkIcon color="#8E32A4" {...props} />,
    group: TemplateGroups.PROFILE_ENRICHMENT,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "3",
    heading: "Find Work Email (Waterfall)",
    title: "Find email with name and company using waterfall model",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzFiMzQyMzJkOGUyY2ViZGZkZmJkIiwiaWF0IjoxNzI3MjA4NDMwLCJleHAiOjE3NTg3NjYwMzB9.lDkbyzECZqFIRnlwevQjdhOtKtDYVr9mlKAsCHieqBk",
    image: FindWork,
    group: TemplateGroups.PROFILE_ENRICHMENT,
    isInternalRedirection: true,
    redirectionLink: "/tools",
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "4",
    heading: "Track Companies Hiring",
    title: "Track a list of companies for hiring",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzBiYjRmZWUxNjU1MGViYjFmMGY1IiwiaWF0IjoxNzI3MjA3NTkxLCJleHAiOjE3NTg3NjUxOTF9.ORYbfJDuAsazI29uxArSRSj-g3_ep201oIhRp27UWh4",
    image: CompanyHiring,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "5",
    heading: "Find Company News",
    title: "Find company news from company URL",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzE4Yzk0ZjQ5ZTUyN2Q4Y2U0OTRlIiwiaWF0IjoxNzI3MjA4MTk1LCJleHAiOjE3NTg3NjU3OTV9.vFjp5l9SrS7fxrhFNglW1o8LjH8u8VG57VmRJfnvQro",
    image: CompanyNews,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "6",
    heading: "Find Company Pricing",
    title: "Find company pricing using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNhMDU3N2VkMzU0ZmVhYTUyYmI5IiwiaWF0IjoxNzI3MjE2NTMwLCJleHAiOjE3NTg3NzQxMzB9.wMdc_YlpLjdlQgaYqxPTVYRWF5p_CXBHHwABIfaSdG0",
    image: CompanyPricing,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "2",
  },
  {
    templateId: "7",
    heading: "Find Company Revenue",
    title: "Find company revenue using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNjMTAxOWQ5OWRlMWNiZDYyMGUxIiwiaWF0IjoxNzI3MjE2OTA1LCJleHAiOjE3NTg3NzQ1MDV9.o5IQjIc6ZII28pqguPSYpCBAaPj9jJapffWwEz6y9cs",
    image: CompanyRevenue,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "2",
  },
  {
    templateId: "8",
    heading: "Find Company Competitors",
    title:
      "Survey competitors and pinpoint differences using Persana's Quantum Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMzNkMjUyMzg3NzI2NjdmNzdiYzQ5IiwiaWF0IjoxNzI3MjE3MDY1LCJleHAiOjE3NTg3NzQ2NjV9.L3OHuPwGnd8Jf64Dvr0T4rnqDY0E3p_-ZwAES7U-4us",
    image: CompanyCompetitors,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "2",
  },
  {
    templateId: "9",
    heading: "Find Website Insights",
    title: "Find website visitor traffic and other insights of a website",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmM2M5YTBiNjFiM2U5NzIzZjMyMTEyIiwiaWF0IjoxNzI3MjUzMDIzLCJleHAiOjE3NTg4MTA2MjN9.r6dY8Aj9eHXwH-K9zBH4IqUPWpaXX18Eu1aTb_MItgQ",
    image: WebsiteInsight,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "2",
  },
  {
    templateId: "10",
    heading: "Podcast Appearance",
    title: "Find podcast appearance + write personalized first line:",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmNmViNmU5ZmZjYTNiMjRjZTk4NTI3IiwiaWF0IjoxNzI3NDU4OTE1LCJleHAiOjE3NTkwMTY1MTV9.stPttvRoZVIJOkKMVWT_FTYCqiiPO_tnByu7JnEoPPA",
    image: WebsiteInsight,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "2-4",
  },
  {
    templateId: "11",
    heading: "Company Personalization",
    title: "Personalize company using company enrichment and AI Agent.",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjZmMjQwNzdmZWUxNjU1MGViYThmMWY5IiwiaWF0IjoxNzI3MTUyNTc2LCJleHAiOjE3NTg3MTAxNzZ9.kMXr45ZvpoqmD7SFdtWPOESj_eTD4hOhEyLJB5r0j2Y",
    image: CompanyPersonalization,
    group: TemplateGroups.COMPANY_SEARCH,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1-3",
  },
  {
    templateId: "12",
    heading: "Write personalized emails to prospects",
    title:
      "Generate personalized first lines using prospects' LinkedIn and find their email:",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjcxNzVkMzM3YjRjODY4OTJmNGE1MmM1IiwiaWF0IjoxNzI5NTg1NDMzLCJleHAiOjE3NjExNDMwMzN9._r7IEfdKcSx4SMDpW6_MqzPubDcKpXTiiVakYO8TqZ4",
    image: CompanyCompetitors,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1",
  },
  {
    templateId: "13",
    heading: "Write personalized emails to prospects based on news.",
    title: "Generate personalized first lines based on recent company news ",
    url: "https://www.platform.persana.ai/table-preview?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZUlkIjoiNjcxODNhMTEzNTY2YTM2YmY1OGFjODVhIiwiaWF0IjoxNzI5NjQxMzExLCJleHAiOjE3NjExOTg5MTF9.IXJuURO82-SGm3yGXgfiGY25rtJLCRLtUVqfK0UIt_Q",
    image: CompanyCompetitors,
    group: TemplateGroups.SAAS_MARKET_INSIGHTS,
    isInternalRedirection: false,
    isPlaybookTemplate: true,
    credits: "1-2",
  },
];
