import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Spinner, Switch, useToast } from "@chakra-ui/react";
import { ColDef, GridApi } from "ag-grid-community";
import useAutopilotTableList from "./hooks/useAutopilotJobs";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import { queryClient } from "@/lib/queryClient";

const AutopilotJobsManager = () => {
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      cellStyle: {
        borderRight: "1px solid #e2e8f0",
        display: "flex",
        alignItems: "center",
      },
      editable: false,
      cellDataType: false,
    };
  }, []);
  const [autopilotTables, setAutopilotTables] = useState<any[]>([]);
  const {
    data: allTables,
    isLoading,
    isFetching,
    refetch,
  } = useAutopilotTableList();

  const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
  const toast = useToast();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ tableId, enable }: { tableId: string; enable: boolean }) =>
      tableService.updateTableDataV2(tableId, {
        metaData: {
          isDisabledAutopilot: enable,
        },
      }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["autopilotTables"] });
    },
  });

  const handleTable = async (tableId: string, status: boolean) => {
    await mutateAsync(
      { tableId, enable: status },
      {
        onSuccess(response: any) {
          if (response?.success) {
            toast({
              title: "Success",
              description: "Table status updated successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Error",
              description:
                response?.error?.message || "Failed to update table status!",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
      },
    );
  };

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: "Table ID",
          field: "_id",
          sortable: true,
          filter: true,
          width: 250,
        },
        {
          headerName: "Table Name",
          field: "name",
          filter: true,
          sortable: true,

          width: 300,
          cellRenderer: (params: any) => (
            <div className="flex flex-row items-center truncate">
              <span className="ml-2 truncate">{params?.value || ""}</span>
            </div>
          ),
        },
        {
          headerName: "Workflow Type",
          field: "workflowType",
          filter: true,
          width: 200,
          sortable: true,
        },
        {
          headerName: "User Email",
          field: "email",
          filter: true,
          width: 300,
          sortable: true,
          cellRenderer: (params: any) => (
            <div className="flex flex-row items-center truncate">
              <span className="ml-2 truncate">{params.value}</span>
            </div>
          ),
        },
        {
          headerName: "Trigger",
          field: "trigger",
          filter: true,
          width: 200,
          cellRenderer: (params: any) => {
            const freq = params.value;
            return (
              <div className="flex flex-row items-center truncate">
                <span className="ml-2 truncate">
                  {freq === "1"
                    ? "Daily"
                    : freq === "7"
                      ? "Weekly"
                      : freq === "15"
                        ? "Biweekly"
                        : "Monthly"}
                </span>
              </div>
            );
          },
        },
        {
          headerName: "Is Active",
          field: "isActive",
          sortable: false,
          width: 100,
          cellRenderer: (params: any) => {
            return (
              <Switch
                isChecked={params.value === undefined ? true : !params.value}
                size="sm"
                colorScheme="purple"
                onChange={(e) => {
                  handleTable(params.data?._id, !e.target.checked);
                }}
                id="sequence-switch"
                className="text-[14px] flex font-medium"
              />
            );
          },
        },
      ] as ColDef<any>[],
    [],
  );

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (isFetching || isPending) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }

    if (!allTables?.length) {
      gridApi?.showNoRowsOverlay();
    }
  }, [isFetching, isPending, gridApi, allTables ?? null]);

  useEffect(() => {
    const formattedTables = allTables?.map((item) => ({
      ...item,
      // @ts-ignore
      email: item?.userId?.Email || "",
      trigger: item?.metaData?.frequencyToUpdate || "",
      isActive: item?.metaData?.isDisabledAutopilot,
    }));
    setAutopilotTables(formattedTables || []);
  }, [allTables]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative flex size-full flex-col justify-between">
          <div
            className="ag-theme-quartz custom-ag-grid relative"
            style={{
              width: "100%",
              height: "calc(100vh - 100px)",
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={autopilotTables}
              suppressRowClickSelection
              onGridReady={onGridReady}
              rowClass="border-1 border-gray-200"
              defaultColDef={defaultColDef}
              headerHeight={40}
              rowHeight={50}
              rowSelection="multiple"
              suppressColumnMoveAnimation
              pagination
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AutopilotJobsManager;
