import StarterChecklist from "@/components/StarterChecklist";
import { Flex } from "@chakra-ui/react";
import SwitchWorkspace from "./SwitchWorkspace";
import ExpertCard from "./ExpertCard";

const RightSection = () => {
  return (
    <Flex flexDirection="column" w="full">
      <SwitchWorkspace />
      <ExpertCard />
      <StarterChecklist />
      {/* <StarterPlaybooksList /> */}
    </Flex>
  );
};

export default RightSection;
