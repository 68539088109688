import { Checkbox } from "@chakra-ui/react";
import PropTypes from "prop-types";

// Define types for the props
interface TemplateSidebarProps {
  checkboxList: { id: string; item: string }[]; // checkboxList is an array of objects with 'id' and 'item' properties
  onCategoryChange: (categoryId: string) => void;
  selectedGroups: { name: string }[]; // selectedGroups is an array of objects with 'name' property
}

const TemplateSidebar: React.FC<TemplateSidebarProps> = ({
  checkboxList,
  onCategoryChange,
  selectedGroups,
}) => {
  return (
    <div className="enrichment-modal max-h-full w-[25%] border-r px-6 py-4">
      <div className="rounded-[6px] border pb-[8px]">
        <p className="w-full bg-gray px-4 pb-2 pt-4 text-[16px] font-medium text-[#0F0D1C]">
          Categories
        </p>
        <div className="flex max-h-[312px] flex-col overflow-y-auto px-4">
          {checkboxList.map((checkbox) => (
            <div
              key={checkbox.id}
              className={`flex w-full cursor-pointer items-center gap-[15px]  py-[12px] `}
              // onClick={() => handleCategory(item)}
            >
              <Checkbox
                // value={item}
                //  isChecked={item === activeCategory}
                isChecked={selectedGroups.some(
                  (group) => group.name === checkbox.item,
                )}
                value={checkbox.item}
                onChange={() => onCategoryChange(checkbox.id)}
              >
                <p className={`text-[14px] font-medium leading-[16px]`}>
                  {checkbox.item}
                </p>
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// PropTypes for runtime validation (optional)
TemplateSidebar.propTypes = {
  checkboxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  selectedGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default TemplateSidebar;
