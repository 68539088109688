import { useState, useEffect } from "react";
import SlackIcon from "@/assets/svgs/slack-black.svg";
import ExtensionIcon from "@/assets/svgs/extension.svg";
import ArrowRightIcon from "@/assets/svgs/arrow-right.svg";
import Icons from "../Icons";
import { useModalStore } from "@/stores/modal.store";

const checklistItems = [
  // {
  //   id: "3",
  //   title: "Watch Getting Started Guide",
  //   icon: StartIcon,
  //   onClick: () => {
  //     window.open("https://persana.ai/learn-persana/persana-academy", "_blank");
  //   },
  // },
  {
    id: "1",
    title: "Join our Slack community",
    icon: SlackIcon,
    onClick: () => {
      window.open(
        "https://join.slack.com/t/persanaaicommunity/shared_invite/zt-2hkyc3iaf-HQYWmcTIQnT9I6tcou4XEA",
        "_blank",
      );
    },
  },
  {
    id: "2",
    title: "Install LinkedIn Prospect Finder Extension",
    icon: ExtensionIcon,
    onClick: () => {
      window.open(
        "https://www.google.com/url?q=https://chrome.google.com/webstore/detail/persana-ai-chatgpt-for-li/jdbdgcibmddkccanncenaahimbfcgglj&sa=D&source=docs&ust=1702141765964951&usg=AOvVaw14JP3i-1wfyBkNMATHv2Nq",
        "_blank",
      );
    },
  },
  // {
  //   id: "4",
  //   title: "Learn How to Find Your First Set Of Leads",
  //   icon: LeadIcon,
  //   onClick: () => {
  //     useModalStore.getState().updateState({
  //       isOpenModal: true,
  //       modalData: {
  //         modalType: "apollo-people-search",
  //       },
  //     });
  //   },
  // },
  // {
  //   id: "5",
  //   title: "Book a free Onboarding Call",
  //   icon: PhoneIcon,
  //   onClick: () => {
  //     window.open(
  //       "https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding",
  //       "_blank",
  //     );
  //   },
  // },
];

const ListSection = () => {
  const [completedSteps, setCompletedSteps] = useState(() => {
    const savedSteps = localStorage.getItem("completedSteps");
    return savedSteps ? JSON.parse(savedSteps) : [];
  });

  useEffect(() => {
    localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
  }, [completedSteps]);

  const handleItemClick = (id: string, onClick: any) => {
    if (!completedSteps.includes(id)) {
      setCompletedSteps([...completedSteps, id]);
    }
    onClick();
  };

  // const progress = (completedSteps.length / checklistItems.length) * 100;

  return (
    <div>
      {/* <div className="mb-[24px] px-[24px]">
        <ProgressBar
          progress={progress}
          stepsCompleted={completedSteps.length}
          totalSteps={checklistItems.length}
        />
      </div> */}
      <>
        {checklistItems.map(({ id, title, icon, onClick }, ind) => (
          <div
            key={id}
            className={`border-b border-[#D0D4DC] py-[16px] cursor-pointer hover:bg-[#F0EFF1] ${ind === 0 && "rounded-t-[12px]"}`}
            onClick={() => handleItemClick(id, onClick)}
          >
            <div className="flex items-center justify-between px-[24px]">
              <div className="flex items-center gap-[16px]">
                <img src={icon} alt="" height={16} width={16} />
                <p className="text-[14px] leading-[20px] text-[#0F0D1C]">
                  {title}
                </p>
              </div>
              {completedSteps.includes(id) ? (
                <Icons.CheckIcon color="#00B41D" size={20} />
              ) : (
                <img src={ArrowRightIcon} alt="" width={12} height={12} />
              )}
            </div>
          </div>
        ))}
      </>
      <div
        className="w-full flex items-center justify-center gap-2 bg-[#F5F3FF] py-[12px] rounded-b-[12px] cursor-pointer"
        onClick={() => {
          useModalStore.getState().updateState({
            isOpenModal: true,
            modalData: {
              modalType: "referral",
            },
          });
        }}
      >
        <Icons.GiftIcon className="text-[#5024DC] w-[16] h-[16]" />
        <p className="text-[#000] text-[14px] font-medium">
          Refer & earn free credits
        </p>
      </div>
    </div>
  );
};

export default ListSection;
