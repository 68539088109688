export type TemplateTypes = {
  templateId: string;
  heading: string;
  url: string;
  strength: string;
};

export const allTemplates: TemplateTypes[] = [
  {
    templateId: "1",
    heading: "Find work email",
    url: "https://persana.ai/learn-persana/work-email-finder-bounce-tested",
    strength: "99% accuracy",
  },
  {
    templateId: "2",
    heading: "Use AI Draft email feature",
    url: "https://persana.ai/learn-persana/how-to-use-the-ai-draft-email-feature-for-personalization",
    strength: "High reply rate",
  },
  {
    templateId: "3",
    heading: "Find people at company",
    url: "https://persana.ai/learn-persana/how-to-find-decision-makers-at-companies-using-persana",
    strength: "700M contacts",
  },
];
