import { useEffect, useState } from "react";
import "@xyflow/react/dist/style.css";

import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import HelpSteps from "./HelpSteps";
import WorkFlow from "./WorkFlow";

import { useTableList } from "@/hooks/useTableList";
import { TableData } from "@/types/table.types";
import AutoPilotSidebar from "../Common/AutoPilot/Sidebar";

const AutoPilotLeadsWorkflow: React.FC = () => {
  const [needHelp, setNeedHelp] = useState(false);
  const [selectedTable, setSelectedTable] = useState<Partial<TableData>>({});

  const { data: allTables, refetch, isPending } = useTableList();

  const autoPilotTables = allTables?.filter(
    (item) => item.workflowType === "find-people",
  );

  useEffect(() => {
    if (autoPilotTables && autoPilotTables.length && !selectedTable?._id) {
      setSelectedTable(autoPilotTables[0]);
    }
  }, [allTables, autoPilotTables]);

  return (
    <>
      <div
        className="grid min-h-screen"
        style={{
          gridTemplateColumns: "repeat(1, 25% 1fr)",
        }}
      >
        <AutoPilotSidebar
          title="Find New Leads"
          flowName="find_new_leads"
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          autoPilotTables={autoPilotTables}
          isLoading={isPending}
        />
        <div>
          <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
            <WorkFlow autoPilotTable={selectedTable} refetch={refetch} />
          </TabContext.Provider>
          <HelpSteps needHelp={needHelp} setNeedHelp={setNeedHelp} />
        </div>
      </div>
    </>
  );
};

export default AutoPilotLeadsWorkflow;
