import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Tag,
  TagLabel,
  Wrap,
  useToast,
} from "@chakra-ui/react";

import BCHeader from "@/assets/svgs/bg-grad.svg";

import { useModalStore } from "@/stores/modal.store";
import { useState } from "react";
import { CATEGORY_OPTIONS, COUNTRY_OPTIONS } from "./constants";

import enrichment from "@/services/enrichment.service";
import { useNavigate } from "@tanstack/react-router";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import SearchPersanaInput from "@/components/Common/PersanaInput/SearchPersanaInput";

const PandaMatchSearchModal = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [domain, setDomain] = useState<Option[]>([]);
  const [keyword, setKeyword] = useState<Option[]>([]);
  const [category, setCategory] = useState<Option>();
  const [country, setCountry] = useState<Option>();
  const [companySize, setCompanySize] = useState<Option>();
  const [isLoading, setIsLoading] = useState(false);
  const [minScore, setMinScore] = useState<number>();
  const [maxScore, setMaxScore] = useState<number>();
  const [minSimilarity, setMinSimilarity] = useState<number>();
  const [maxRecords, setMaxRecords] = useState<number>();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  // const addDomain = (newDomain: string) => {
  //   if (newDomain && !domain.includes(newDomain) && domain.length < 10) {
  //     setDomain([...domain, newDomain]);
  //   }
  //   setInputText("");
  // };

  // const removeDomain = (index: number) => {
  //   setDomain(domain.filter((_, i) => i !== index));
  // };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setInputText(e.target.value);
  // };

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter" || e.key === ",") {
  //     e.preventDefault();
  //     addDomain(inputText.trim());
  //   } else if (e.key === "Backspace" && inputText === "" && domain.length > 0) {
  //     removeDomain(domain.length - 1); // Remove last domain on backspace
  //   }
  // };

  const fetchPandaMatchCompanies = async () => {
    // const domainList = domain
    //   .split(",") // Split by commas
    //   .map((d) => d.trim()) // Remove spaces
    //   .filter((d) => d.length > 0) // Remove empty values
    //   .filter((d) => /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(d)) // Validate domain format
    //   .slice(0, 10); // Limit to 10 domains

    // setDomain(domainList);
    try {
      setIsLoading(true);
      const response = await enrichment.pandaMatchCompanies({
        domain: domain?.map((d) => d.value)?.slice(0, 10) || [],
        keyword:
          keyword
            ?.map((k) => k.value)
            ?.slice(0, 10)
            ?.join(",") || "",
        category: category?.value,
        country: country?.value,
        minScore,
        maxScore,
        minSimilarity,
        maxRecords,
      });
      if (!response.success) {
        toast({
          title: response?.error?.message || "Something went wrong",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } else {
        const tableId = response?.data?.data?.tableData._id;

        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
        closeModal();
      }
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    if (modalData.metaData?.openPreviousModal) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started", metaData: "" },
      });
    } else {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "", metaData: "" },
      });
    }
  };

  const handleCompanySizeChange = (value: string) => {
    switch (value) {
      case "small":
        setMinScore(1);
        setMaxScore(200);
        break;
      case "mid":
        setMinScore(201);
        setMaxScore(500);
        break;
      case "large":
        setMinScore(501);
        setMaxScore(800);
        break;
      default:
        setMinScore(0);
        setMaxScore(0);
    }
  };

  return (
    <Modal
      isOpen={isOpenModal && modalData.modalType === "panda-match-search"}
      onClose={closeModal}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="!rounded-md font-poppins">
        <ModalHeader
          className="flex flex-col items-center justify-center !rounded-t-md border-b-[1px] bg-cover bg-no-repeat p-6 text-center"
          style={{ backgroundImage: `url(${BCHeader})` }}
        >
          <Text className="flex-1 text-[22px] font-medium text-[#0F0D1C]">
            Find Company Lookalikes
          </Text>
          {/* <Text className="text-semibold flex-1 text-lg text-[#0F0D1C]">
              Add relevant information to the fields to get started.
            </Text> */}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] overflow-auto">
          <div className="space-y-4">
            <div>
              <p className="pb-1 text-base font-semibold">Company Domain</p>
              <p className="pb-1 text-xs italic text-darkTextGray">
                Enter valid domains, e.g. example.com, example.co.uk You can
                input up to 10 domains..
              </p>
              <MultiSelectDropdown
                isMulti
                closeMenuOnSelect={false}
                placeholder="Enter domains."
                value={domain}
                options={[]}
                onChange={(selected) => {
                  setDomain(selected as Option[]);
                }}
              />
              {/* <Input
                  placeholder="Enter domains and press Enter"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />{" "} */}
              {/* </InputGroup> */}

              {/* <Wrap spacing={2} className="mt-2">
                {domain.map((d, index) => (
                  <Tag
                    key={index}
                    size="md"
                    variant="solid"
                    className="!flex !items-center !justify-center gap-2 !rounded-full !border !border-[#BD90F4] !bg-[#F5F3FF] !px-3 !py-1 !text-[#5024DC]"
                  >
                    <img src={StarIcon} />
                    <TagLabel>{d}</TagLabel>
                    <TagCloseButton onClick={() => removeDomain(index)} />
                  </Tag>
                ))}
              </Wrap> */}
              <p className="ml-1 mt-1 text-xs text-darkTextGray">
                Enter valid domains (e.g., example.com), separated by commas.
              </p>
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">
                Keyword{" "}
                <span className="text-xs font-[400] text-darkTextGray">
                  (Optional)
                </span>
              </p>

              <p className="pb-1 text-xs italic text-darkTextGray">
                Specify one or more of search keywords. You can input up to 10
                keywords.
              </p>

              <MultiSelectDropdown
                isMulti
                closeMenuOnSelect={false}
                placeholder="Enter keywords"
                value={keyword}
                options={[]}
                onChange={(selected) => {
                  setKeyword(selected as Option[]);
                }}
              />
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">Category</p>
              <MultiSelectDropdown
                isMulti={false}
                closeMenuOnSelect
                placeholder="Select categories"
                value={category}
                options={CATEGORY_OPTIONS}
                onChange={(selected) => setCategory(selected as Option)}
              />
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">Country</p>
              <MultiSelectDropdown
                isMulti={false}
                closeMenuOnSelect
                placeholder="Select countries"
                value={country}
                options={COUNTRY_OPTIONS}
                onChange={(selected) => setCountry(selected as Option)}
              />
              {/* <p className="ml-1 mt-1 text-xs text-darkTextGray">
                Accepts ISO-3166-2 two-letter country codes, e.g. US, CA.
              </p> */}
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">Company Size</p>
              <MultiSelectDropdown
                isMulti={false}
                closeMenuOnSelect
                placeholder="Select company size"
                value={companySize}
                options={[
                  { label: "Small Company", value: "small" },
                  { label: "Mid Company", value: "mid" },
                  { label: "Large Company", value: "large" },
                ]}
                onChange={(selected) => {
                  setCompanySize(selected as Option);
                  // @ts-ignore
                  handleCompanySizeChange(selected?.value || "");
                }}
              />
            </div>

            <div>
              <p className="pb-1 text-base font-semibold">Company Size</p>
              <Select
                placeholder="Select company size"
                onChange={(e) => handleCompanySizeChange(e.target.value)}
              >
                <option value="small">Small Company(1–100 employees)</option>
                <option value="mid">Mid Company(101–1,000 employees)</option>
                <option value="large">Large Company(1,000+ employees)</option>
              </Select>
              <Wrap spacing={2} className="mt-2">
                <Tag
                  key={0}
                  size="md"
                  variant="solid"
                  className="!flex !items-center !justify-center !rounded-full !border !border-[#BD90F4] !bg-[#F5F3FF] !px-3 !py-1 !text-[#5024DC]"
                >
                  <TagLabel>{"small businesses "}</TagLabel>
                </Tag>
                <Tag
                  key={1}
                  size="md"
                  variant="solid"
                  className="!flex !items-center !justify-center !rounded-full !border !border-[#BD90F4] !bg-[#F5F3FF] !px-3 !py-1 !text-[#5024DC]"
                >
                  <TagLabel>{"medium businesses"}</TagLabel>
                </Tag>
                <Tag
                  key={2}
                  size="md"
                  variant="solid"
                  className="!flex !items-center !justify-center !rounded-full !border !border-[#BD90F4] !bg-[#F5F3FF] !px-3 !py-1 !text-[#5024DC]"
                >
                  <TagLabel>{"large businesses"}</TagLabel>
                </Tag>
              </Wrap>
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">
                Minimum similarity{""}
                <span className="text-xs font-[400] text-darkTextGray">
                  (Optional)
                </span>
              </p>

              <p className="pb-1 text-xs italic text-darkTextGray">
                How similar they are to the original domain you provided.
              </p>

              <SearchPersanaInput
                type="number"
                name="minSimilarity"
                placeholder="Enter a number between 0-99, with 0 being least similar"
                value={minSimilarity?.toString() || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "") {
                    setMinSimilarity(undefined); // Set to undefined when input is empty
                  } else {
                    setMinSimilarity(
                      parseInt(value) > 99 ? 99 : parseInt(value),
                    );
                  }
                }}
              />
            </div>
            <div>
              <p className="pb-1 text-base font-semibold">
                Max Records{" "}
                <span className="text-xs font-[400] text-darkTextGray">
                  (Optional)
                </span>
              </p>

              <p className="pb-1 text-xs italic text-darkTextGray">
                Maximum number of records to return. Default is 25.
              </p>

              <SearchPersanaInput
                type="number"
                name="maxRecords"
                placeholder="Enter a number between 1-100"
                value={maxRecords?.toString() || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "") {
                    setMaxRecords(undefined); // Set to undefined when input is empty
                  } else {
                    setMaxRecords(
                      parseInt(value) > 100 ? 100 : parseInt(value),
                    );
                  }
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            isDisabled={!domain?.length}
            style={{
              background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%)",
              color: "#fff",
              border: "none",
              fontWeight: 500,
            }}
            onClick={fetchPandaMatchCompanies}
          >
            Find
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PandaMatchSearchModal;
