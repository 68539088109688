import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ButtonGroup,
  Button,
  IconButton,
  ModalFooter,
  UseDisclosureReturn,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import Icons from "../../../Icons";
import { useState } from "react";
import { TablePicker } from "../../../TablePicker";
import { ExportMapper } from "./ExportMapper";
interface Props {
  disclosure: UseDisclosureReturn;
  buttonLabel: string;
  buttonIcon?: JSX.Element;
  onConfirm: (
    table: {
      _id: string;
      name: string;
      fieldMap: { [field: string]: string };
      isComplete: boolean;
    } | null,
  ) => void;
  isForEmailEnrichment: boolean;
  isLoading: boolean;
  variant: "primary" | "secondary";
}
export const ExportTableMapper = ({
  disclosure,
  buttonIcon,
  isLoading,
  isForEmailEnrichment,
  onConfirm,
}: Props) => {
  const [selectedTable, setSelectedTable] = useState<{
    _id: string;
    name: string;
  } | null>(null);
  const [fieldMapping, setFieldMapping] = useState<{
    fieldMap: { [field: string]: string };
    isComplete: boolean;
  }>({
    fieldMap: {},
    isComplete: false,
  });
  const tableSelectorDisclosure = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <>
      <Modal
        size={"2xl"}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="flex items-center gap-2">
            <Icons.ViewTableIcon />
            Choose Table & Map Columns
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="relative border border-b-gray-400 !py-6">
            <Flex className="gap-2" flexDir={"column"}>
              <Flex
                position={"sticky"}
                justifyContent="center"
                alignItems="center"
              >
                <ButtonGroup isAttached w={"full"}>
                  <Button
                    fontWeight={selectedTable?._id ? "auto" : "thin"}
                    size={"md"}
                    w={"full"}
                    leftIcon={<Icons.ChevronDownIcon />}
                    variant={"outline"}
                    onClick={() => {
                      disclosure.onClose();
                      tableSelectorDisclosure.onOpen();
                    }}
                  >
                    {selectedTable?._id
                      ? selectedTable?.name?.slice(0, 40) +
                        (selectedTable?.name?.length > 40 ? "..." : "")
                      : "Select a Table"}
                  </Button>
                  <IconButton
                    isDisabled={!selectedTable}
                    aria-label="Clear Selection"
                    size={"md"}
                    variant={"outline"}
                    icon={<Icons.CloseIcon />}
                    onClick={() => {
                      setSelectedTable(null);
                    }}
                  />
                </ButtonGroup>
              </Flex>
              {selectedTable?._id && (
                <>
                  <Divider className="my-2" />
                  <div className="max-h-[400px] overflow-auto">
                    <ExportMapper
                      isForEmailEnrichment={isForEmailEnrichment}
                      selectedTableId={selectedTable?._id || ""}
                      setMapping={setFieldMapping}
                    />
                  </div>
                </>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter sx={{ padding: "12px" }}>
            <Button
              size={"sm"}
              colorScheme="purple"
              isDisabled={
                !!(selectedTable && !fieldMapping?.isComplete) || !selectedTable
              }
              isLoading={isLoading}
              variant="outline"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={() => {
                onConfirm(
                  selectedTable && fieldMapping?.isComplete
                    ? { ...selectedTable, ...fieldMapping }
                    : null,
                );
              }}
              leftIcon={buttonIcon || <Icons.ExportIcon />}
            >
              Add to Selected Table
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <TablePicker
        onTableSelect={(table) => {
          setSelectedTable(table);
          disclosure.onOpen();
          tableSelectorDisclosure.onClose();
        }}
        disclosure={tableSelectorDisclosure}
      />
    </>
  );
};
