import persanaLogo from "@/assets/images/logo.png";
import { usePresets } from "@/hooks/usePresets";
import { Tooltip, Text, Stack, Box, Badge } from "@chakra-ui/react";
import { usePresetsStore } from "@/stores/presets.store";
import { PresetDataResponse } from "@/types/presets.types";
import { presetTypeDetails } from "../Enrichment/constants";
import CustomAccordion from "../Common/CustomAccordion";
import Icons from "../Icons";

export const PresetSearch = ({
  searchTerm,
  all,
}: {
  searchTerm?: string;
  all?: boolean;
}) => {
  const { isLoading, data } = usePresets();
  const updateState = usePresetsStore((state) => state.updateState);

  // If no search term is provided, return null
  if (!searchTerm && !all) {
    return null;
  }

  const doesMatch = (preset: PresetDataResponse, searchTerm: string) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return Object.values(preset).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(lowerCaseSearchTerm);
      }
      if (typeof value === "object" && value !== null) {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
      }
      return false;
    });
  };
  const selectPreset = (preset: PresetDataResponse) => {
    updateState({
      isOpenPresetsModal: true,
      presetsModalData: {
        modalType: preset.type,
        selectedPresetId: preset._id,
      },
    });
  };

  const filteredPresets = data?.filter((preset: PresetDataResponse) =>
    doesMatch(preset, searchTerm || ""),
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!filteredPresets?.length) {
    return <div className="px-6 py-2.5">No matching presets found.</div>;
  }

  return (
    <div className="bg-white pr-2">
      <CustomAccordion
        isDefaultOpen
        title={`Presets (${filteredPresets.length})`}
        description="Use presets to save time and enhance your workflow"
      >
        <Box className=" flex w-full flex-col justify-stretch">
          {filteredPresets.map((preset: PresetDataResponse) => {
            const presetDetails = presetTypeDetails[preset.type];
            if (!presetDetails) {
              console.warn("Preset type not found:", preset.type);
              return null;
            }
            return (
              <Tooltip
                className="w-full"
                label={presetDetails.tooltip}
                key={preset._id}
              >
                <Box
                  onClick={() => selectPreset(preset)}
                  className=" mb-2 flex grow  cursor-pointer items-center space-x-4 rounded-md bg-[#F5F3FF]  p-3 shadow-md"
                >
                  <div className="shrink-0">
                    {presetDetails.Icon ? <presetDetails.Icon /> : null}
                  </div>
                  <Stack className="w-full">
                    <div className="flex justify-between text-sm font-medium">
                      {preset.name}
                      <span className="flex gap-2">
                        {!presetDetails.isUseOwnKey && (
                          <Tooltip
                            hasArrow
                            label="You can use your own key/account"
                            bg="#2bbf71"
                            fontWeight={500}
                            borderRadius={"lg"}
                            fontSize={"12px"}
                            color="#fff"
                            placement="top"
                          >
                            <div
                              className={` flex items-center justify-center  rounded-[4px] `}
                            >
                              <Icons.KeyIcon className="text-sm text-lightGray" />
                            </div>
                          </Tooltip>
                        )}
                        <Tooltip
                          hasArrow
                          label="Persana Credits"
                          bg="#2bbf71"
                          fontWeight={500}
                          borderRadius={"lg"}
                          fontSize={"12px"}
                          color="#fff"
                          placement="top"
                        >
                          <div className="flex items-center justify-center rounded-[4px] bg-[#e6fce9]  text-[#2bbf71]">
                            <span className="text-[12px] font-[500]">
                              {presetDetails.credits}
                            </span>
                            <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
                          </div>
                        </Tooltip>
                        {preset.isOpen ? (
                          <Tooltip
                            className="shrink-0"
                            label="Provided by Persana"
                          >
                            <img
                              src={persanaLogo}
                              alt="persana"
                              className="ml-2 size-4"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip className="shrink-0" label="Private">
                            <Icons.CgUser className="size-4" />
                          </Tooltip>
                        )}
                        <Badge colorScheme="gray">preset</Badge>
                      </span>
                    </div>
                    <Text fontWeight={400} fontSize={"12px"} w={"90%"}>
                      {preset.description}
                    </Text>
                  </Stack>
                </Box>
              </Tooltip>
            );
          })}
        </Box>
      </CustomAccordion>
    </div>
  );
};
