/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Icons from "../Icons";

import { usePresetsStore } from "@/stores/presets.store";

import type { PresetDataResponse } from "@/types/presets.types";
import AddMoreBtn from "../Enrichment/Common/AddMoreBtn";
import CustomTextEditor from "../Enrichment/Common/CustomTextEditor";
import { allEnrichments } from "../Enrichment/constants";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  presetsData: PresetDataResponse[];
  selectedPresetId?: string;
}

const HttpPresetsList = ({ presetsData, selectedPresetId }: Props) => {
  const updateState = usePresetsStore((state) => state.updateState);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  const [selectedPreset, setSelectedPreset] =
    useState<PresetDataResponse | null>(null);

  useEffect(() => {
    const preset = presetsData.find((item) => item._id === selectedPresetId);
    setSelectedPreset(preset ?? null);
  }, [selectedPresetId]);
  const onUsePresetClick = () => {
    updateState({
      isOpenPresetsModal: false,
      selectedMethod: selectedPreset?.payload.selectedMethod,
      endpoint: selectedPreset?.payload.endpoint,
      body: selectedPreset?.payload.body,
      headers: selectedPreset?.payload.headers,
      selectedPreset,
    });

    const enrichment = allEnrichments.find(
      (item) => item.presetType === selectedPreset?.type,
    );
    if (enrichment) {
      updateEnrichmentState({
        selectedEnrichments: enrichment,
        viewMode: "create",
        searchInput: "",
        isOpenEnrichModal: false,
      });
    }
  };

  return (
    <div className="">
      {selectedPreset ? (
        <div className="h-full space-y-6 overflow-y-auto">
          <div className="flex justify-start">
            <Icons.ArrowLeftIcon
              onClick={() => {
                setSelectedPreset(null);
              }}
              className="cursor-pointer text-[#000000]/70"
              size={20}
            />
          </div>

          <div className="!mt-2 text-sm">
            <h2 className="pb-0.5 font-semibold text-[#1d2026]">
              {selectedPreset.name}
            </h2>
            <p className="text-[#676d7e]">{selectedPreset.description}</p>
          </div>

          <div className={`text-sm ${selectedPreset.isSecure ? "hidden" : ""}`}>
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Method Preview
            </h4>
            <Input
              value={selectedPreset?.payload.selectedMethod?.value}
              placeholder="E.g. Split the names"
              className="border-gray-300 w-full rounded-md border p-2 text-sm focus:border-blue-500 focus:outline-none"
              isDisabled
            />
          </div>

          <div className={`text-sm ${selectedPreset.isSecure ? "hidden" : ""}`}>
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Endpoint Preview
            </h4>
            <CustomTextEditor
              readonly
              slateValue={selectedPreset?.payload?.endpoint || []}
              setSlateValue={() => []}
              isInline
              editorHeight="fit-content"
              editorClassName="!px-2 !py-[4.5px]"
              showDetails={false}
            />
          </div>

          <div className="text-sm">
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Request Body Preview
            </h4>
            <CustomTextEditor
              readonly
              slateValue={
                selectedPreset.isSecure
                  ? [
                      {
                        type: "paragraph",
                        children: [{ text: "Write your query" }],
                      },
                    ]
                  : selectedPreset?.payload?.body || []
              }
              setSlateValue={() => ""}
              editorHeight="10rem"
              showDetails={false}
              placeholder={
                selectedPreset.isSecure ? "Write your query..." : "..."
              }
            />
          </div>

          <div className={`text-sm ${selectedPreset.isSecure ? "hidden" : ""}`}>
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Headers Preview
            </h4>
            <AddMoreBtn
              keyName="Key"
              handleFieldChange={() => {}}
              title="Add new key value pair"
              description="Enter any header fields to set, e.g. {'API-key': 'abcd1234'}"
              moreFields={selectedPreset?.payload?.headers || []}
              setMoreFileds={() => {}}
              showAddMore={false}
              showRemove={false}
            />
          </div>

          <Button colorScheme="green" onClick={onUsePresetClick}>
            <Icons.CheckCircleIcon className="mr-2 text-lg" />
            Use this preset
          </Button>
        </div>
      ) : (
        <div className="custom-scrollbar grow space-y-4 overflow-y-auto">
          {presetsData?.map((preset, index) => (
            <div
              key={index}
              className="w-[98%] cursor-pointer rounded-md bg-[#f6f6f8] p-3 duration-300 hover:bg-[#E7E8EC]"
              onClick={() => setSelectedPreset(preset)}
            >
              <p className="font-semibold">{preset.name}</p>
              <p className="text-sm">{preset.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HttpPresetsList;
