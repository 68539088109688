import { Box, Tooltip } from "@chakra-ui/react";
import PropTypes from "prop-types";
import Logo from "@/assets/svgs/logo.svg";
import Logo_two from "@/assets/svgs/logo-1.svg";
import Logo_three from "@/assets/svgs/logo-2.svg";
import Logo_four from "@/assets/svgs/logo-3.svg";
import Logo_five from "@/assets/svgs/logo-4.svg";
import Icons from "../Icons";

interface TemplateCardProps {
  cardTitle: string;
  cardDesc: string;
  image: any | (() => Element); // Allow both React components and image URLs
  GroupIcon: any | (() => Element);
  quantumTemplate: boolean | null;
  onClick: () => void;
  credits?: string;
}

const ImagesArr = [
  { src: Logo, alt: "logo" },
  { src: Logo_two, alt: "logo_two" },
  { src: Logo_three, alt: "logo_three" },
  { src: Logo_four, alt: "logo_four" },
  { src: Logo_five, alt: "logo_five" },
];

const TemplateCard: React.FC<TemplateCardProps> = ({
  cardTitle,
  cardDesc,
  image: Icon,
  GroupIcon,
  quantumTemplate,
  onClick,
  credits,
}) => {
  return (
    <div
      onClick={onClick} // Trigger the parent-defined function
      className="flex cursor-pointer flex-col gap-0 rounded-xl border border-[#D0D4DC] bg-white p-4"
    >
      <div className="mb-2 mt-1 flex items-center justify-between">
        <div className="flex  items-center gap-2">
          {typeof Icon === "string" && Icon ? (
            <img src={Icon} alt={cardTitle} className="size-6" />
          ) : quantumTemplate ? (
            typeof GroupIcon === "function" ? (
              <GroupIcon className="size-6" />
            ) : null
          ) : (
            <Icon className="size-6" />
          )}
        </div>
        {quantumTemplate && (
          <p className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-[#693DC7] to-[#BF72E1] px-4 py-2 text-xs font-medium text-white">
            Quantum AI
          </p>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h4 className="text-base font-medium text-[#0F0D1C]">{cardTitle}</h4>
        <p className="text-[13px] text-[#7E889F]">{cardDesc}</p>
      </div>
      <div className="mt-2 flex items-center justify-between">
        {cardTitle === "Find Work Email (Waterfall)" && (
          <Box className="inline-flex items-center gap-1 rounded-full bg-[#D0D4DC] p-1">
            {ImagesArr.map((logo, index) => (
              <img key={index} src={logo.src} alt={logo.alt} />
            ))}
          </Box>
        )}
        <Tooltip
          hasArrow
          label="Persana Credits"
          bg="#2bbf71"
          fontWeight={500}
          borderRadius={"lg"}
          fontSize={"12px"}
          color="#fff"
          placement="top"
        >
          <div className="flex items-center justify-center gap-[4px] rounded-[12px] border border-[#00B41D]  bg-[#e6fce9] px-[4px] py-[2px] text-[#2bbf71]">
            <span className="text-[12px] font-[500]">{credits ?? 0}</span>
            <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

// Define PropTypes
TemplateCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardDesc: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  GroupIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  quantumTemplate: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  credits: PropTypes.string,
};

export default TemplateCard;
