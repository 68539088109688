import { useTableStore } from "@/stores/table.store";
import Icons from "@/components/Icons";
import { Button } from "@chakra-ui/react";

const ParentTable = () => {
  const tableData = useTableStore((state) => state.tableData);
  const parentTableId = tableData?.metaData?.parentTableId;
  if (!parentTableId) {
    return null;
  }
  const handleLinkedTable = (linkedTable: string) => {
    window.open(`/table/${linkedTable}?last_page=true`, "_blank");
  };
  return (
    <div className="flex cursor-pointer items-center gap-1">
      <div onClick={() => handleLinkedTable(parentTableId)}>
        <Button
          leftIcon={
            <Icons.ExternalLinkIcon className="h-auto w-4 text-[#000000]" />
          }
          size="sm"
          colorScheme="purple"
          onClick={() => handleLinkedTable(parentTableId)}
        >
          Parent Table
        </Button>
      </div>
    </div>
  );
};

export default ParentTable;
