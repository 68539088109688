import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import BCHeader from "@/assets/svgs/bg-grad.svg";

import { useModalStore } from "@/stores/modal.store";
import { useTableStore } from "@/stores/table.store";
import tableService from "@/services/table.service";

interface Params {
  sourceType: string;
  sourceId: string;
  targetId: string;
}

export const useFolderList = () => {
  const workspaceId = useTableStore((state) => state.selectedWorkSpace?._id);

  return useQuery({
    queryKey: ["folderList", workspaceId],
    queryFn: () => tableService.getFolderList(),
  });
};
const AddNewFolderModal = () => {
  const [selectedValue, setSelectedValue] = useState<Option | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | null>(null);

  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const { data: folderListAtServer = null, isLoading: isFolderListLoading } =
    useFolderList();

  console.log(111, folderListAtServer);
  console.log(22222, modalData);

  const handleSaveItem = async () => {
    if (!selectedValue) {
      setError("Please select Folder");
      return;
    }
    if (selectedValue) {
      const params: Params = {
        sourceType: "table",
        sourceId: modalData.metaData,
        targetId: selectedValue?.value,
      };
      console.log(4444444, params);
      await tableService.moveFolderItems(params);
    }
  };

  return (
    <Modal
      isCentered
      onClose={() => {
        updateState({
          isOpenModal: false,
          modalData: { modalType: "", metaData: "" },
        });
      }}
      isOpen={isOpenModal && modalData.modalType === "add-folder"}
      motionPreset="scale"
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="font-poppins">
        <ModalHeader
          className="flex flex-col items-center justify-center border-b-[1px] bg-cover bg-no-repeat p-6 text-center"
          style={{ backgroundImage: `url(${BCHeader})` }}
        >
          <Text className="flex-1 text-[32px] font-medium text-[#0F0D1C]">
            Select Folder
          </Text>
          {/* <Text className="text-semibold flex-1 text-lg text-[#0F0D1C]">
              Add relevant information to the fields to get started.
            </Text> */}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] overflow-auto">
          <div className="space-y-4">
            <div>
              <p className="pb-1 text-base font-semibold">
                Select folder where you want to move your selected table?{" "}
              </p>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Select.."
                closeMenuOnSelect
                options={
                  folderListAtServer?.data?.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })) || []
                } // Ensures options is always an array
                value={selectedValue}
                onChange={(selected) => setSelectedValue(selected as Option)}
              />

              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isFolderListLoading}
            onClick={() => handleSaveItem()}
          >
            {"Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewFolderModal;
