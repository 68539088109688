import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import misServices from "@/services/misc.services";

interface Props {
  value?: Option[];
  onChange?: (selectedValues: Option[]) => void;
  label?: string;
}

interface CompanyNameResult {
  company_name: string;
  employee_count: number;
  follower_count: number;
  id: number;
  square_logo_url: string;
  website: string;
}

const CompanyNameSearches = ({
  label = "Company Name",
  value = [],
  onChange,
}: Props) => {
  console.log("HERE------------>", label, value);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(value);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedOptions, setFetchedOptions] = useState<{
    [key: string]: Option[];
  }>({});

  // Sync selectedOptions with external value changes
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const fetchOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue.length <= 2) {
        return [];
      }

      if (fetchedOptions[inputValue]) {
        return fetchedOptions[inputValue];
      }

      try {
        setIsLoading(true);
        const response = await misServices.getMiscData<any>("company-names", {
          search: inputValue,
        });

        const options = response?.data?.data?.results?.map(
          (result: CompanyNameResult) => ({
            label: result.company_name,
            value: result.id,
            logo: result.square_logo_url || "",
          }),
        );

        setFetchedOptions((prev) => ({ ...prev, [inputValue]: options }));
        return options;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [fetchedOptions],
  );

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: any[]) => void) => {
      fetchOptions(inputValue)
        .then((options) => callback(options || []))
        .catch(() => callback([]));
    }, 1000),
    [fetchOptions],
  );

  const handleChange = (newValue: MultiValue<Option>) => {
    setSelectedOptions([...newValue]);
    onChange && onChange(newValue as Option[]);
  };

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    const getInitial = (name: string) => name.charAt(0).toUpperCase();

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-gray-100"
      >
        {data.logo && data.logo !== null ? (
          <img
            src={data.logo}
            alt={data.label}
            className="w-6 h-6 rounded-full"
          />
        ) : (
          <div className="w-6 h-6 flex items-center justify-center bg-black-400 text-white rounded-full text-sm font-semibold">
            {getInitial(data.label)}
          </div>
        )}
        <span>{data.label}</span>
      </div>
    );
  };

  return (
    <>
      <p className="mb-1 text-[14px] font-[600] text-[#1a202c]">{label}</p>
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder="Search for company names..."
        isClearable
        defaultOptions={[]}
        noOptionsMessage={() => "No results found"}
        isLoading={isLoading}
        value={selectedOptions}
        isMulti
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        components={{ Option: CustomOption }}
      />
    </>
  );
};

export default CompanyNameSearches;
