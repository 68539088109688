import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

import leadService from "@/services/leads.service";
import { userStore } from "@/stores/user.store";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

type Props = {
  buttonIcon?: JSX.Element;
  currentSearchPayload: any;
  buttonText: string;
  variant: "primary" | "secondary";
};

const CompanySearchResultTableActions = ({
  buttonIcon,
  currentSearchPayload,
  buttonText,
  variant,
}: Props) => {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);

  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ payload, apiPath }: { payload: any; apiPath: string }) => {
      return leadService.searchCompanyIscraper(payload, apiPath);
    },
  });

  const handleImportLeads = async () => {
    if (!selectedLeads.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the companies.",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    const isFreeUser = currentUser.planType?.toLowerCase() === "free";

    const customSelectionNumber = isFreeUser
      ? Math.min(25, selectionCount)
      : selectionCount;

    const payload = {
      ...currentSearchPayload,
      selectedCompanies: ["select-all", "range-selection"].includes(
        selectionAction,
      )
        ? []
        : selectedLeads?.map((item) => item?.id || ""),
      customSelectionNumber:
        selectionAction === "range-selection" ? customSelectionNumber : 0,
      isSelectAll: selectionAction === "select-all",
      totalEntries: searchLeadsResult?.pagination?.total_entries || 0,
      page: selectionAction === "select-all" ? 1 : currentSearchPayload?.page,
    };

    await mutateAsync(
      { payload, apiPath: "leads/add-leads-to-company-table" || "" },
      {
        onSuccess: (response: any) => {
          if (!response?.data?.tableData) {
            return;
          }

          toast({
            title: "Success",
            description: (
              <p>
                Leads have been successfully imported to the table. <br />
                <a
                  href={`/table/${response?.data?.tableData._id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-lg font-bold text-white underline"
                >
                  View Table
                </a>
              </p>
            ),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });

          window.open(
            `${window.location.origin}/table/${response?.data?.tableData._id}`,
            "_blank",
          );
        },
        onError: (error) => {
          console.log(error);
        },
      },
    );
  };

  return (
    <>
      <div className="flex shrink-0 justify-end">
        <Button
          size={"sm"}
          isLoading={isPending}
          loadingText={"Importing..."}
          variant="outline"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() => {
            handleImportLeads();
          }}
          leftIcon={buttonIcon}
          className={`!hover:text-inherit !h-[35px] !w-fit !rounded-[8px] !px-4 !py-1.5 !font-title !text-sm !font-semibold !tracking-wide ${variant === "primary" ? "!border !border-[#C03EDC] !bg-[#F5F3FF] !text-[#373F51]" : "!bg-[#FFFFFF] !text-[#373F51]"} ${buttonText.includes("Enrich with Persana") ? "joyride-import-companies" : "joyride-export-find-email"}`}
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default CompanySearchResultTableActions;
