const NoResult = () => {
  return (
    <div
      className="flex h-full w-full items-center justify-center"
      style={{ height: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-col items-center rounded-md bg-[#FAFAFB] px-5 py-3 font-title tracking-wide">
        <h3 className="pb-1.5 text-lg font-semibold text-[#000000]">
          No Results Found
        </h3>
      </div>
    </div>
  );
};

export default NoResult;
