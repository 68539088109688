import TemplateDetails from "@/components/Preview/TemplateDetails";
import { FileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = new FileRoute("/templates/$templateId").createRoute({
  parseParams: (params) => ({
    templateId: z.string().parse(String(params.templateId)),
  }),
  component: TemplateDetailsPage,
});
function TemplateDetailsPage() {
  const params = Route.useParams();
  return <TemplateDetails templateId={params.templateId} />;
}

export default TemplateDetailsPage;
