import PersanaAccordion from "@/components/Common/PersanaAccordion";
import { Input } from "@chakra-ui/react";

type AdvancedDetailsProps = {
  columnIdentifier: string;
  setColumnIdentifier: (val: string) => void;
};

const AdvancedDetails = ({
  columnIdentifier,
  setColumnIdentifier,
}: AdvancedDetailsProps) => {
  return (
    <div className="z-100 my-4">
      <PersanaAccordion
        btnStyle={{
          backgroundColor: "rgb(250 250 251)",
          borderRadius: "6px",
        }}
        items={[
          {
            button: (
              <button className="w-full truncate text-left font-semibold text-[#000000]">
                Advanced Details (Optional)
              </button>
            ),

            panel: (
              <div>
                <p className="text-base font-semibold">Column Identifier </p>
                <Input
                  size={"sm"}
                  value={columnIdentifier}
                  onChange={(e) => {
                    const value = e.target.value;
                    setColumnIdentifier(value);
                  }}
                  className="border-gray-200 w-full rounded-md border px-4 py-2"
                />
                <span className="text-xs text-darkTextGray">
                  Identifier will append with the column name
                </span>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default AdvancedDetails;
