import {
  Avatar,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import Icons from "../Icons";
import { Member } from "@/types/team.types";
import Checkbox from "../Common/Checkbox";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { useModalStore } from "@/stores/modal.store";
import { UserData } from "@/types/user.types";
import teamService from "@/services/team.service";

interface Props {
  changeTab: (index: number) => void;
  teamMembers: Member[];
  isForShare: boolean;
}

const ManageTeam = ({ changeTab, teamMembers, isForShare }: Props) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [searchhQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedMembersToShare, setSelectedMembersToShare] = useState<
    Partial<Member>[]
  >([]);

  const queryClient = useQueryClient();
  const addTeamMembersModal = useModalStore(
    (state) => state.addTeamMembersModal,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const updateState = useTableStore((state) => state.updateState);
  const updateModalState = useModalStore((state) => state.updateState);
  const toast = useToast();
  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
    }: {
      tableId: string;
      payload: {
        usersId: string[];
      };
    }) => tableService.shareWithTeam(tableId, payload),
  });

  const { mutateAsync: removeMember } = useMutation({
    mutationFn: ({ memberId }: { memberId: string }) =>
      teamService.removeSharedUser(memberId),
  });

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const filteredMembers = teamMembers.filter((member: any) => {
      // @ts-ignore
      return (
        member?.userId?.profileInfo?.firstName
          ?.toLowerCase()
          ?.includes(query?.toLowerCase()) ||
        member?.userId?.profileInfo?.lastName
          ?.toLowerCase()
          ?.includes(query?.toLowerCase()) ||
        member?.userId?.Email?.toLowerCase()?.includes(query?.toLowerCase())
      );
    });
    setMembers(filteredMembers);
  };

  const handleSelect = (member: Partial<Member>) => {
    const isMemberSelected = selectedMembersToShare.some(
      (selectedMember) => selectedMember?._id === member?._id,
    );
    if (isMemberSelected) {
      setSelectedMembersToShare(
        selectedMembersToShare.filter(
          (selectedMember) => selectedMember?._id !== member?._id,
        ),
      );
    } else {
      setSelectedMembersToShare([...selectedMembersToShare, member]);
    }
  };

  const handleShareWithTeam = async () => {
    setIsLoading(true);
    const usersId = selectedMembersToShare.map(
      (member) => (member?.userId as UserData)?._id || "",
    );
    await mutateAsync(
      {
        tableId,
        payload: {
          usersId,
        },
      },
      {
        onSuccess: (response) => {
          // changeTab(0);
          toast({
            position: "top-right",
            title: "Shared with team members successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          updateState({
            tableData: response?.data?.data,
          });
          updateModalState({
            addTeamMembersModal: {
              isOpen: false,
              isForShare: false,
            },
          });
          setIsLoading(false);
        },

        onError: (error) => {
          console.log(error);
          setIsLoading(false);
        },
      },
    );
    // changeTab(0);
  };

  useEffect(() => {
    if (
      addTeamMembersModal.sharedWith &&
      addTeamMembersModal.sharedWith.length > 0
    ) {
      // make sharedwith members selected and top of the list
      const sharedWithMembers = teamMembers.filter((member) =>
        addTeamMembersModal?.sharedWith?.includes(
          (member?.userId as UserData)?._id,
        ),
      );
      const otherMembers = teamMembers.filter(
        (member) =>
          !addTeamMembersModal?.sharedWith
            ?.toString()
            ?.includes((member?.userId as UserData)?._id),
      );
      setMembers([...sharedWithMembers, ...otherMembers]);

      setSelectedMembersToShare(sharedWithMembers);
    } else {
      setMembers(teamMembers);
    }
  }, [addTeamMembersModal.sharedWith, teamMembers]);

  const handleRemove = async (member: Member) => {
    setIsRemoveLoading(true);
    const response = await removeMember({
      memberId: (member.userId as UserData)?._id || "",
    });

    if (response.success) {
      await queryClient.invalidateQueries({
        queryKey: ["team-details"],
      });
      const newMembers = members.filter((mem) => mem._id !== member._id);
      setMembers(newMembers);
      toast({
        position: "top-right",
        title: "Member removed successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        position: "top-right",
        title: "Error while removing member",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    setIsRemoveLoading(false);
  };

  return (
    <div className="pt-5 pb-2 min-h-[200px]">
      <div className="w-full space-y-5">
        <InputGroup width="100%">
          <InputLeftElement>
            <Icons.SearchIcon size={20} color="gray" />
          </InputLeftElement>
          <Input
            placeholder="Search with name or email..."
            variant="outline"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            value={searchhQuery}
          />
        </InputGroup>

        <div className="space-y-5 w-full min-h-[250px] max-h-[250px] overflow-auto custom-scrollbar">
          {members?.length > 0
            ? members?.map((member: any, index) => (
                <div
                  key={`workspace-membmer-${index}`}
                  className="flex items-center justify-between font-title"
                >
                  <TeamDetailsWrapper
                    isForShare={isForShare}
                    member={member}
                    selectedMembersToShare={selectedMembersToShare}
                    handleSelect={handleSelect}
                  >
                    <div className="flex items-center gap-x-3">
                      <Avatar
                        sx={{
                          width: "2.7rem",
                          height: "2.7rem",
                        }}
                      />
                      <div className="text-start leading-[1] relative top-[-3.5px]">
                        <p className="font-semibold text-base">
                          {`${
                            // @ts-ignore
                            member?.userId?.profileInfo?.firstName || ""
                          } ${
                            // @ts-ignore
                            member?.userId?.profileInfo?.lastName || ""
                          }`}
                        </p>
                        <p className="text-darkTextGray font-medium text-sm">
                          {
                            // @ts-ignore
                            member?.userId?.Email || ""
                          }
                        </p>
                      </div>
                    </div>
                  </TeamDetailsWrapper>
                  <button
                    className="font-semibold text-textPurple text-sm hover:underline duration-300 pr-2"
                    onClick={() => {
                      handleRemove(member);
                    }}
                    disabled={isRemoveLoading}
                  >
                    Remove
                  </button>
                </div>
              ))
            : null}
        </div>
      </div>
      <Button
        className="flex !h-[40px] items-center rounded-md border  !bg-primary-gradient !text-white !text-sm hover:!shadow-md w-full mt-5"
        isDisabled={isForShare && selectedMembersToShare.length === 0}
        isLoading={isLoading}
        onClick={() => {
          isForShare ? handleShareWithTeam() : changeTab(0);
        }}
      >
        {isForShare ? "Share with team members" : "Invite new team members"}
      </Button>
    </div>
  );
};

export default ManageTeam;

const TeamDetailsWrapper = ({
  isForShare,
  children,
  member,
  handleSelect,
  selectedMembersToShare,
}: {
  isForShare: boolean;
  children: React.ReactNode;
  member: Partial<Member>;
  selectedMembersToShare: Partial<Member>[];
  handleSelect: (member: Partial<Member>) => void;
}) => {
  return isForShare ? (
    <Checkbox
      isChecked={
        // @ts-ignore
        selectedMembersToShare.some(
          (selectedMember) => selectedMember?._id === member?._id,
        )
      }
      onChange={() => {
        handleSelect(member);
      }}
    >
      {children}
    </Checkbox>
  ) : (
    children
  );
};
