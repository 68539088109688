import { useEffect, useMemo, useReducer } from "react";
import { Text, Select as ChakraSelect, Spinner } from "@chakra-ui/react";
import CustomAccordion from "@/components/Common/CustomAccordion";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useTableStore } from "@/stores/table.store";
import { useTableInfo } from "@/hooks/useTableData";
import { useWorkspaceTableList } from "@/hooks/useTableList";
import { EnrichmentMetaData } from "@/types/table.types";
import { useEnrichStore } from "@/stores/enrich.store";

const initialState = {
  suppressionTableId: "", // ID of the selected suppression list table
  sourceEmailColumnId: "",
  targetEmailColumnId: "",
  sourceColId2: "",
  targetColId2: "",
  targetIsSuppressedColumnId: "",
};

type State = typeof initialState;
interface EnrichmentDataType extends EnrichmentMetaData {
  payload: typeof initialState;
}

type Action<Key extends keyof State> = {
  key: Key;
  value: State[Key];
};

function reducer<Key extends keyof State>(state: State, action: Action<Key>) {
  return { ...state, [action.key]: action.value };
}

const SuppressionListEnrichment = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const {
    data: suppressionTables = [],
    isFetching: isFetchingTables,
    isLoading: isLoadingTables,
  } = useWorkspaceTableList();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: targetTableData, isFetching: isFetchingTargetColumns } =
    useTableInfo(state.suppressionTableId);

  const targetColumns = useMemo<{ name: string; _id: string }[]>(
    () =>
      targetTableData?.data?.columns.map((column: ANY) => ({
        name: column.name,
        _id: column._id,
      })) || [],
    [targetTableData],
  );
  const updateState = <Key extends keyof State>(key: Key, value: State[Key]) =>
    dispatch({ key, value });

  const isValid = useMemo(() => !!state.suppressionTableId, [state]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;
      if (enrichmentData?.payload) {
        Object.entries(enrichmentData.payload).forEach(([key, value]) => {
          dispatch({ key: key as keyof State, value });
        });
      }
    }
  }, [viewMode, selectedColumnToEdit]);
  const generateApiPayload = () => {
    return {
      payload: {
        suppressionTableId: state.suppressionTableId,
        sourceEmailColumnId: state.sourceEmailColumnId,
        targetEmailColumnId: state.targetEmailColumnId,
        targetIsSuppressedColumnId: "",
        sourceColId2: state.sourceColId2,
        targetColId2: state.targetColId2,
      },
      dependentColumnIds: [state.sourceEmailColumnId],
    };
  };

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        {/* Select Suppression List */}
        <CustomAccordion
          title="Select Suppression List"
          isDefaultOpen={true}
          isOpen={true}
        >
          <div className="flex flex-col gap-4">
            <div>
              <Text>Select Suppression List Table</Text>
              <ChakraSelect
                placeholder={
                  isLoadingTables
                    ? "Loading tables..."
                    : "Select Suppression List Table"
                }
                value={state.suppressionTableId}
                isDisabled={isFetchingTables || isLoadingTables}
                onChange={(e) =>
                  updateState("suppressionTableId", e.target.value)
                }
              >
                {suppressionTables.map((table) => (
                  <option key={table._id} value={table._id}>
                    {table.name}
                  </option>
                ))}
              </ChakraSelect>
            </div>
          </div>
        </CustomAccordion>

        {/* Match Contact */}
        <CustomAccordion
          title="Match Contact 1"
          isDefaultOpen={true}
          isOpen={true}
        >
          <div className="flex flex-col gap-4">
            <div>
              <Text>Source Table Column</Text>
              <ChakraSelect
                placeholder="Select source email column"
                value={state.sourceEmailColumnId}
                onChange={(e) =>
                  updateState("sourceEmailColumnId", e.target.value)
                }
              >
                {columns.map((col) => (
                  <option key={col._id} value={col._id}>
                    {col.name}
                  </option>
                ))}
              </ChakraSelect>
            </div>
            <div>
              <Text>Target Table Column</Text>
              {isFetchingTargetColumns ? (
                <Spinner size="sm" />
              ) : (
                <ChakraSelect
                  placeholder="Select target email column"
                  value={state.targetEmailColumnId}
                  onChange={(e) =>
                    updateState("targetEmailColumnId", e.target.value)
                  }
                >
                  {targetColumns.map((col) => (
                    <option key={col._id} value={col._id}>
                      {col.name}
                    </option>
                  ))}
                </ChakraSelect>
              )}
            </div>
          </div>
        </CustomAccordion>

        <CustomAccordion
          title="Match Contact 2 (Optional)"
          isDefaultOpen={true}
          isOpen={true}
        >
          <div className="flex flex-col gap-4">
            <div>
              <Text>Source Table Column</Text>
              <ChakraSelect
                placeholder="Select source email column"
                value={state.sourceColId2}
                onChange={(e) => updateState("sourceColId2", e.target.value)}
              >
                {columns.map((col) => (
                  <option key={col._id} value={col._id}>
                    {col.name}
                  </option>
                ))}
              </ChakraSelect>
            </div>
            <div>
              <Text>Target Table Column</Text>
              {isFetchingTargetColumns ? (
                <Spinner size="sm" />
              ) : (
                <ChakraSelect
                  placeholder="Select target email column"
                  value={state.targetColId2}
                  onChange={(e) => updateState("targetColId2", e.target.value)}
                >
                  {targetColumns.map((col) => (
                    <option key={col._id} value={col._id}>
                      {col.name}
                    </option>
                  ))}
                </ChakraSelect>
              )}
            </div>
          </div>
        </CustomAccordion>
      </div>
      <SaveAndRunEnrichment
        isDisabled={!isValid}
        apiPayload={generateApiPayload()}
      />
    </>
  );
};

export default SuppressionListEnrichment;
