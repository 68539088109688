import AllEnrichments from "./AllEnrichments";
import TopEnrichments from "./TopEnrichments";

const MainContent = () => {
  return (
    <div className="flex min-h-[636px] w-[75%] px-[24px] py-[16px] pb-4">
      <div className="w-full">
        <div className="mb-[16px]">
          <p className="pb-2 text-[14px] text-[#0F0D1C]">Suggestions</p>
          <TopEnrichments />
        </div>
        <div>
          <p className="pb-2">Tools</p>
          <AllEnrichments />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
