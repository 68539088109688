import ListSection from "./ListSection";

const StarterChecklist = () => {
  return (
    <div className="border border-[#D0D4DC] rounded-[12px] bg-white h-fit w-full mt-1">
      <div className="flex flex-col gap-[24px]">
        {/* <div className="flex flex-col gap-[8px] px-[24px]">
          <p className="text-[20px] leading-[24px] font-medium text-[#0F0D1C]">
            {selectedWorkSpace
              ? selectedWorkSpace.name
              : `${currentUser?.profileInfo?.firstName || "User"}'s Workspace`}
          </p>
          <p className="text-sm text-[#4A5264] leading-[20px]">
            Follow these simple steps to get started in minutes.
          </p>
        </div> */}
        <ListSection />
      </div>
    </div>
  );
};

export default StarterChecklist;
