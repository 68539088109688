import {
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { AgGridReact } from "ag-grid-react";
import React, { Fragment, useEffect } from "react";
import Swal from "sweetalert2";
import persanaQLogo from "@/assets/images/agent_2.png";

import ImportDataIcon from "@/assets/svgs/download.svg";
import enrichmentSVG from "@/assets/svgs/enrichment.svg";
import { allEnrichments } from "@/components/Enrichment/constants";
import Icons from "@/components/Icons";
import AddColumn from "./AddColumn";
import DownloadsList from "./DownloadsList";
import Filters from "./Filters";
import PendingQueues from "./PendingQueues";
import RemoveRows from "./RemoveRows";
import ShareTable from "./ShareTable";
import TableInfo from "./TableInfo";
import TableName from "./TableName";

import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import TableSearch from "../TableSearch";
import ColumnList from "./ColumnList";
import ImportData from "./ImportData";
import ExportData from "./ExportData";
import { messages } from "@/components/Templates/onboardMessages";
import LinkedTables from "./LinkedTables";
import ParentTable from "./ParentTable";

interface MenuItemProps {
  onClose?: () => void;
}

interface Props {
  isRefetching: boolean;
  gridRef: React.RefObject<AgGridReact>;
}

const PageActions = ({ isRefetching, gridRef }: Props) => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (tableId: string) => tableService.deleteTable(tableId),
  });

  const { mutateAsync: copyTableMutation, isPending } = useMutation({
    mutationFn: (tableId: string) => tableService.copyTable(tableId),
  });

  const tableData = useTableStore((state) => state.tableData);
  const tableFilters = useTableStore((state) => state.tableFilters);
  const isShowLoader = useTableStore((state) => state.isShowLoader);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);

  const { currentUser } = userStore();
  const updateState = useEnrichStore((state) => state.updateState);
  const updateModalState = useModalStore((state) => state.updateState);

  const processingData = useProcessingStore((state) => state.processingData);

  const isAnyProcessRunning = !!processingData?.length;

  const draftEmail = {
    id: "draftEmail",
    name: "AI Draft Message",
    icon: () => <Icons.AgentIcon color="#1972f5" size={26} />,
    enrichmentName: "draftEmail",
    iconBg: "#fff",
    label: "",
    subtitle: "",
    categories: [],
    credits: "FREE",
  };

  const handleDeleteTable = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await mutateAsync(tableData._id, {
          onSuccess: (response) => {
            if (response.success == false) {
              toast({
                title: "Something went wrong",
                description: response.error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top-right",
              });
              return;
            }
            queryClient.refetchQueries({
              queryKey: ["tables"],
            });
            toast({
              title: "Table deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top-right",
            });
            navigate({
              to: "/",
            });
          },
          onError: (error) => {
            toast({
              title: "Something went wrong",
              description: error.message,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top-right",
            });
          },
        });
      },
    });
  };

  const handleCopyTable = () => {
    return copyTableMutation(tableData._id, {
      onSuccess: (response) => {
        if (response.success) {
          Swal.close();
          const templateId = new URLSearchParams(window.location.search).get(
            "templateId",
          );
          if (templateId) {
            showTemplateIntoMessage(templateId);
          }
          navigate({
            to: `/table/$tableId`,
            params: {
              tableId: response.data.tableId,
            },
          });
        } else {
          toast({
            title: "Something went wrong",
            description: response.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Something went wrong",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  const handleDraftEmail = () => {
    updateModalState({
      isOpenModal: true,
      modalData: {
        modalType: "draft-email",
      },
    });
    updateState({
      selectedEnrichments: draftEmail,
      isOpenEnrichModal: false,
      viewMode: "create",
    });
  };

  const handleRemoveDuplicateRows = () => {
    updateModalState({
      isOpenModal: true,
      modalData: {
        modalType: "remove-duplicate-rows",
      },
    });
  };

  const AddColumnMenuItem = ({ onClose }: MenuItemProps) => {
    return (
      <MenuItem className="!p-0" closeOnSelect={false}>
        <AddColumn
          onClose={onClose}
          gridRef={gridRef}
          className="m-auto w-[94%]"
        />
      </MenuItem>
    );
  };

  const items = [
    {
      key: "add-column",
      label: <AddColumnMenuItem />,
    },
    {
      key: "formula",
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.AddIcon size={20} />}
            className="m-auto w-[94%]"
            onClick={(e) => {
              e.preventDefault();
              updateState({
                generateFormulaModal: {
                  isOpen: true,
                  type: "formula",
                },
              });
            }}
          >
            <span className="flex-1">Formula</span>
          </Button>
        </MenuItem>
      ),
    },
    {
      key: "mergeColumn",
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.AddIcon size={20} />}
            className="m-auto w-[94%]"
            isDisabled={isAnyProcessRunning}
            onClick={(e) => {
              e.preventDefault();
              const selectedEnrichment = allEnrichments.find(
                (item) => item.id === "mergeColumns",
              );
              updateState({
                selectedEnrichments: selectedEnrichment,
                isOpenEnrichModal: false,
                viewMode: "create",
              });
            }}
          >
            <span className="flex-1">Merge Columns</span>
          </Button>
        </MenuItem>
      ),
    },
    {
      key: "searchPeople",
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.SearchIcon size={20} />}
            className="m-auto w-[94%]"
            isDisabled={isAnyProcessRunning}
            onClick={(e) => {
              e.preventDefault();
              updateModalState({
                isOpenModal: true,
                modalData: {
                  modalType: "search-people",
                },
              });
            }}
          >
            <span className="flex-1">Search People</span>
          </Button>
        </MenuItem>
      ),
    },
    {
      key: "searchCompany",
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.SearchIcon size={20} />}
            className="m-auto w-[94%]"
            isDisabled={isAnyProcessRunning}
            onClick={(e) => {
              e.preventDefault();
              updateModalState({
                isOpenModal: true,
                modalData: {
                  modalType: "search-people",
                },
              });
            }}
          >
            <span className="flex-1">Search Company</span>
          </Button>
        </MenuItem>
      ),
    },
    {
      key: "divider-0",
      label: <MenuDivider />,
    },
    {
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.CopyIcon size={20} />}
            className="m-auto w-[94%]"
            onClick={handleCopyTable}
            isDisabled={isAnyProcessRunning}
          >
            <span className="flex-1">Duplicate Table</span>
          </Button>
        </MenuItem>
      ),
      key: "1",
    },
    {
      label: (
        <MenuItem className="hidden !px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.FindWorkEmailIcon />}
            className="m-auto w-[94%]"
            onClick={handleDraftEmail}
          >
            <span className="flex-1">AI Draft Message</span>
          </Button>
        </MenuItem>
      ),
      key: "2",
    },
    {
      key: "divider-1",
      label: <MenuDivider />,
    },
    {
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.MergeIcon size={20} />}
            className="m-auto w-[94%]"
            onClick={handleRemoveDuplicateRows}
            isDisabled={isAnyProcessRunning}
          >
            <span className="flex-1">Remove Duplicate Rows</span>
          </Button>
        </MenuItem>
      ),
      key: "3",
    },
    {
      key: "divider-2",
      label: <MenuDivider />,
    },
    {
      label: (
        <MenuItem className="!px-0 !pb-0">
          <Button
            size="sm"
            variant="outline"
            leftIcon={<Icons.DeleteIcon size={20} className="text-red-700" />}
            className="m-auto w-[94%]"
            onClick={handleDeleteTable}
            isDisabled={isAnyProcessRunning}
          >
            <span className="flex-1">Delete Table</span>
          </Button>
        </MenuItem>
      ),
      key: "4",
    },
    {
      label: (
        <RemoveRows
          title={`Delete All Rows`}
          isAnyProcessRunning={isAnyProcessRunning}
          removeAll
        />
      ),
      key: "5",
    },
  ];

  const encrichmentItems = [
    {
      key: "linkedInDetails",
      id: "linkedInDetails",
      className: "joyride-enrich-profile",
      name: "Enrich Profile",
      icon: <Icons.PersonIcon color="#805ad6" />,
    },
    {
      key: "findWorkEmail",
      id: "findWorkEmailWaterfall",
      className: "joyride-find-work-email",
      name: "Find Work Email",
      icon: <Icons.FindWorkEmailIcon color="#805ad6" />,
    },
    {
      key: "generateSalesEmails",
      id: "generateSalesEmails",
      className: "joyride-generate-sales-email",
      name: "Generate Sales Emails",
      icon: <Icons.CampaignIcon color="#805ad6" />,
    },
    {
      key: "persanaQuantum",
      id: "persanaQuantum",
      name: "Use Agent",
      className: "joyride-use-agent",
      icon: (
        <img src={persanaQLogo} className="w-[14px]" alt="Persana quantum" />
      ),
    },
  ];

  if (selectedRowsId?.length) {
    items.push({
      key: "divider-2",
      label: <MenuDivider />,
    });

    items.push({
      label: (
        <RemoveRows
          title={`Delete ${selectedRowsId.length} Rows`}
          isAnyProcessRunning={isAnyProcessRunning}
        />
      ),
      key: "6",
    });
  }
  const showTemplateIntoMessage = (templateId: string) => {
    const templateInfo = messages.find(
      (item) => item.templateId === templateId,
    );
    if (templateInfo) {
      Swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: "success",
        title: "Table copied successfully",
        html: templateInfo.message,
        confirmButtonText: "Continue",
      });
    }
  };
  const handleImportTable = async () => {
    if (currentUser?._id) {
      Swal.fire({
        html: "<h5>Copying table</h5>",
        confirmButtonText: "Open Table",
      });
      Swal.showLoading();
      await handleCopyTable();
    } else {
      const URL = `${window.location.pathname}${window.location.search}`;
      navigate({
        to: `/login?redirect=${encodeURIComponent(URL)}` as `/login`,
      });
    }
  };

  useEffect(() => {
    const templateId = new URLSearchParams(window.location.search).get(
      "templateId",
    );
    if (isTablePreviewPage && templateId) {
      handleImportTable();
    }
  }, []);
  const handleEnrichmentButtonClick = (enrichmentKey: string) => {
    const selectedEnrichment = allEnrichments.find(
      (item) => item.id === enrichmentKey,
    );
    updateState({
      selectedEnrichments: selectedEnrichment,
      isOpenEnrichModal: false,
      viewMode: "create",
    });
  };

  return (
    <div className="shrink-0">
      <div className=" flex items-center justify-between">
        <TableName />
        <div className="flex items-center gap-2">
          {(isShowLoader || isRefetching) && (
            <div className="mr-3 flex items-center gap-2">
              <Spinner size="sm" colorScheme="purple" />
            </div>
          )}
          <DownloadsList />
          {isTablePreviewPage ? (
            <Button
              size={"sm"}
              isLoading={isPending}
              leftIcon={<img src={ImportDataIcon} alt="import-data" />}
              onClick={handleImportTable}
              variant="solid"
              fontSize={14}
              className="!bg-primary-gradient hover:opacity-80"
              colorScheme="purple"
              borderRadius={"6px"}
            >
              Start Using this Workflow
            </Button>
          ) : (
            <>
              <TableInfo />
              <ParentTable />
              <LinkedTables />
              <Menu colorScheme="purple" autoSelect={false}>
                {({ onClose }) => (
                  <>
                    <MenuButton
                      colorScheme="purple"
                      as={Button}
                      size={"sm"}
                      variant={"outline"}
                      rightIcon={<Icons.ChevronDownIcon />}
                    >
                      Actions
                    </MenuButton>
                    <MenuList zIndex={"dropdown"}>
                      <ImportData
                        isAnyProcessRunning={isAnyProcessRunning}
                        isMenuItem
                      />
                      <ExportData
                        isAnyProcessRunning={isAnyProcessRunning}
                        isMenuItem
                      />
                      {items.map((item) => (
                        <Fragment key={item.key}>
                          {React.cloneElement(item.label, { onClose })}
                        </Fragment>
                      ))}
                      {tableFilters?.filters?.length ? (
                        <RemoveRows
                          title={`Delete All Visible Rows`}
                          isForDelFiltered={true}
                          isAnyProcessRunning={isAnyProcessRunning}
                        />
                      ) : null}
                    </MenuList>
                  </>
                )}
              </Menu>
              <ButtonGroup
                isAttached
                size={"sm"}
                colorScheme="purple"
                variant={"outline"}
                className="flex items-center"
              >
                <ShareTable isAnyProcessRunning={isAnyProcessRunning} />
              </ButtonGroup>
            </>
          )}
        </div>
      </div>
      {!isTablePreviewPage && (
        <div className="mb-1 flex items-center justify-between">
          <ButtonGroup
            isAttached
            variant={"ghost"}
            className="flex items-center gap-0 bg-[#ffffff2e]"
          >
            {encrichmentItems.map((item) => (
              <Button
                fontWeight={400}
                colorScheme="primary"
                className={`flex !h-[35px] items-center rounded-md !text-sm !text-black ${item.className}`}
                leftIcon={item.icon}
                onClick={() => handleEnrichmentButtonClick(item.id)}
                isDisabled={false}
                key={item.key}
              >
                {item.name}
              </Button>
            ))}
          </ButtonGroup>
          <Button
            size={"sm"}
            mr={"auto"}
            ml={3}
            className=" joyride-enrich-data flex items-center rounded-md  !bg-primary-gradient font-semibold !text-white"
            leftIcon={<img src={enrichmentSVG} alt="" />}
            onClick={() => {
              updateState({
                isOpenEnrichModal: true,
                selectedEnrichments: null,
              });
            }}
            isDisabled={false}
          >
            Enrich Data
          </Button>
          <div className="flex items-center gap-4">
            <TableSearch />
            <Filters isRetching={false} />
            <PendingQueues />
            <ColumnList />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageActions;
