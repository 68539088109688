import {
  Box,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useGridOptions } from "./hooks/useGridOptions";
import { NoTablesView } from "./components/NoTablesView";
import { CreateNew } from "./components/CreateNew";
import StyleProvider from "@/providers/StyleProvider";
import "./index.css";
import { TableState, TRowId } from "./types";
import { userStore } from "@/stores/user.store";
import { useMemo } from "react";
import {
  createTableStore,
  tableListContext,
} from "./hooks/useTableState.store";
import Icons from "@/components/Icons";
import { StoreApi, useStore } from "zustand";

export const TableListComponent = ({
  variant = "full",
  tableListStore,
}: {
  tableListStore: StoreApi<TableState>;
  path?: TRowId[];
  variant?: "mini" | "full";
}) => {
  const searchValue = useStore(tableListStore, (state) => state.search);
  const setSearchValue = useStore(tableListStore, (state) => state.updateState);
  const gridOptions = useGridOptions();
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );
  const initOptions =
    variant === "mini"
      ? {
          editable: false,
          showUpdatedAt: false,
          showFolders: false,
        }
      : {
          editable: true,
          showUpdatedAt: true,
          showFolders: true,
        };
  return (
    <StyleProvider>
      <div className="my-4 mx-4 flex items-center justify-between">
        {initOptions.editable &&
          (variant === "mini" ? null : <CreateNew mr="2em" />)}
        <InputGroup width="100%" className="mr-1">
          <InputLeftElement>
            <Icons.SearchIcon size={20} color="gray" />
          </InputLeftElement>
          <Input
            style={{ borderRadius: "20px" }}
            placeholder="Search tables..."
            size="md"
            variant="outline"
            value={searchValue}
            onChange={(event) => {
              setSearchValue({ search: event?.target.value ?? "" });
            }}
          />
        </InputGroup>
      </div>
      <Divider />
      <Box
        overflowX="auto"
        className="folder-table"
        style={{
          scrollbarWidth: "none",
        }}
      >
        <Box
          maxHeight={
            variant === "full"
              ? `calc(100vh - 300px - ${isUpgradeBannerShown ? "45px" : "0px"})`
              : "auto"
          }
        >
          <AgGridReact
            context={{
              ...(gridOptions.context ?? null),
              initOptions,
              variant,
            }}
            className="custom-scrollbar ag-theme-quartz !h-[540px]"
            gridOptions={gridOptions}
            noRowsOverlayComponent={NoTablesView}
            loadingOverlayComponent={NoTablesView}
          />
        </Box>
      </Box>
    </StyleProvider>
  );
};
export const TableList = ({
  children = null,
}: {
  children?: React.ReactNode;
}) => {
  const store = useMemo(() => createTableStore(), []);
  return (
    <tableListContext.Provider value={store}>
      {<TableListComponent tableListStore={store} />}
      {children}
    </tableListContext.Provider>
  );
};
export default TableList;
