import SalesNavIcon from "@/assets/images/sales-nav.png";
import FindCompanyIcon from "@/assets/svgs/find-company.svg";
import FindPeopleIcon from "@/assets/svgs/find-people-icon.svg";
import GithubIcon from "@/assets/svgs/github.svg";
import GoogleLocationIcon from "@/assets/svgs/google-location.svg";
import GoogleSheetIcon from "@/assets/svgs/google-sheet.svg";
import HubspotIcon from "@/assets/svgs/hubspot.svg";
import WebhookIcon from "@/assets/svgs/sidebar-web-research.svg";
import SalesforceIcon from "@/assets/svgs/salesforce.svg";
import GoogleIcon from "@/assets/images/google.png";
import Icons from "@/components/Icons";
import { ActionType } from "./types";

export const allActions: {
  name: string;
  maxCards: number;
  actions: {
    title: string;
    description: string;
    icon: string;
    highlight?: boolean;
    actionType?: ActionType;
    isForPro?: boolean;
  }[];
}[] = [
  {
    name: "",
    maxCards: 2,
    actions: [
      {
        title: "Find People",
        description:
          "Easily search by skills, experience, location, and more to find relevant contacts. Gain access to contact information and initiate outreach with ease.",
        icon: FindPeopleIcon,
        actionType: "people",
      },
      {
        title: "Find Companies",
        description:
          "Access comprehensive profiles of millions of global public and private companies through our extensive database, offering insights for informed decisions and strategic partnerships.",
        icon: FindCompanyIcon,
        highlight: true,
        actionType: "companies",
      },
    ],
  },
  {
    name: "Suggested Enrichments",
    maxCards: 3,
    actions: [
      {
        title: "Import CSV",
        description: "",
        icon: GoogleSheetIcon,
        actionType: "csv",
      },
      {
        title: "Import from Web Search",
        description: "",
        icon: GoogleIcon,
        actionType: "googleSearch",
      },
      {
        title: "Find Local Businesses",
        description: "",
        icon: GoogleLocationIcon,
        actionType: "localBusinesses",
      },
      {
        title: "Find Stargazers on GitHub",
        description: "",
        icon: GithubIcon,
        actionType: "github",
      },
    ],
  },
  {
    name: "Automate Your Workflows",
    maxCards: 3,
    actions: [
      {
        title: "Import Data From Webhook",
        description: "",
        icon: WebhookIcon,
        actionType: "webhook",
      },
      {
        title: "Import Data from Salesforce",
        description: "",
        icon: SalesforceIcon,
        actionType: "salesforce",
      },
      {
        title: "Import Data From Hubspot",
        description: "",
        icon: HubspotIcon,
        actionType: "hubspot",
      },
      {
        title: "Import using Sales Nav URL",
        description: "",
        icon: SalesNavIcon,
        actionType: "salesNavImport",
        isForPro: true,
      },
    ],
  },
];

export const TableOptions = [
  {
    name: "Company List",
    payload: {
      columns: [
        { name: "Company Name" },
        { name: "Company Domain" },
        { name: "LinkedIn Company Profile" },
      ],
    },
    icon: () => (
      <Icons.CompanyDetailsIcon className="mr-4 text-xl" color="#3C22BB" />
    ),
  },
  {
    name: "People List",
    payload: undefined,
    icon: () => <Icons.PeopleIcon className="mr-4 text-xl" color="#3C22BB" />,
  },
  {
    name: "Custom List",
    payload: undefined,
    icon: () => (
      <Icons.ViewTableIcon className="mr-4 text-xl" color="#3C22BB" />
    ),
  },
  // {
  //   name: "Auto Pilot Workflow",
  //   payload: undefined,
  //   icon: () => (
  //     <Icons.FindPeopleIcon className="mr-4 text-xl" color="#3C22BB" />
  //   ),
  // },
];
