import { Button, IconButton, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";

import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { RowData } from "@/types/table.types";
import { useProcessingStore } from "@/stores/processing.store";
import { useNavigate } from "@tanstack/react-router";
interface Props {
  id?: string;
  tableId: string;
  gridRef: React.RefObject<AgGridReact>;
  className?: string;
  mini?: boolean;
}

export const AddRow = ({ id, tableId, gridRef, className, mini }: Props) => {
  const toast = useToast();
  const navigate = useNavigate({
    from: "/table/$tableId",
  });
  const processingData = useProcessingStore((state) => state.processingData);
  const isAnyProcessRunning = !!processingData?.length;
  const totalPages = useTableStore((state) => state.totalPages);
  const updateRows = useTableStore((state) => state.updateRows);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => tableService.insertRow(tableId),
  });

  const handleAddRow = async () => {
    await mutateAsync(undefined, {
      onSuccess: (response) => {
        if (!response.data?.success) {
          return toast({
            position: "top-right",
            title: response.data?.message || "Failed to create new row",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        const newRow = response.data?.data as unknown as RowData[];

        if (newRow) {
          updateRows([...newRow], false);
          useTableStore.getState().updateState({
            queryParams: {
              ...useTableStore.getState().queryParams,
              pageNumber: totalPages,
            },
          });
          gridRef.current?.api.paginationGoToPage(totalPages - 1);
          gridRef.current?.api?.refreshCells({ force: true });
          const totalRecords = gridRef.current?.api.getDisplayedRowCount() || 1;
          gridRef.current?.api.redrawRows();
          gridRef.current?.api.ensureIndexVisible(totalRecords, "top");
        }

        if (totalPages > 1) {
          navigate({
            search: { page: totalPages },
            params: {
              tableId: tableId,
            },
          });
        }
      },
      onError: () => {
        toast({
          position: "top-right",
          title: "Failed to create new row",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };
  if (mini) {
    return (
      <IconButton
        id={id}
        isDisabled={isAnyProcessRunning}
        isLoading={isPending}
        className={className}
        size={"xs"}
        colorScheme="purple"
        aria-label="Add Row"
        onClick={handleAddRow}
        icon={<Icons.AddIcon />}
      />
    );
  }
  return (
    <Button
      id={id}
      leftIcon={<Icons.AddIcon size={20} />}
      size="sm"
      className="m-auto w-full"
      variant={"outline"}
      onClick={handleAddRow}
      isLoading={isPending}
      isDisabled={isAnyProcessRunning}
    >
      <span className="flex-1">Add Row</span>
    </Button>
  );
};
