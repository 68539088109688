import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import Sidebar from "./Sidebar";
import MarketplaceSidebar from "../Marketplace/Sidebar";
import MainContent from "./MainContent";
import MarketPlaceMainContent from "../Marketplace/MainContent";
import { useEnrichStore } from "@/stores/enrich.store";
import Search from "./Search";
import { IntegrationCategoryType } from "./constants";
import { useState } from "react";
import WorkflowTemplates from "../TemplateTab/MainContent";

const EnrichModal = () => {
  const [activeCategory, setActiveCategory] =
    useState<IntegrationCategoryType>("All");
  const isOpenEnrichModal = useEnrichStore((state) => state.isOpenEnrichModal);
  const updateState = useEnrichStore((state) => state.updateState);

  const handleActiveCategory = (categoryType: IntegrationCategoryType) => {
    setActiveCategory(categoryType);
  };

  return (
    <Modal
      isCentered
      onClose={() => {
        updateState({
          isOpenEnrichModal: false,
        });
      }}
      isOpen={isOpenEnrichModal}
      motionPreset="scale"
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      {/* linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) */}
      <ModalContent className="h-[680px] ">
        <ModalBody className="font-primary relative !m-0 inline-block size-full overflow-hidden rounded-[20px] bg-white !p-0 text-left align-bottom transition-all sm:my-8 sm:w-full sm:align-middle">
          <Search />
          <Tabs className="mt-4">
            <TabList className="px-8" borderBottom={"1px solid #E2E8F0"}>
              <Tab
                className="mr-8 !p-0"
                color="#7E889F"
                _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
              >
                Enrichments
              </Tab>
              <Tab
                className="mr-8 !p-0"
                color="#7E889F"
                _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
              >
                Marketplace
              </Tab>
              <Tab
                className="!p-0"
                color="#7E889F"
                _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
              >
                Templates
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel className="!p-0">
                <div className="flex justify-between">
                  <Sidebar />
                  <MainContent />
                </div>
              </TabPanel>
              <TabPanel className="!p-0">
                <div className="flex justify-between">
                  <MarketplaceSidebar
                    activeCategory={activeCategory}
                    handleCategory={handleActiveCategory}
                  />
                  <MarketPlaceMainContent activeCategory={activeCategory} />
                </div>
              </TabPanel>
              <TabPanel className="!p-0">
                <WorkflowTemplates />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnrichModal;
