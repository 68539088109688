import {
  Box,
  Button,
  VStack,
  ButtonGroup,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import {
  ColumnSchema,
  getDescriptionDesc,
  summaryField,
  useColumnStore,
} from "./schema.store";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import Icons from "@/components/Icons";
import { EditableRow } from "./EditableRow";
import { convertSlateToText } from "@/utils";
import { Descendant } from "slate";
import { useMemo } from "react";
import { shuffle } from "lodash";
import { colorList } from "@/lib/utils";

const createEmptyColumn = (): ColumnSchema => ({
  name: "",
  type: "string",
  description: getDescriptionDesc(""),
});

export const SchemaCreator = ({
  prompt,
  readonly,
  isEditView,
}: {
  prompt: Descendant[];
  readonly: boolean;
  isEditView: boolean;
}) => {
  const toast = useToast({
    position: "top-right",
  });
  const isSummaryAdded = useColumnStore((state) => state.isSummaryAdded());
  const toggleSummary = useColumnStore((state) => state.toggleSummary);
  const textPrompt = useMemo(() => convertSlateToText(prompt).text, [prompt]);
  const { editingIndex, setEditingIndex, columns, addColumn } =
    useColumnStore();
  const handleAddColumn = () => {
    const newColumn = createEmptyColumn();
    addColumn(newColumn);
  };
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      tableService.generateColumns({
        prompt: textPrompt,
      }),
    onSuccess: (response) => {
      if (response?.data?.columns) {
        useColumnStore.setState({
          editingIndex: null,
          columns: [
            summaryField,
            ...response.data.columns.map((col) => {
              if (!col.type.startsWith("enum"))
                return {
                  ...col,
                  options: undefined,
                  description: getDescriptionDesc(col.description),
                };
              const colorPalette = shuffle(colorList);
              const options: ColumnSchema["options"] = col.options?.map(
                (option) => ({
                  value: option,
                  color: colorPalette.shift() as string,
                }),
              );
              return {
                ...col,
                options,
                colorPalette,
                description: getDescriptionDesc(col.description),
              };
            }),
          ],
          mainColumn: response.data.mainColumn,
          addToTable: response.data.columns
            .map(
              (col, index) =>
                [
                  index,
                  response.data?.pinnedColumns?.includes(col.name),
                ] as const,
            )
            .filter(([, isPinned]) => isPinned)
            .map(([index]) => index),
        });
      }
    },
    onError: () => {
      console.error("Failed to generate columns");
      toast({
        title: "Failed to generate columns",
        status: "error",
        isClosable: true,
      });
    },
  });
  return (
    <Box p={4} className="flex w-full flex-col gap-1">
      {isEditView && (
        <p className="text-xs text-darkGray italic mb-1">
          Note: Details of the generated columns cannot be modified once they
          have been created.
        </p>
      )}
      <ButtonGroup className="flex w-full flex-row justify-between" size="xs">
        <Button
          isDisabled={readonly}
          variant={"outline"}
          colorScheme="purple"
          aria-label="Add Column"
          leftIcon={<Icons.FaPlus />}
          onClick={handleAddColumn}
        >
          Add Column
        </Button>
        <span className="flex flex-row items-center gap-2">
          <label className="flex items-center gap-1 text-[#8e8e8e]" hidden>
            Include Summary
            <Checkbox
              isChecked={isSummaryAdded}
              onChange={() => toggleSummary()}
              colorScheme="purple"
            />
          </label>
          <Button
            isDisabled={!textPrompt || readonly}
            aria-label="Generate Columns"
            isLoading={isPending}
            onClick={() => mutateAsync()}
            leftIcon={<Icons.BsMagic />}
            className="btn-primary-outline"
          >
            Generate Columns
          </Button>
        </span>
      </ButtonGroup>
      <VStack spacing={0} className="w-full">
        {columns.map((col, index) => (
          <EditableRow
            readonly={readonly}
            key={index}
            column={col}
            isOpen={editingIndex === index}
            onEditing={(editing: boolean = true) =>
              setEditingIndex(editing ? index : -1)
            }
            index={index}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default SchemaCreator;
