import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import PrimaryLoader from "@/components/Common/PrimaryLoader";
import { createANewColumnName } from "@/components/Table/utils";
import tableService from "@/services/table.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { useTableStore } from "@/stores/table.store";
import { Cell, ColumnCreatePayload } from "@/types/table.types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ResponseDropdown from "./ResponseDropdown";

interface CreateColumn {
  payload: ColumnCreatePayload;
  query: any;
}

function EnrichmentResponse() {
  const queryClient = useQueryClient();

  const [selectedCell, setSelectedCell] = useState<
    | (Cell & {
        metaData: any;
      })
    | null
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const isProcessing = useTableStore(
    (state) => state.tableData.metaData?.isProcessing,
  );
  const updateState = useTableStore((state) => state.updateState);
  const rowsData = useTableStore((state) => state.rowsData);

  const toast = useToast();

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  };

  const { mutateAsync: createColumnAsync } = useMutation({
    mutationFn: (createColumnData: CreateColumn) =>
      tableService.insertColumn(
        tableId,
        createColumnData.payload,
        createColumnData.query,
      ),
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({ columnId, rowId }: { columnId: string; rowId: string }) =>
      tableService.getCellData(tableId, rowId, columnId),
  });

  const handleAddNewColumn = async (newColumnData: any) => {
    if (isProcessing) {
      toast({
        position: "top-right",
        title:
          "Already one process is running. Please wait until it completes.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const rowIds = rowsData.map((rowData) => rowData._id);

    await createColumnAsync(
      {
        payload: {
          name: createANewColumnName(
            newColumnData?.newColumnName || "New Column",
          ),
          rowIds,
        },
        query: {
          colType: "addFromResponse",
          colId: selectedEnrichments?.columnData
            ? selectedEnrichments?.columnData._id
            : "",
          path: newColumnData?.path || "",
          isObjectKey: newColumnData?.isObjectKey || false,
        },
      },
      {
        onSuccess: (response) => {
          if (!response.data?.success) {
            return toast({
              position: "top-right",
              title: response.data?.message || "Failed to create new column",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }

          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
          if (response.data?.success) {
            updateState({
              tableData: response.data.data,
              isCreatedNewColumn: true,
            });
            // TODO: Add queued

            setTimeout(() => {
              updateState({
                isCreatedNewColumn: false,
              });
            }, 2000);
          }
          toast({
            position: "top-right",
            title: "New column created",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            position: "top-right",
            title: "Failed to create new column",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const fetchCellData = async () => {
    if (selectedEnrichments?.columnData && selectedEnrichments?.rowData) {
      setIsLoading(true);
      await mutateAsync(
        {
          columnId: selectedEnrichments.columnData._id,
          rowId: selectedEnrichments.rowData._id,
        },
        {
          onSuccess: (response) => {
            setSelectedCell(response);
            setIsLoading(false);
          },
          onError: (error) => {
            console.log("error -->", error);
            setSelectedCell(null);
            setIsLoading(false);
          },
        },
      );
    }
  };

  useEffect(() => {
    fetchCellData();
  }, [selectedEnrichments]);

  return (
    <div className="grow overflow-y-auto bg-[#F6F6F8] p-4">
      {isLoading ? (
        <div className="flex size-full grow items-center justify-center">
          <PrimaryLoader />
        </div>
      ) : (
        <ResponseDropdown
          responseObj={
            typeof selectedCell?.metaData?.responseValue === "string"
              ? { response: selectedCell?.metaData?.responseValue }
              : selectedCell?.metaData?.responseValue ||
                selectedCell?.metaData ||
                {}
          }
          handleAddNewColumn={handleAddNewColumn}
          handleCopyToClipboard={handleCopyToClipboard}
          enrichmentName={selectedEnrichments?.enrichmentName || ""}
        />
      )}
    </div>
  );
}

export default EnrichmentResponse;
