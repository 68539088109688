import { useModalStore } from "@/stores/modal.store";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { allTemplates } from "./constants";
import Icons from "@/components/Icons";

const UseTemplateModal = () => {
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  return (
    <Modal
      closeOnOverlayClick
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "use-template"}
      motionPreset="scale"
      size="5xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

      <ModalContent className="rounded-[20px]">
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalHeader>
          <p className="w-full text-center text-2xl">Select a template</p>
        </ModalHeader>
        <ModalBody className="font-primary relative !m-0 inline-block size-full max-h-[500px] overflow-y-auto bg-white !p-0 text-left align-bottom transition-all sm:my-8 sm:w-full">
          <div className="p-6">
            {allTemplates.map((item) => (
              <div
                key={item.templateId}
                className="mb-3 flex items-center justify-between rounded"
              >
                <div>
                  <div className="flex items-center gap-3">
                    <Icons.TemplateIcon className="text-xl text-textPurple" />
                    <p className="text-cellTextGray px-4 py-2 font-[500]">
                      {item.title}
                    </p>
                  </div>
                </div>

                <Link href={item.url} target="_blank">
                  <Button
                    size={"sm"}
                    colorScheme="primary"
                    variant="outline"
                    className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
                  >
                    Use Template
                  </Button>
                </Link>
              </div>
            ))}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UseTemplateModal;
