import { categories } from "./constants";
import { useEnrichStore, CategoryType } from "@/stores/enrich.store";

const Sidebar = () => {
  const activeCategory = useEnrichStore((state) => state.activeCategory);
  const updateState = useEnrichStore((state) => state.updateState);

  const handleCategory = (category: CategoryType) => {
    const newActiveCategory = category === activeCategory ? "" : category;

    updateState({
      activeCategory: newActiveCategory,
      searchInput: "",
    });
  };

  return (
    <div className="enrichment-modal max-h-full w-[25%] border-r px-6 py-4">
      <div className="rounded-[6px] border pb-[8px]">
        <p className="w-full bg-gray px-4 pb-2 pt-4 text-[16px] font-medium text-[#0F0D1C]">
          Categories
        </p>
        <div className="flex max-h-[312px] flex-col overflow-y-auto px-4">
          {categories.map((item) => (
            <div
              key={item.name}
              className={`flex cursor-pointer items-center gap-[15px] py-[12px] ${
                item.name === activeCategory
                  ? "text-[#F5F3FF]"
                  : "text-[#F9F8F9]"
              }`}
              onClick={() => handleCategory(item.name)}
            >
              {typeof item.icon === "string" ? (
                <img src={item.icon} alt="Enrichment Icon" />
              ) : (
                <item.icon />
              )}
              <p
                className={`text-[14px] font-medium leading-[16px] ${
                  item.name === activeCategory
                    ? "font-[500] text-textPurple"
                    : "font-[400] text-black"
                } `}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
