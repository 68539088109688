import { Box, Stack, Tag, Text, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";

import { useEnrichStore } from "@/stores/enrich.store";
import { userStore } from "@/stores/user.store";
import Icons from "../Icons";
import {
  allEnrichments as _allEnrichments,
  _showSubtitlesInEnrichmentList,
  PRO_RESOURCES_ALLOWED_PLAN_TYPES,
} from "./constants";
import _ from "lodash";
import { useModalStore } from "@/stores/modal.store";
import { PresetSearch } from "../Presets/PresetSearch";

const AllEnrichments = ({
  isForMarketplace = false,
  providerName = "",
}: {
  isForMarketplace?: boolean;
  providerName?: string;
}) => {
  const navigate = useNavigate();
  const activeCategory = useEnrichStore((state) => state.activeCategory);
  const searchInput = useEnrichStore((state) => state.searchInput);
  const currentUser = userStore((state) => state.currentUser);
  const updateState = useEnrichStore((state) => state.updateState);
  const { updateState: updateModalState } = useModalStore();
  const subscriptionDetails = userStore((state) => state.subscriptionDetails);

  const isAdmin = currentUser?.role === "admin";
  const isPro = PRO_RESOURCES_ALLOWED_PLAN_TYPES.includes(
    currentUser?.planType?.toLocaleLowerCase() ?? "",
  );
  const isUnlimited = ["unlimited"].includes(currentUser?.planType ?? "");

  const filteredEnrichments = useMemo(() => {
    let allEnrichments = [..._allEnrichments];
    if (!isAdmin) {
      allEnrichments = allEnrichments.filter(
        (item) =>
          !item.isForAdmin ||
          item?.allowEmails?.includes(currentUser.Email?.toLocaleLowerCase()),
      );
    }
    if (!isPro) {
      allEnrichments = allEnrichments.filter((item) => !item.isForPro);
    }

    if (isForMarketplace) {
      allEnrichments = allEnrichments.filter((item) =>
        // @ts-ignore
        item.provider?.includes(providerName),
      );
    }

    if (searchInput.trim()) {
      return allEnrichments.filter(
        (item) =>
          item?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.keywords?.includes(searchInput.toLowerCase()),
      );
    }

    if (!activeCategory.trim()) return allEnrichments;
    if (activeCategory === "All Enrichments") {
      return allEnrichments;
    } else {
      return allEnrichments.filter((item) =>
        !item.categories ? true : item.categories?.includes(activeCategory),
      );
    }
  }, [activeCategory, searchInput, isAdmin]);

  return (
    <div
      className={`custom-scrollbar w-[100%] ${!isForMarketplace && "max-h-[310px] overflow-y-auto pb-2"}`}
    >
      <Stack
        key={filteredEnrichments.length}
        direction={"column"}
        gap={"6px"}
        overflow={"auto"}
        border={"1px solid #E2E8F0"}
        borderRadius={"lg"}
      >
        {filteredEnrichments
          .filter((item) => {
            if (item.isForStaging) {
              return import.meta.env.VITE_NODE_ENV === "staging";
            }
            return (
              !item.isHidden ||
              (item.name === "Persana AI Agent 2.0" &&
                currentUser?.role === "admin")
            );
          })
          .map((item) => {
            return (
              <Stack
                id={item.id}
                direction={"row"}
                p={"10px 24px"}
                alignItems={"center"}
                cursor={"pointer"}
                gap={"16px"}
                key={item.id}
                className="relative"
                borderBottom={"1px solid #E2E8F0"}
                onClick={() => {
                  if (item.name === "AI Formula") {
                    updateState({
                      isOpenEnrichModal: false,
                      generateFormulaModal: {
                        isOpen: true,
                        type: "formula",
                      },
                    });
                  } else if (item.name === "Playbooks") {
                    navigate({ to: "/playbooks" });
                  } else {
                    updateState({
                      selectedEnrichments: item,
                      isOpenEnrichModal: false,
                      viewMode: "create",
                    });
                  }
                  updateModalState({
                    isOpenModal: false,
                    modalData: {
                      modalType: "",
                    },
                  });
                }}
                background={"#fff"}
              >
                <div className="absolute right-[15px] top-[35%] flex items-center justify-center gap-[4px]  ">
                  {item.isUseOwnKey && (
                    <Tooltip
                      hasArrow
                      label="You can use your own key/account"
                      bg="#2bbf71"
                      fontWeight={500}
                      borderRadius={"lg"}
                      fontSize={"12px"}
                      color="#fff"
                      placement="top"
                    >
                      <div
                        className={` flex items-center justify-center gap-[4px] rounded-[4px] px-[4px] py-[2px]`}
                      >
                        <Icons.KeyIcon className="text-sm text-lightGray" />
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip
                    hasArrow
                    label="Persana Credits"
                    bg="#2bbf71"
                    fontWeight={500}
                    borderRadius={"lg"}
                    fontSize={"12px"}
                    color="#fff"
                    placement="top"
                  >
                    <div className="flex items-center justify-center gap-[4px] rounded-[4px] bg-[#e6fce9] px-[4px]  py-[2px] text-[#2bbf71]">
                      <span className="text-[12px] font-[500]">
                        {item.credits}
                      </span>
                      <Icons.CoinsIcon className="text-sm text-[#2bbf71]" />
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <Box
                    boxShadow="xl"
                    className="flex !w-[24px] items-center justify-center"
                  >
                    {typeof item.icon === "string" ? (
                      <img
                        src={item.icon}
                        alt="enrichment-icon"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <item.icon
                        color={`${item.name === "Use AI" ? "#0284FE" : "gray"}`}
                        className="size-5"
                      />
                    )}
                  </Box>
                </div>

                <Stack direction={"column"} gap={"8px"}>
                  <div className="flex items-center gap-2">
                    <Tooltip
                      label={item?.label}
                      bg="gray.300"
                      color="black.500"
                      borderRadius={10}
                    >
                      <Text fontWeight={400} fontSize={"16px"}>
                        {item.name}
                      </Text>
                    </Tooltip>
                    {!isUnlimited &&
                      item.categories?.includes("Persana Unlimited") && (
                        <Tag
                          size="sm"
                          bg={"#F8F0FF"}
                          textColor={"#693DC7"}
                          borderRadius="full"
                          cursor="pointer"
                          _hover={{ bg: "#E2D1FF" }}
                          display="flex"
                          alignItems="center"
                          gap="4px"
                          onClick={(e) => {
                            e.stopPropagation();
                            updateModalState({
                              isOpenModal: true,
                              modalData: {
                                modalType: "pricing",
                                action: "change",
                                openFrom: "dashboard",
                                currentPlan: {
                                  ...subscriptionDetails?.currentPlan,
                                  amount: subscriptionDetails?.nextCycleAmount,
                                },
                              },
                            });
                          }}
                        >
                          Free on Persana Unlimited
                          <span style={{ marginLeft: "4px" }}>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                              <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                          </span>
                        </Tag>
                      )}
                  </div>
                  {_showSubtitlesInEnrichmentList.includes(item.id!) && (
                    <Text fontWeight={400} fontSize={"12px"} w={"90%"}>
                      {item.subtitle}
                    </Text>
                  )}
                </Stack>
              </Stack>
            );
          })}
        <PresetSearch searchTerm={searchInput} />
      </Stack>
    </div>
  );
};

export default AllEnrichments;
