import { Table, Tbody, Td, Th, Thead, Tr, Box } from "@chakra-ui/react";

const PeopleDetailsRenderer = ({ cellDetails }: { cellDetails: any }) => {
  const profiles = cellDetails?.metaData?.responseValue?.profiles || [];

  return (
    <div className="container mx-auto p-4">
      <Box
        overflowY="scroll"
        maxH="500px"
        borderWidth="1px"
        borderRadius="lg"
        className="!custom-scrollbar"
      >
        {profiles?.length ? (
          <Table variant="simple" size="sm">
            <Thead bg="gray.800" height={35}>
              <Tr>
                <Th textAlign="center" color="white">
                  Name
                </Th>
                <Th textAlign="center" color="white">
                  LinkedIn URL
                </Th>
                <Th textAlign="center" color="white">
                  Title
                </Th>
                <Th textAlign="center" color="white">
                  Company
                </Th>
                <Th textAlign="center" color="white">
                  Location
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {profiles?.map((profile: any, index: number) => (
                <Tr key={index}>
                  <Td textAlign="center">
                    {profile.first_name || ""} {profile.last_name || ""}
                  </Td>
                  <Td textAlign="center">
                    {profile.linkedin_url ? (
                      <a
                        href={profile.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        View Profile
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </Td>
                  <Td textAlign="center">{profile.title || "N/A"}</Td>
                  <Td textAlign="center">{profile.company_name || "N/A"}</Td>
                  <Td textAlign="center">{profile.locality || "N/A"}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <p className="w-full py-2 px-4 text-left">0 Profiles found</p>
        )}
      </Box>
    </div>
  );
};

export default PeopleDetailsRenderer;
