/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import CellExpendViewModal from "../Enrichment/CellExpendViewModal";
import BottomPageActions from "./PageActions/BottomPageActions";
import DataTable from "./data-table";

import useSocket from "@/lib/socket";
import { downloadFile } from "@/lib/utils";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import { useEnrichStore } from "@/stores/enrich.store";

import type {
  ISocketData,
  ISocketMessageData,
  ISocketProcessingData,
  ISocketRefreshApiCalls,
} from "@/types/socket.types";
import { convertObject } from "@/utils";
import AutoRun from "./PageActions/AutoRun";
import RunningProcesses from "./PageActions/RunningProcesses";
import Pagination from "./Pagination";
import { allEnrichments } from "../Enrichment/constants";

interface Props {
  isRefetching: boolean;
  gridRef: React.RefObject<AgGridReact>;
}

const PersanaTable: React.FC<Props> = ({ gridRef, isRefetching }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { subscribe } = useSocket();

  const currentUser = userStore((state) => state.currentUser);
  const updateRows = useTableStore((state) => state.updateRows);
  const updateRowsData = useTableStore((state) => state.updateRowsData);
  const expendedCellValue = useTableStore((state) => state.expendedCellValue);
  const isWebhook = useTableStore(
    (state) => !!state.tableData.metaData?.isWebhook,
  );
  const columns = useTableStore((state) => state.tableData.columns);
  const totalRows = useTableStore((state) => state.totalRows);
  const updateTableMetaDta = useTableStore((state) => state.updateTableMetaDta);
  const updateProcessingData = useProcessingStore(
    (state) => state.updateProcessingData,
  );
  const updateRowsCellValue = useTableStore(
    (state) => state.updateRowsCellValue,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const tableId = useTableStore((state) => state.tableData._id);
  const tableName = useTableStore((state) => state.tableData.name);
  const processesData = useProcessingStore((state) => state.processingData);
  const socketInstance = useTableStore((state) => state.socketInstance);
  const emailEnrichment = localStorage.getItem("enrich");

  const [lastReceivedDataAt, setLastReceivedDataAt] = useState(Date.now());

  useEffect(() => {
    if (!socketInstance?.connected) return;

    subscribe(tableId, async (data: ISocketData) => {
      const {
        processingData,
        rowsData = [],
        isForNew = false,
        isCreateRows,
        columnId,
      } = data;

      setLastReceivedDataAt(Date.now());

      if (rowsData.length) {
        if (isForNew) {
          updateRows(rowsData);
        } else if (isWebhook) {
          updateRowsData(rowsData);
        }
      }

      if (rowsData.length && !isWebhook) {
        const rowsPayload = rowsData.map(({ cells, ...rowData }): any => {
          const cellsObj = convertObject(cells);

          const rowNode = gridRef.current?.api?.getRowNode(rowData._id);
          if (rowNode) {
            if (columnId) {
              updateRowsCellValue({
                rowId: rowData._id,
                columnId,
                cellValue: cells[columnId],
              });
              rowNode.setDataValue(columnId, cells[columnId]?.value);
            }
          }

          return {
            ...cellsObj,
            rowData,
          };
        });

        // Update table
        gridRef.current?.api.flushAsyncTransactions();
        if (isCreateRows) {
          gridRef.current?.api?.applyTransactionAsync({ add: rowsPayload });
        }
      }

      if (processingData) {
        updateProcessingData(processingData);
        gridRef.current?.api?.refreshCells();
      }
    });

    subscribe(`${tableId}-processing`, async (data: ISocketProcessingData) => {
      if (data.tableMetaData) {
        updateTableMetaDta({
          ...data.tableMetaData,
        });
      }
      if (data.downloadLink) {
        downloadFile({
          url: data.downloadLink,
          fileName: `${tableName}.csv`,
        });
      }
    });

    subscribe(`${tableId}-message`, async (data: ISocketMessageData) => {
      if (data?.title === "Remove duplicate rows") {
        queryClient.refetchQueries({
          queryKey: ["table-running-processes", tableId],
        });
      }
      toast({
        title: data.title,
        description: data.description,
        status: data.status,
        duration: data.duration,
        isClosable: true,
        position: "top-right",
      });
    });

    subscribe(
      `REFRESH_API_CALLS-${currentUser._id}`,
      async (data: ISocketRefreshApiCalls) => {
        if (data.key) {
          queryClient.refetchQueries({
            queryKey: [data.key],
          });
        }
      },
    );
  }, [tableId, isWebhook, currentUser, socketInstance]);

  // useEffect(() => {
  //   let interval: NodeJS.Timeout | undefined = undefined;
  //   if (processesData?.length) {
  //     const TIME = 1 * 10 * 1000; // 10 seconds
  //     interval = setInterval(() => {
  //       if (Date.now() - lastReceivedDataAt > TIME) {
  //         queryClient.refetchQueries({
  //           queryKey: ["table", tableId],
  //         });
  //         queryClient.refetchQueries({
  //           queryKey: ["table-running-processes", tableId],
  //         });
  //       }
  //     }, TIME);
  //   } else {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   }

  //   return () => clearInterval(interval);
  // }, [lastReceivedDataAt, processesData]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (processesData?.length) {
      const TIME = 1 * 5 * 1000; // 5 seconds
      interval = setInterval(() => {
        if (Date.now() - lastReceivedDataAt > TIME) {
          queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });
          queryClient.refetchQueries({
            queryKey: ["table-running-processes", tableId],
          });
        }
      }, TIME);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [processesData, tableId, lastReceivedDataAt]);

  useEffect(() => {
    const enrichment = allEnrichments.find(
      (item) => item.id === "findLinkedInDataWithWorkEmail",
    );

    emailEnrichment === "true" &&
      updateState({
        selectedEnrichments: enrichment,
        isOpenEnrichModal: false,
        viewMode: "create",
      });
    // Cleanup function to run when the component unmounts
    return () => {
      // Remove or reset the enrich item in localStorage
      localStorage.removeItem("enrich");
      console.log("localStorage 'enrich' removed");
    };
  }, [emailEnrichment]);

  return (
    <div className="grow">
      <DataTable gridRef={gridRef} isRefetching={isRefetching} />
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <BottomPageActions gridRef={gridRef} tableId={tableId} />
          <RunningProcesses />
        </div>
        <div className="mr-16 flex items-center gap-2">
          <div className="flex items-center gap-4">
            <AutoRun />
            <p className="text-sm text-darkTextGray">
              (Rows: {totalRows}, Columns: {columns.length})
            </p>
          </div>
          <Pagination gridRef={gridRef} />
        </div>
      </div>
      {expendedCellValue.isOpen && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <CellExpendViewModal gridRef={gridRef} />
        </React.Suspense>
      )}
    </div>
  );
};

export default PersanaTable;
