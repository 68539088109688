/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import { useCallback, useEffect, useRef } from "react";
import { io } from "socket.io-client";

const serverUrl = `${import.meta.env.VITE_BE_BASE_URL}`;

const useSocket = () => {
  const currentUser = userStore((state) => state.currentUser);
  const socketInstance = useTableStore((state) => state.socketInstance);
  const updateState = useTableStore((state) => state.updateState);
  const subscriptionQueue = useRef<Array<[string, (data: any) => void]>>([]);
  const socketRef = useRef(socketInstance);

  useEffect(() => {
    socketRef.current = socketInstance;
  }, [socketInstance]);

  const connect = useCallback(() => {
    if (!socketInstance && currentUser?._id) {
      const instance = io(serverUrl, {
        withCredentials: true,
        transports: ["websocket"],
        path: "/websocket",
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });
      instance.on("connect", () => {
        instance?.emit("join_room", currentUser._id);
        updateState({ socketInstance: instance });

        subscriptionQueue.current.forEach(
          ([eventName, callback]: [string, (data: any) => void]) => {
            instance.on(eventName, callback);
          },
        );
        subscriptionQueue.current = [];
      });
      instance.on("disconnect", () => {
        instance?.emit("leave_room", currentUser._id);
        updateState({ socketInstance: undefined });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?._id, socketInstance]);

  const disconnect = useCallback(() => {
    socketRef.current?.disconnect();
  }, []);

  const send = useCallback((eventName: string, data: any) => {
    socketRef.current?.emit(eventName, data);
  }, []);

  const subscribe = useCallback(
    (eventName: string, callback: (data: any) => void) => {
      if (socketRef.current?.connected) {
        socketRef.current.on(eventName, callback);
      } else {
        subscriptionQueue.current.push([eventName, callback]);
      }

      return () => {
        socketRef.current?.off(eventName, callback);
        subscriptionQueue.current = subscriptionQueue.current.filter(
          ([e, c]) => e !== eventName || c !== callback,
        );
      };
    },
    [],
  );

  return { connect, disconnect, send, subscribe };
};

export default useSocket;
