import MakeAPICall from "@/lib/apiCalls";
import { SingleEnrichmentTypes } from "./../types/enrichment.types";

import type {
  CompanyJobOpeningPayload,
  EnrichmentApiCallPayload,
  SingleEnrichmentPayload,
} from "@/types/enrichment.types";

interface EnrichmentParams {
  domain: string[];
  keyword?: string;
  category?: string;
  country?: string;
  minScore?: number;
  maxScore?: number;
  minSimilarity?: number;
  maxRecords?: number;
}

class Enrichment {
  constructor(private apiCaller = MakeAPICall) {}

  async pandaMatchCompanies({
    domain,
    keyword,
    category,
    country,
    minScore,
    maxScore,
    minSimilarity,
    maxRecords,
  }: EnrichmentParams) {
    const payload = {
      domain: domain?.join(","),
      keyword: keyword,
      category: category,
      country: country,
      min_score: minScore,
      max_score: maxScore,
      min_similarity: minSimilarity,
      max_records: maxRecords,
    };
    const apiCall = new this.apiCaller({
      apiPath: "leads/panda-match-companies",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async companyJobOpening(payload: CompanyJobOpeningPayload) {
    const apiCall = new this.apiCaller({
      apiPath: "enrichment/company-job-openings",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async callEnrichmentApi(payload: Partial<EnrichmentApiCallPayload>) {
    const apiCall = new this.apiCaller({
      apiPath: `enrichment/${payload.enrichmentName}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async googleSrp(query: string, numberOfRecords: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/google-srp`,
      payload: { query, resultNum: numberOfRecords },
    });
    const response = await apiCall.post();
    return response;
  }

  async repoSearch(repoUrl: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/github/repo-search`,
      payload: { repoUrl },
    });
    const response = await apiCall.post();
    return response;
  }

  async githubStargazers(repoUrl: string, totalNumOfStarGazers: number) {
    const apiCall = new this.apiCaller({
      apiPath: "leads/github-star-gazer",
      payload: { repoUrl, totalNumOfStarGazers },
    });
    const response = await apiCall.post();
    return response;
  }

  async googleSearch({
    searchQuery,
    numberOfResults,
  }: {
    searchQuery: string;
    numberOfResults?: number;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "leads/google-search",
      payload: { searchQuery, numberOfResults },
    });
    const response = await apiCall.post();
    return response;
  }

  async localBusinesses({
    searchQuery,
    numberOfResults,
  }: {
    searchQuery: string;
    numberOfResults?: number;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "leads/local-businesses",
      payload: { searchQuery, numberOfResults },
    });
    const response = await apiCall.post();
    return response;
  }

  async callCronJobAPI() {
    const apiCall = new this.apiCaller({
      apiPath: "crons/pending-queues",
    });
    const response = await apiCall.get();
    return response;
  }

  async sendEmail(payload: {
    rowId: string;
    tableId: string;
    columnId: string;
  }) {
    const apiCall = new this.apiCaller({
      apiPath: "enrichment/sendEmailWithGmail",
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getSingleWorkEmail(
    singleEnrichmentName: SingleEnrichmentTypes,
    payload: SingleEnrichmentPayload,
  ) {
    const apiCall = new this.apiCaller({
      apiPath: `enrichment/single/${singleEnrichmentName}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getSingleMobileNumber(
    singleEnrichmentName: SingleEnrichmentTypes,
    payload: SingleEnrichmentPayload,
  ) {
    const apiCall = new this.apiCaller({
      apiPath: `enrichment/single/${singleEnrichmentName}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }
}

const enrichment = new Enrichment();

export default enrichment;
