import { Switch, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import logo from "@/assets/images/logoWithText.png";
import TailwindModalLayout from "@/components/Common/TailwindModalLayout";
import Icons from "@/components/Icons";
import { getCurrentDateInYYYYMM } from "@/lib/utils";
import userService from "@/services/user.service";
import { ModalDataType, useModalStore } from "@/stores/modal.store";
import { pricings } from "./constants";
import { PriceBox, SelectedPriceType } from "./PriceBox";
import UpgradePlanModal from "./UpgradePlanModal";
import { userStore } from "@/stores/user.store";
import Swal from "sweetalert2";
import AIPriceBox from "./AIPriceBox";

interface PricingModalDataType extends ModalDataType {
  action: string;
  openFrom?: string;
}

export interface ConfirmUpgradeProps {
  selectedPrice: SelectedPriceType;
  isUpgrade: boolean;
  isDowngrade: boolean;
  bypassUpgradeModalCheck?: boolean;
}

const PricingModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<SelectedPriceType | null>(
    null,
  );
  const [isAnnual, setIsAnnual] = useState<boolean>(false);
  const [isPlanSwitched, setIsPlanSwitched] = useState<boolean>(false);

  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const subscriptionDetails = userStore((state) => state.subscriptionDetails);

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: ({ priceId }: { priceId: string }) =>
      userService.getSubscriptionLink(priceId),
  });

  const { mutateAsync: mutateAsyncUpdatePlan } = useMutation({
    mutationFn: (payload: { priceId: string; action: string }) =>
      userService.updateSubscriptionPlan(payload),
  });

  const pricingModalData = modalData as PricingModalDataType;

  const handleClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "" },
    });
  };

  const handleActionBtnClick = async ({
    selectedPrice,
    isUpgrade,
    isDowngrade,
    bypassUpgradeModalCheck,
  }: ConfirmUpgradeProps) => {
    setSelectedPrice(selectedPrice);

    if (!selectedPrice?.priceId) {
      window.open(
        `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
        "_blank",
      );
    } else {
      setIsLoading(true);

      if (isDowngrade || isUpgrade) {
        if (isUpgrade && !bypassUpgradeModalCheck) {
          setIsUpgradeModalOpen(true);
          setIsLoading(false);
          return;
        }

        if (isDowngrade) {
          setIsLoading(false);
          Swal.fire({
            title: "Downgrade Plan",
            html: 'Reach out to <a href="mailto:contact@persana.ai" style="color: #0284fe;">contact@persana.ai</a> to do the downgrade.',
            icon: "info",
            confirmButtonText: "OK",
          });
          return;
        }

        await mutateAsyncUpdatePlan(
          {
            action: isDowngrade ? "downgrade" : "upgrade",
            priceId: selectedPrice.priceId,
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                queryClient.invalidateQueries({
                  queryKey: ["user-subscription-details"],
                });
              }, 500);

              setTimeout(() => {
                setIsLoading(false);
                toast({
                  title: "Success",
                  description: "Plan updated successfully",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });

                updateState({
                  isOpenModal: false,
                  modalData: { modalType: "" },
                });
              }, 1000);
            },
            onError: (error) => {
              toast({
                title: "Error",
                description: error?.message || "Something went wrong",
                status: "error",
                duration: 3000,
                isClosable: true,
              });

              setIsLoading(false);
            },
          },
        );

        return;
      }

      await mutateAsync(
        { priceId: selectedPrice.priceId },
        {
          onError: (error) => {
            console.log("error", error);
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            setIsLoading(false);
          },
          onSuccess: (data) => {
            if (!data?.success) {
              toast({
                title: "Error",
                // @ts-ignore
                description: data?.message || "Something went wrong",
                status: "error",
                duration: 3000,
                isClosable: true,
              });

              setIsLoading(false);

              return;
            }
            // @ts-ignore
            if (data?.link) {
              // @ts-ignore
              window.location.href = data.link;
            }
            setIsLoading(false);
          },
        },
      );
    }
  };

  return (
    <TailwindModalLayout
      modalOpen={isOpenModal && pricingModalData.modalType === "pricing"}
      setModalOpen={handleClose}
      closeOnOuterClick
      overlayClassName="bg-[rgba(0,_0,_0,_0.18)]"
      dialogClassName="!z-[2000]"
    >
      <div className="relative inline-block h-full overflow-hidden p-4 text-left align-bottom font-title transition-all sm:w-full sm:max-w-[86.5rem] sm:align-middle">
        <div className="relative size-full overflow-hidden rounded-[26px] bg-white">
          <div className="flex items-center justify-between gap-x-3 border-b border-[#e5e7eb] px-5 py-4">
            <img src={logo} alt="logo" className="w-36" />
            {/* <p className="font-title gradient-text text-xl">
             ( Launch special 50% off for a limited time )
            </p> */}
            <Icons.FillCloseCircleIcon
              className="cursor-pointer text-4xl text-[#693DC7]"
              onClick={handleClose}
            />
          </div>
          <div className="flex items-center justify-center space-x-2 pt-5 font-title text-sm tracking-wide">
            <p className={`${isAnnual ? "" : "font-bold underline"}`}>
              Monthly
            </p>
            <Switch
              checked={isAnnual}
              onChange={() => {
                setIsAnnual(!isAnnual);
                setIsPlanSwitched(true);
              }}
            />
            <p className={`${isAnnual ? "font-bold underline" : ""}`}>Annual</p>
            <p className="font-bold text-primary">Save 20%!</p>
          </div>
          <div className="grid grid-cols-5 content-center gap-3.5 px-7 py-6">
            {pricings.slice(0, 4).map((pricing, index) => (
              <PriceBox
                key={`pricing-${index}`}
                pricing={pricing}
                // @ts-ignore
                onClick={handleActionBtnClick}
                isLoading={isLoading}
                currentPlan={{
                  ...subscriptionDetails?.currentPlan,
                  amount: subscriptionDetails?.nextCycleAmount,
                }}
                openFrom={pricingModalData?.openFrom}
                isAnnual={isAnnual}
                isPlanSwitched={isPlanSwitched}
              />
            ))}
            <AIPriceBox
              key={`pricing-${5}`}
              pricing={pricings[5]}
              // @ts-ignore
              onClick={handleActionBtnClick}
              isLoading={isLoading}
              currentPlan={{
                ...subscriptionDetails?.currentPlan,
                amount: subscriptionDetails?.nextCycleAmount,
              }}
              openFrom={pricingModalData?.openFrom}
              isAnnual={isAnnual}
              isPlanSwitched={isPlanSwitched}
            />
          </div>
        </div>
        {isUpgradeModalOpen && selectedPrice ? (
          <UpgradePlanModal
            isModalOpen={isUpgradeModalOpen}
            setIsModalOpen={setIsUpgradeModalOpen}
            selectedPrice={selectedPrice as SelectedPriceType}
            onConfirmUpgrade={handleActionBtnClick}
          />
        ) : null}
      </div>
    </TailwindModalLayout>
  );
};

export default PricingModal;
