import providerService from "@/services/providers.service";
import { ProviderNameType } from "./../../../../stores/provider.store";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useProviderList } from "@/hooks/useProviderData";

export const useSetKeyPreferred = () => {
  const { data: providerLists } = useProviderList();
  const queryClient = useQueryClient();

  const { mutateAsync: updatePreference } = useMutation({
    mutationFn: async (params: {
      preferKey: boolean;
      keyName: ProviderNameType;
    }) => {
      const provider = providerLists?.data?.find((providerItem) => {
        return providerItem.name === params.keyName;
      });
      if (typeof provider === "undefined") return;
      return providerService.setDefaultKeys({
        providerId: provider._id || "",
        preferKey: params.preferKey,
        alwaysPreferKey: provider.metaData?.alwaysPreferKey || false,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["providers"],
        type: "all",
      });
    },
    onError: (e: any) => {
      console.error("failed to update key preferred", e);
    },
  });

  return updatePreference;
};
