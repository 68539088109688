import { useMutation } from "@tanstack/react-query";

import leadService from "@/services/leads.service";

import { IScraperCompanySearchPayload } from "@/types/leads.types";
import { useSearchLeadsStore } from "@/stores/searchleads.store";

export const useCompanySearch = () => {
  const updateState = useSearchLeadsStore((state) => state.updateState);
  return useMutation({
    mutationFn: ({
      payload,
      isUseMixrank,
    }: {
      payload: IScraperCompanySearchPayload;
      isUseMixrank?: boolean;
    }) =>
      leadService.searchCompanyIscraper(
        payload,
        `leads/search-company-linkedin${isUseMixrank ? "?isUseMixrank=true" : ""}`,
      ),
    onSuccess: (response, { payload, isUseMixrank }) => {
      updateState({
        currentSearchPayload: payload,
        searchLeadsResult: {
          profiles: response?.data.profiles || [],
          // @ts-ignore
          pagination: {
            ...response?.data.pagination,
            // @ts-ignore
            page: response?.data?.pagination?.current_page,
          } || {
            total_entries: 0,
            total_pages: 0,
            page: 1,
            per_page: 100,
          },
          apiPath: `leads/search-company-linkedin?per_page=${response?.data?.providerName === "iScraper" ? 20 : 25}&want_to_import=true&providerName=${response?.data?.providerName}${
            isUseMixrank ? "&isUseMixrank=true" : ""
          }`,
          columsMapping: response?.data?.columsMapping || [],
        },
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });
};
