import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Link,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import BgImg from "@/assets/images/agents-bg.png";
import AIAgentIcon from "@/assets/svgs/ai-agents.svg";
import { getCurrentDateInYYYYMM } from "@/lib/utils";

const AgentsModal = () => {
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "agents"}
      motionPreset="scale"
      size="3xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

      <ModalContent
        className="rounded-[20px] p-[24px]"
        style={{
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <ModalBody className="flex flex-col items-center">
          <img src={AIAgentIcon} alt="" className="my-[-30px]" />
          <p className="text-[32px] text-[#0F0D1C] font-medium">
            Persana AI Agents
          </p>
          <p className="text-[14px] text-[#7E889F] mb-3">
            Automate market research & personalize outreach with Persana's AI
            Agents
          </p>
          <Link
            href={`https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`}
            target="_blank"
          >
            <Button className="!bg-[#C03EDC] !text-white !text-[16px] !font-medium">
              Book a call to get access
            </Button>
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AgentsModal;
