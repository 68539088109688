import React, { useMemo } from "react";
import { usePresets } from "@/hooks/usePresets";
import PropTypes from "prop-types";

// Import TemplateTabs component and assets
import TemplateTabs from "./TemplateTabs";

import icGenerate from "@/assets/svgs/ic-generate.svg";
import icTemplate from "@/assets/svgs/ic-template.svg";
import ArrowBack from "@/assets/svgs/arrow-back.svg";
import { useNavigate } from "@tanstack/react-router";

// Define the types for the props
interface TemplateDetailsProps {
  templateId: string; // templateId is expected to be a string
}

const TemplateDetails: React.FC<TemplateDetailsProps> = ({ templateId }) => {
  const { data } = usePresets("persana-quantum");
  // const [filteredTemplate, setFilteredTemplate] = useState<Template | null>(
  //   null,
  // );
  const navigate = useNavigate();

  // 🔥 Use useMemo to prevent unnecessary re-computations
  const filteredTemplate = useMemo(() => {
    return data?.find((item) => item._id === templateId) || null;
  }, [data, templateId]);

  // useEffect(() => {
  //   if (data) {
  //     // Filter the data based on templateId and _id
  //     const selectedTemplate =
  //       data.find((item) => item._id === templateId) || null;
  //     setFilteredTemplate(selectedTemplate); // Set null if no template found
  //   }
  // }, [data, templateId]);
  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-wrap items-center justify-between p-8">
        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <button
              onClick={() => {
                navigate({
                  to: "/templates",
                });
              }}
            >
              <img
                src={ArrowBack}
                alt="arrow-back"
                className="brightness-0 contrast-100"
              />
            </button>
          </div>
          <img src={icGenerate} alt="generate" />
          <h2 className="text-xl font-bold text-black">
            {filteredTemplate?.name}
          </h2>
          <div className="flex items-center gap-1 rounded-lg bg-[#F5F3FF] p-1 px-2 text-[13px] font-medium text-[#5024DC]">
            <img src={icTemplate} alt="template" />
            Templates
          </div>
        </div>
      </div>

      {/* Render filtered template data */}
      {filteredTemplate ? (
        <TemplateTabs templateData={filteredTemplate} />
      ) : (
        <p>No template found with the given ID.</p>
      )}
    </div>
  );
};

// PropTypes validation (optional for runtime checking)
TemplateDetails.propTypes = {
  templateId: PropTypes.string.isRequired, // templateId should be a string
};

export default TemplateDetails;
