import { Checkbox } from "@chakra-ui/react";
import {
  IntegrationCategoies,
  IntegrationCategoryType,
} from "../Enrichment/constants";

const Sidebar = ({
  activeCategory,
  handleCategory,
}: {
  activeCategory: string;
  handleCategory: (categoryType: IntegrationCategoryType) => void;
}) => {
  return (
    <div className="enrichment-modal max-h-full w-[25%] border-r px-6 py-4">
      <div className="rounded-[6px] border pb-[8px]">
        <p className="w-full bg-gray px-4 pb-2 pt-4 text-[16px] font-medium text-[#0F0D1C]">
          Categories
        </p>
        <div className="flex max-h-[312px] flex-col overflow-y-auto px-4">
          {IntegrationCategoies.map((item) => (
            <div
              key={item}
              className={`flex w-full cursor-pointer items-center gap-[15px]  py-[12px] `}
              onClick={() => handleCategory(item)}
            >
              <Checkbox value={item} isChecked={item === activeCategory}>
                <p
                  className={`text-[14px] font-medium leading-[16px] ${
                    item === activeCategory
                      ? "font-[500] text-textPurple"
                      : "font-[400] text-black"
                  } `}
                >
                  {item}
                </p>
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
