import {
  Button,
  Card,
  CardBody,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { getParsedCsvData } from "@/utils";

const predefinedColumns = [
  {
    label: "First Name",
    value: "firstName",
  },
  {
    label: "Last Name",
    value: "lastName",
  },
  {
    label: "Work Email",
    value: "workEmail",
  },
  {
    label: "LinkedIn URL",
    value: "linkedInURL",
  },
  {
    label: "Company to track",
    value: "originalCompanyToTrack",
  },
  {
    label: "Hubspot ID",
    value: "hubspotId",
  },
];

const LeadCSVUpload = ({
  fetchPaginationData,
  onClose: onModelClose,
}: {
  fetchPaginationData: () => void;
  onClose?: () => void;
}) => {
  const toast = useToast();

  const [isOpen, _setIsOpen] = useState(false);
  const [uploadedColumns, setUploadedColumns] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>({
    firstName: "",
    lastName: "",
    workEmail: "",
    linkedInURL: "",
    originalCompanyToTrack: "",
    hubspotId: "",
  });
  const [selectedFile, setSelectedFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setIsOpen = (val: boolean) => {
    _setIsOpen(val);
    if (!val) {
      onModelClose?.();
    }
  };
  const handleFileChange = async (e: any) => {
    // read the CSV file parse using Papa parse, then return column names only
    const file = e.target.files[0];
    const columnNames = (await getParsedCsvData(file)) as any;
    setSelectedFile(file);

    if (columnNames.columns) {
      setIsOpen(true);
      setUploadedColumns(columnNames.columns?.filter(Boolean));
    }
  };

  const handleSubmit = async () => {
    /*
      Minimum valid Inputs
        Name, Company
        Name, Work email
        Name, linkedin
    */

    const name = `${selectedOptions.firstName} ${selectedOptions.lastName}`;
    if (!name.trim()) {
      // show error
      toast({
        position: "top-right",
        title: "Select Name field",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    if (
      !selectedOptions.workEmail &&
      !selectedOptions.linkedInURL &&
      !selectedOptions.originalCompanyToTrack
    ) {
      // show error
      toast({
        position: "top-right",
        title:
          "Select at least either of Work Email, LinkedIn URL or Company to track",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    selectedFile && formData.append("file", selectedFile);
    formData.append("firstName", selectedOptions.firstName);
    formData.append("lastName", selectedOptions.lastName);
    formData.append("workEmail", selectedOptions.workEmail);
    formData.append("linkedInURL", selectedOptions.linkedInURL);
    formData.append("hubspotId", selectedOptions.hubspotId);
    formData.append(
      "originalCompanyToTrack",
      selectedOptions.originalCompanyToTrack,
    );

    setIsSubmitting(true);
    const res = await accountMonitoringService.uploadLeads(formData);
    if (res.success) {
      toast({
        title: "Success",
        description: "File uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchPaginationData();
      setIsOpen(false);
    } else {
      toast({
        title: "Error",
        description: "There was an issue uploading the file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  const onClose = () => setIsOpen(false);

  return (
    <>
      <label className="m-0 flex h-[38px] cursor-pointer items-center justify-center gap-3 rounded-[6px] bg-primary-gradient p-3 font-[600] text-white text-[14px] hover:opacity-80">
        Upload CSV
        <input
          type="file"
          accept=".csv"
          className="hidden"
          onChange={handleFileChange}
        />
      </label>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalBody>
            <div>
              {predefinedColumns.map((column) => (
                <Card key={column.label} className="mb-2">
                  <CardBody>
                    <div className="flex items-center justify-between">
                      <Text className="flex-1">{column.label}</Text>
                      <div className="flex-1">
                        <Select
                          placeholder="Select Column"
                          onChange={(selectedItem) => {
                            setSelectedOptions((prevState: any) => ({
                              ...prevState,
                              [column.value]: selectedItem.target.value,
                            }));
                          }}
                          value={selectedOptions[column.value]}
                        >
                          {uploadedColumns.map((column) => (
                            <option value={column} key={column}>
                              {column}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
              <div className="mb-5 mt-3 flex w-full justify-center">
                <Button
                  colorScheme="purple"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LeadCSVUpload;
