import PropTypes from "prop-types";

interface TemplateCardProps {
  cardTitle: string;
  cardDesc: string;
  image?: string | undefined;
  groupTitle?: string;
  quantumTemplate: boolean | null;
  onClick: () => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  cardTitle,
  cardDesc,
  image,
  groupTitle,
  quantumTemplate,
  onClick,
}) => {
  return (
    <div
      onClick={onClick} // Trigger the parent-defined function
      className="flex cursor-pointer flex-col gap-0 rounded-xl border border-[#D0D4DC] bg-white p-4"
    >
      <div className="mt-1 flex items-center justify-between">
        <div className="flex items-center gap-2">
          {typeof image === "string" ? (
            <img src={image} alt={cardTitle} className="size-8" />
          ) : (
            <div className="size-8">{image}</div>
          )}
        </div>
        {quantumTemplate ? (
          <div className="inline-flex items-center justify-center rounded-full bg-[#F5F3FF] px-4 py-2">
            <p className=" bg-gradient-to-r from-[#8E32A4] to-[#5024DC] bg-clip-text text-xs font-medium text-transparent">
              Quantum AI
            </p>
          </div>
        ) : (
          <p className="inline-flex items-center justify-center rounded-full bg-[#F5F3FF] px-4 py-2 text-xs font-medium text-[#C03EDC]">
            {groupTitle}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <h4 className="text-base font-medium text-[#0F0D1C]">{cardTitle}</h4>
        <p className="text-[13px] text-[#7E889F]">{cardDesc}</p>
      </div>
    </div>
  );
};

// Define PropTypes
TemplateCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  cardDesc: PropTypes.string.isRequired,
  groupTitle: PropTypes.string,
  image: PropTypes.string,
  quantumTemplate: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default TemplateCard;
