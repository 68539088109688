import { Button, Divider } from "@chakra-ui/react";
import Icons from "../Icons";

import { useEffect, useState } from "react";
import AllEnrichments from "../Enrichment/AllEnrichments";
import {
  IntegrationCategoryType,
  Integrations,
  IntegrationsType,
} from "../Enrichment/constants";
import { useEnrichStore } from "@/stores/enrich.store";

const MainContent = ({
  activeCategory,
}: {
  activeCategory: IntegrationCategoryType;
}) => {
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationsType | null>();
  const [filteredIntegrations, setFilteredIntegrations] =
    useState<IntegrationsType[]>(Integrations);
  const searchInput = useEnrichStore((state) => state.searchInput);

  useEffect(() => {
    let filtered = Integrations;

    if (activeCategory !== "All") {
      filtered = filtered.filter((item) =>
        item.categories?.includes(activeCategory),
      );
    }

    if (searchInput.trim()) {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(searchInput.toLowerCase()),
      );
    }

    setFilteredIntegrations(filtered);
  }, [activeCategory, searchInput]);

  return (
    <div className="flex min-h-[636px] w-[75%]">
      <div className="w-full">
        {selectedIntegration && (
          <>
            <div className="flex items-center justify-between gap-4 px-[24px] pb-[10px] pt-[4px]">
              <Button
                size={"sm"}
                variant={"outline"}
                leftIcon={<Icons.BackIcon />}
                onClick={() => {
                  setSelectedIntegration(null);
                }}
              >
                Go Back
              </Button>
            </div>
            <Divider />
          </>
        )}
        {!selectedIntegration ? (
          <>
            <p className="px-[20px] pt-[20px] text-[16px] text-[#0F0D1C]">
              All integrations & APIs
            </p>
            <div className="custom-scrollbar grid max-h-[450px] grid-cols-2 items-center gap-4 overflow-auto p-[20px] pb-2">
              {filteredIntegrations?.map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => setSelectedIntegration(item)}
                  className="flex min-h-[210px] cursor-pointer flex-col justify-between rounded-lg border border-[#D0D4DC] bg-white shadow-md"
                >
                  <div className="flex grow flex-col gap-3 p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <img
                          src={item.icon}
                          alt={item.title}
                          className="h-auto w-6"
                        />
                        <p className="text-[16px] font-medium text-[#0F0D1C]">
                          {item.title}
                        </p>
                      </div>
                      <div className="rounded-[10px] bg-[#F5F3FF] px-[12px] py-[8px] text-[14px] font-medium text-[#5024DC]">
                        Integration
                      </div>
                    </div>
                    <div className="my-2">
                      <p className="mt-1 text-[13px] font-normal text-[#7E889F]">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className="border-gray-300 flex items-center justify-center rounded-b-lg border-t bg-[#F5F3FF] px-4 py-3">
                    <p className="text-gray-800 text-sm font-semibold">{`Explore ${item.title}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="px-[30px] py-[20px]">
              <div className="flex items-center gap-2">
                <img
                  src={selectedIntegration.icon}
                  alt={selectedIntegration.title}
                  className="h-auto w-6"
                />
                <p className="text-[16px] font-medium text-[#0F0D1C]">
                  {selectedIntegration.title}
                </p>
              </div>
              <div className="my-2">
                <p className="mt-1 text-[13px] font-normal text-[#7E889F]">
                  {selectedIntegration.description}
                </p>
              </div>
            </div>
            <Divider />
            <div className="custom-scrollbar max-h-[420px] overflow-y-auto pt-4">
              <div className="px-[24px] py-[18px]">
                <AllEnrichments
                  isForMarketplace
                  providerName={selectedIntegration.providerName}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MainContent;
