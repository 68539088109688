import LottiePlayer from "./LottiePlayer";

const ResultScreenLoader = () => {
  return (
    <div
      className="flex h-full w-full items-center justify-center"
      style={{ height: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-col items-center">
        <LottiePlayer
          src={
            "https://persana-assets.s3.us-west-1.amazonaws.com/searchPeople.lottie"
          }
          style={{ height: "auto", width: "300px" }}
        />
        <p className="font-title text-lg">
          Firing up 🔥 Persana's brain, Please Wait...
        </p>
      </div>
    </div>
  );
};

export default ResultScreenLoader;
