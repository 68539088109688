import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";

import BlueStar from "@/assets/svgs/star.svg";
import CompaniesIcon from "@/assets/svgs/companies.svg";
import PeopleIcon from "@/assets/svgs/people.svg";

import leadService from "@/services/leads.service";

import { useModalStore } from "@/stores/modal.store";
import { useTabStore } from "../PeopleSearch/store";
import useTypedText from "./useTypedText";

import { userStore } from "@/stores/user.store";
import { useCompanySearchStore } from "../CompanySearch/hooks/useCompanySearchStore";
import Icons from "../Icons";
const AIAssistant = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [inputValue, setInputValue] = useState("");
  const getFirstStaleTab = useTabStore(
    (state) => state.getOrCreateFirstStateTab,
  );
  const { setResponseValues } = useCompanySearchStore();

  const isUseMixRank = userStore((state) => state.isUseMixrank);
  const placeholders = [
    "Ask me anything...",
    "Find people who work at Google as a Software Engineer and lives in San Francisco",
    "Find healthcare companies in United States",
  ];

  const dynamicPlaceholder = useTypedText(placeholders);
  const updateState = useModalStore((state) => state.updateState);

  const { mutateAsync: searchPeopleUsingGPTMutateAsync, isPending } =
    useMutation({
      mutationFn: (input: string) =>
        leadService.searchPeopleUsingGPT({ prompt: input, isUseMixRank }),
      onSuccess: (response) => {
        // setInputValue("");
        if (response.type === "AGENT") {
          // onOpen();
          setInputValue("");
          updateState({
            isOpenModal: true,
            modalData: { modalType: "get-started" },
          });
          return;
        }

        if (response.type === "PEOPLE_SEARCH") {
          if (response.data?.peopleSearchPayload) {
            const tab = getFirstStaleTab();
            tab.store.setState({
              peopleSearchPayload: response.data?.peopleSearchPayload,
            });
            useTabStore.getState().activateTab(tab.id);
            navigate({
              to: `/search`,
              search: {
                type: "people",
              },
            });
          }
          return;
        }

        if (response.type === "COMPANY_SEARCH") {
          localStorage.removeItem("companySearchPopup");

          setResponseValues({
            ...response.data?.companySearchPayload,
            applyFilterData: true,
          });

          navigate({
            to: `/search`,
            search: {
              type: "companies",
              from: "ai",
            },
          });
        }

        if (!response?.data?.tableId) return;
        navigate({
          to: `/table/$tableId`,
          params: {
            tableId: response?.data?.tableId,
          },
        });
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <>
      <div className="relative mx-[24px] mb-[24px] overflow-hidden rounded-[12px] border border-[#D0D4DC] bg-white">
        <div className="px-[16px] py-[12px]">
          <p className="text-[20px] font-[600] text-[#0F0D1C]">
            Build your lead list with AI
          </p>
        </div>

        <div className="flex flex-col gap-[16px] px-[16px] pb-[12px] my-3">
          <div className="flex items-center gap-[12px]">
            <ButtonGroup className="w-full">
              <Button
                leftIcon={
                  <img src={PeopleIcon} alt="people" width={20} height={20} />
                }
                className="!flex !items-center !justify-start !text-[14px] rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC] !font-[500] flex-1"
                onClick={() => {
                  navigate({ to: "/search" });
                }}
                variant="secondary"
              >
                Find People
              </Button>
              <Button
                leftIcon={
                  <img
                    src={CompaniesIcon}
                    alt="companies"
                    width={20}
                    height={20}
                  />
                }
                className="!flex !items-center !justify-start !text-[14px] rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC] !font-[500] flex-1"
                onClick={() => {
                  navigate({ to: "/search", search: { type: "companies" } });
                }}
                variant="secondary"
              >
                Find Companies
              </Button>
              <Button
                leftIcon={<Icons.FileUploadIcon />}
                className="!flex !items-center !justify-start !text-[14px] rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5024DC] !font-[500] flex-1"
                onClick={() => {
                  updateState({
                    isOpenModal: true,
                    modalData: {
                      modalType: "import-csv",
                      metaData: { openPreviousModal: false },
                    },
                  });
                }}
                variant="secondary"
              >
                Upload CSV
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div
          style={{
            position: "relative",
            padding: "12px 16px",
            margin: "12px 16px",
            borderRadius: "8px",
            background:
              "linear-gradient(white, white) padding-box, linear-gradient(90deg, #8E32A4 0%, #5024DC 100%) border-box",
            border: "1px solid transparent",
          }}
          className="flex flex-col gap-[8px]"
        >
          <textarea
            onKeyDown={handleKeyDown}
            value={inputValue}
            onChange={handleChange}
            placeholder={dynamicPlaceholder}
            rows={2}
            className="!h-[50px] !w-full !resize-none !rounded-md !p-2 shadow-none"
          />

          <div className="flex items-center justify-between">
            <div className="bg-[#F5F3FF] px-[16px] py-[10px] rounded-[6px] flex items-center gap-2">
              <Icons.SettingsIcon className="text-[#5024DC]" />
              <p className="text-[14px] font-[500] text-[#373F51]">
                e.g. Find Software Engineers at Google in San Francisco
              </p>
            </div>
            <Button
              isLoading={isPending}
              onClick={() => searchPeopleUsingGPTMutateAsync(inputValue)}
              variant="outline"
              leftIcon={
                <img src={BlueStar} alt="star" width={20} height={20} />
              }
              className="flex w-fit cursor-pointer !rounded-[20px] !border !border-[#693DC7 !text-[#FFFFFF] !text-[14px] !font-[500]"
              isDisabled={!inputValue}
              style={{
                background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
              }}
            >
              Generate
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIAssistant;
