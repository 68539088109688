import {
  WalkThroughModalActionType,
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "@/components/Common/AutoPilot/WalkThroughModal";
import WalkThroughInfo from "@/components/Common/AutoPilot/WalkThroughModal/WalkThroughInfo";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import Icons from "@/components/Icons";
import { queryClient } from "@/lib/queryClient";
import leadService from "@/services/leads.service";
import tableService from "@/services/table.service";
import { TableData } from "@/types/table.types";
import {
  Button,
  Checkbox,
  HStack,
  Input,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface Props {
  totalSteps: number;
  action: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | undefined;
}

const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Biweekly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

function FineActiveHiringJobs({
  totalSteps,
  action,
  selectedTableData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [titleIds, setTitleIds] = useState("");
  const [locationId, setLocationId] = useState("");
  const [industryIds, setIndustryIds] = useState("");
  const [onsiteRemote, setOnsiteRemote] = useState("");
  const [jobType, setJobType] = useState("");
  const [salary, setSalary] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [isNotifyByEmail, setIsNotifyByEmail] = useState(false);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [isFindPeopleAtCompany, setIsFindPeopleAtCompany] = useState(false);
  const [findPeopleJobTitles, setFindPeopleJobTitles] = useState<Option[]>([]);
  const toast = useToast();

  const isConfigured = !!selectedTableData;

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runJobOpenings(
      {
        tableId: selectedTableData?._id || "",
      },
      "update-job-openings-table",
    );
    window.open(`/table/${selectedTableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runJobOpenings(
      {
        keywords,
        titleIds,
        industryIds,
        locationId,
        salary,
        jobType,
        onsiteRemote,
        experienceLevel,
        frequencyToUpdate: frequencyToUpdate?.value,
        isFindPeopleAtCompany,
        isNotifyByEmail,
        findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
      },
      "create-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      selectedTableData?._id || "",
      {
        metaData: {
          keywords,
          titleIds,
          industryIds,
          locationId,
          salary,
          jobType,
          onsiteRemote,
          experienceLevel,
          frequencyToUpdate: frequencyToUpdate?.value,
          isFindPeopleAtCompany,
          isNotifyByEmail,
          findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (keywords !== selectedTableData?.metaData?.keywords) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedTableData?._id) {
      setKeywords(selectedTableData?.metaData?.keywords || "");
      setTitleIds(selectedTableData?.metaData?.titleIds || "");
      setLocationId(selectedTableData?.metaData?.locationId || "");
      setIndustryIds(selectedTableData?.metaData?.industryIds || "");
      setOnsiteRemote(selectedTableData?.metaData?.onsiteRemote || "");
      setJobType(selectedTableData?.metaData?.jobType || "");
      setSalary(selectedTableData?.metaData?.salary || "");
      setExperienceLevel(selectedTableData?.metaData?.experienceLevel || "");
      setFrequencyToUpdate(
        frequencyList.find(
          (item) =>
            item.value === selectedTableData?.metaData?.frequencyToUpdate,
        ) || null,
      );
      setIsFindPeopleAtCompany(
        selectedTableData?.metaData?.isFindPeopleAtCompany,
      );
      setIsNotifyByEmail(selectedTableData?.metaData?.isNotifyByEmail);
      const savedJobTitles =
        selectedTableData?.metaData?.findPeopleJobTitles?.map(
          (item: string) => ({
            label: item,
            value: item,
          }),
        );
      setFindPeopleJobTitles(savedJobTitles || []);
    }
  }, [selectedTableData]);

  const otherPayloads = [
    {
      name: "Title IDs",
      state: titleIds,
      description: "it could be comma separated values as 64, 96 etc.",
      setState: (value: any) => {
        setTitleIds(value);
      },
    },
    {
      name: "Location ID",
      state: locationId,
      description: "",
      setState: (value: any) => {
        setLocationId(value);
      },
    },
    {
      name: "Industry IDs",
      state: industryIds,
      description: "it could be comma separated values as 94, 124 etc.",
      setState: (value: any) => {
        setIndustryIds(value);
      },
    },
    {
      name: "Onsite Remote",
      state: onsiteRemote,
      description: "it could be one of these; onSite, remote, hybrid",
      setState: (value: any) => {
        setOnsiteRemote(value);
      },
    },
    {
      name: "Job Type",
      state: jobType,
      description:
        "it could be one of these; fullTime, partTime, contract, internship",
      setState: (value: any) => {
        setJobType(value);
      },
    },
    {
      name: "Salary",
      state: salary,
      description:
        "it could be one of these; 40k+, 60k+, 80k+, 100k+, 120k+, 140k+, 160k+, 180k+, 200k+ ",
      setState: (value: any) => {
        setSalary(value);
      },
    },
    {
      name: "Experience Level",
      state: experienceLevel,
      description:
        "it could be one of these; internship, associate, director, entryLevel, midSeniorLevel. executive",
      setState: (value: any) => {
        setExperienceLevel(value);
      },
    },
  ];

  return (
    <>
      <WalkThroughModalBody>
        <WalkThroughInfo
          info="Actively Hiring Jobs"
          className="py-4 border-[#F0EFF1] border-b-[1.5px]"
        />
        <div className="h-full pt-0 pb-5 px-4">
          <VStack spacing={4} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
            <VStack spacing={2} align="stretch">
              <div className="flex items-center justify-between">
                <Text fontSize="md" fontWeight="semibold">
                  How frequently you want to find new jobs?
                </Text>

                <Checkbox
                  isChecked={isNotifyByEmail}
                  onChange={(e) => setIsNotifyByEmail(e.target.checked)}
                  size="md"
                  colorScheme="purple"
                >
                  Notify me via email
                </Checkbox>
              </div>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to find new jobs"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={1} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Keywords <span className="text-red-400">*</span>
              </Text>
              <Input
                placeholder="Enter keywords"
                size="md"
                variant="outline"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
              <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                it could be one of these; Software Engineer, Java Developer,
                Project Manager
              </p>
            </VStack>
            {otherPayloads?.map((item) => (
              <VStack spacing={1} align="stretch">
                <Text fontSize="md" fontWeight="semibold">
                  {item.name}
                </Text>
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.state}
                  onChange={(e) => item.setState(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                />
                <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                  {item.description}
                </p>
              </VStack>
            ))}
          </VStack>
          <HStack align={"center"} gap={4} className="!mt-3">
            <Text fontSize="md" fontWeight="semibold">
              Would you like to find people at company?
            </Text>
            <Switch
              isChecked={isFindPeopleAtCompany}
              size="md"
              onChange={(e) => setIsFindPeopleAtCompany(e.target.checked)}
              id={`source-switch`}
              className="text-[14px] flex font-medium"
              colorScheme="purple"
            />
          </HStack>
          {isFindPeopleAtCompany && (
            <div className="!mt-3">
              <p className="pb-1 text-base font-semibold">
                {"Add job titles to find people"}
              </p>
              <MultiSelectDropdown
                value={findPeopleJobTitles}
                options={[]}
                onChange={(selectedValues) =>
                  setFindPeopleJobTitles(selectedValues as Option[])
                }
                placeholder={"Add job titles to include"}
              />
            </div>
          )}
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={isLoading}
        onSave={async () => {
          action === "new"
            ? await handleAutoPilot()
            : await updatePreferences();
        }}
        totalSteps={totalSteps}
        action={action}
      />
    </>
  );
}

export default FineActiveHiringJobs;
