import Icons from "@/components/Icons";
import tableService from "@/services/table.service";
import teamService from "@/services/team.service";
import userService from "@/services/user.service";
import { useTableStore } from "@/stores/table.store";
import { userStore } from "@/stores/user.store";
import { Team } from "@/types/team.types";
import { setCookie } from "@/utils";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const SwitchWorkspace = () => {
  const [workspaces, setWorkspaces] = useState<Team[]>([]);
  const selectedWorkSpace = useTableStore((state) => state.selectedWorkSpace);
  const updateTableState = useTableStore((state) => state.updateState);
  const updateUserStore = userStore((state) => state.updateState);
  const cookies = new Cookies();

  const { mutateAsync: updateCredits } = useMutation({
    mutationFn: () => userService.getUserCreditDetails(),
    onSuccess: (response: any) => {
      updateUserStore({
        creditDetails: {
          creditLeft: response?.data?.creditLeft,
          creditUsed: response?.data?.creditUsed,
          planType: response?.data?.planType,
        },
      });
    },
  });

  const { mutateAsync: getWorkspaceTableLists } = useMutation({
    mutationFn: () => tableService.getTableList(),
  });

  const handleWorkspaceChange = async (workspace: Team | null) => {
    setCookie("workspaceId", workspace?._id || "");
    updateTableState({
      selectedWorkSpace: workspace,
    });
    await getWorkspaceTableLists();
    await updateCredits();
    window.location.reload();
  };

  useEffect(() => {
    mutateAsync();
  }, []);

  const { mutateAsync } = useMutation({
    mutationFn: () => teamService.getWorkspaces(),
    onSuccess: (response) => {
      const workspaceId = cookies.get("workspaceId");

      if (workspaceId && Array.isArray(response.data?.sharedWorkspaces)) {
        const selectedWorkspace = response.data.sharedWorkspaces.find(
          (workspace: Team) => workspace._id === workspaceId,
        );
        updateTableState({
          selectedWorkSpace: selectedWorkspace,
        });
      }

      setWorkspaces(response?.data?.sharedWorkspaces || []);
    },
  });

  return (
    <Flex minH="50px" justify="flex-end" align="center" zIndex={1000}>
      {workspaces?.length > 0 && (
        <Menu autoSelect={false}>
          <MenuButton
            className="!w-fit !ml-auto"
            as={Button}
            variant={"outline"}
            rightIcon={<Icons.ChevronDownIcon />}
            style={{
              fontSize: "15px",
              borderRadius: "6px",
            }}
          >
            Switch Team Workspace
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleWorkspaceChange(null);
              }}
              className={!selectedWorkSpace ? "!bg-bgSecondary" : ""}
            >
              Personal workspace
            </MenuItem>
            {workspaces?.map((workspace, index) => (
              <MenuItem
                key={`workspaces-${index}`}
                onClick={() => {
                  handleWorkspaceChange(workspace);
                }}
                className={
                  workspace._id === selectedWorkSpace?._id
                    ? "!bg-bgSecondary"
                    : ""
                }
              >
                <span className="capitalize">{workspace.name}</span>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export default SwitchWorkspace;
