import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";

import { useWalkThroughModalStore } from "./WalkThroughModal";
import { TableData } from "@/types/table.types";
import Icons from "@/components/Icons";
import TableIcon from "@/assets/svgs/tableIcon2.svg";
import { SidebarRowMenu } from "./SidebarRowMenu";
import { Input, Spinner } from "@chakra-ui/react";

interface Props {
  selectedTable: Partial<TableData> | undefined;
  setSelectedTable: (table: Partial<TableData>) => void;
  autoPilotTables: Partial<TableData>[] | undefined;
  title: string;
  flowName: string;
  isLoading?: boolean;
}

function AutoPilotSidebar({
  selectedTable,
  setSelectedTable,
  autoPilotTables,
  title,
  flowName,
  isLoading = false,
}: Props) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTables, setFilteredTables] = useState<Partial<TableData>[]>(
    autoPilotTables || [],
  );
  const [isRefetchingTables, setIsRefetchingTables] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const { updateModalState } = useWalkThroughModalStore();

  useEffect(() => {
    if (autoPilotTables) {
      setFilteredTables(
        autoPilotTables.filter((table) =>
          table.name?.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );
    }
  }, [searchQuery, autoPilotTables]);

  const handleEditWorkflow = (selectedTable: Partial<TableData>) => {
    updateModalState({
      isOpen: true,
      title: title,
      flowName: flowName,
      action: "update",
      selectedTableData: selectedTable,
    });
  };

  const handleCloneCallback = (newTable: Partial<TableData>) => {
    setIsCloning(true);
    handleEditWorkflow(newTable);

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (autoPilotTables && autoPilotTables.length) {
      if (!isCloning) {
        const tableId = selectedTable?._id || autoPilotTables[0]?._id;
        setSelectedTable(
          autoPilotTables.find((item) => item._id === tableId) || {},
        );
      } else {
        const tableId = autoPilotTables[0]?._id;
        setSelectedTable(
          autoPilotTables.find((item) => item._id === tableId) || {},
        );
        setIsCloning(false);
      }
    }
  }, [autoPilotTables, isCloning]);

  return (
    <div className="flex h-full flex-col border-r border-[#D0D4DC] font-poppins">
      <div className="border-b border-[#F0EFF1] px-4 py-6">
        <h3 className="text-[1.3rem] font-semibold">Autopilot</h3>
        <p className="text-sm">{title}</p>
      </div>
      <div className="space-y-3 border-b border-[#F0EFF1] px-4 py-6">
        <button
          onClick={() => {
            updateModalState({
              isOpen: true,
              title: title,
              flowName: flowName,
              action: "new",
            });
          }}
          className="flex w-full items-center space-x-2.5 rounded-lg border border-[#DDD8FF] bg-[#FDFCFF] px-4 py-2 text-sm font-medium"
        >
          <span className="relative block size-4 shrink-0 rounded-full bg-[#F5F3FF] before:absolute before:top-[calc(50%_-_0.6px)] before:h-[1.6px] before:w-full before:-translate-x-1/2 before:bg-[#090909] before:content-[''] after:absolute after:left-1/2 after:h-full after:w-[1.6px] after:-translate-x-1/2 after:bg-[#090909] after:content-['']"></span>
          <span>Create New Workflow</span>
        </button>
        {selectedTable?._id && (
          <button
            className="flex w-full items-center space-x-2.5 rounded-lg border border-[#DDD8FF] bg-[#FDFCFF] px-4 py-2 text-sm font-medium"
            onClick={() => handleEditWorkflow(selectedTable)}
            disabled={!selectedTable?._id}
          >
            <Icons.SettingsIcon className="h-auto w-4" />
            <span>Edit this workflow</span>
          </button>
        )}
      </div>
      <div className="grow">
        <div className="flex items-center justify-between px-4 py-2">
          <h3 className="text-base font-medium">Your Tables</h3>
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="sm"
            placeholder="Search tables..."
            className="!rounded-md !w-[200px]"
          />
        </div>
        <div
          ref={scrollContainerRef}
          className="min-h-[35vh] border-b border-[#F0EFF1] overflow-y-auto max-h-[calc(100vh_-_20rem)] overflow-auto custom-scrollbar"
        >
          {isLoading || isRefetchingTables ? (
            <div className="flex items-center justify-center mt-4">
              <Spinner />
            </div>
          ) : (
            filteredTables.map((table, index) => {
              const formattedDate = format(
                new Date(table?.updatedAt || ""),
                "EE, MMM d, yy",
              );

              return (
                <div
                  key={`${table?._id}-${index}`}
                  className={`flex cursor-pointer items-center space-x-4 border-b border-[#F0EFF1] p-4 duration-200 hover:bg-[#F5F3FF] ${
                    selectedTable?._id === table?._id ? "bg-[#F5F3FF]" : ""
                  }`}
                  onClick={() => setSelectedTable(table)}
                >
                  <img
                    src={TableIcon}
                    alt="Table Icon"
                    className="h-auto w-5"
                  />
                  <div className="w-full overflow-hidden">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-base font-medium">
                        {table?.name}
                      </p>
                      <SidebarRowMenu
                        aria-label="Table Row Menu"
                        variant="ghost"
                        tableId={table?._id}
                        className="shrink-0"
                        handleCloneCallback={handleCloneCallback}
                        setIsRefetchingTables={setIsRefetchingTables}
                      />
                    </div>
                    <p className="text-sm">
                      Last updated:{" "}
                      <span className="text-[#7E889F]">{formattedDate}</span>
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default AutoPilotSidebar;
