import React, { useEffect, useState } from "react";
import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import EmailTab from "./EmailTab";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import ConditionalFormula from "../../Common/ConditionalFormula";

import type { TSelectedColumnOption } from "@/types/common.types";
import { REGEX } from "../../constants";
import { EnrichmentMetaData } from "@/types/table.types";

type TabName = "Find with Email" | "Use Dynamic Fields";

const tabs: {
  name: TabName;
  value: string;
  component: (props: {
    selectedOption: TSelectedColumnOption | null;
    setSelectedOption: React.Dispatch<
      React.SetStateAction<TSelectedColumnOption | null>
    >;
    dynamicText: string;
    setDynamicText: React.Dispatch<React.SetStateAction<string>>;
  }) => JSX.Element;
}[] = [
  {
    name: "Find with Email",
    value: "email",
    component: (props) => <EmailTab {...props} />,
  },
  //   {
  //     name: "Use Dynamic Fields",
  //     value: "dynamic",
  //     component: (props) => <DynamicFieldsTab {...props} />,
  //   },
];

interface EnrichmentDataType extends EnrichmentMetaData {
  type: "email" | "dynamic";
  dynamicText?: string;
}

const FindLinkedInDataWithWorkEmail = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [dynamicText, setDynamicText] = useState("");
  const [selectedTab, setSelectedTab] = useState<"email" | "dynamic">("email");

  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const isDisabled =
    (selectedTab === "email" && !selectedOption?.keyId) ||
    (selectedTab === "dynamic" && !dynamicText);

  useEffect(() => {
    if (selectedTab === "email" && viewMode !== "edit") {
      const initialColumn = getInitialColumn(REGEX.EMAIL);
      if (initialColumn) {
        setSelectedOption({
          key: initialColumn.name,
          keyId: initialColumn._id,
          iconType: initialColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns, selectedTab]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        setSelectedTab(enrichmentData.type);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        if (enrichmentData.type === "email") {
          preselectEnrichmentState(enrichmentData, setSelectedOption);
        } else {
          setDynamicText(enrichmentData.dynamicText || "");
        }
      }
    }
  }, [selectedColumnToEdit]);

  return (
    <div className="overflow-y-auto bg-white">
      <div className="flex flex-col gap-1.5 px-4 py-3.5">
        <h5 className="text-sm text-[#0F0D1C]">How to use this recipe</h5>
        <p className="text-sm text-[#7E889F]">
          Start with just a work email. Then we'll find the Linkedin url using
          work email{"->"} and finally find the person details.
        </p>
      </div>
      <div className="bg-[#F5F3FF] px-4 py-3 ">
        <h4 className="mb-2 text-sm font-medium text-[#0F0D1C]">
          Recipe Enrichments
        </h4>
        <WatchTutorial
          subText={"Find a person's LinkedIn Url via work email"}
          link={""}
          credits={1}
        />
        <WatchTutorial
          subText={"Enrich Person Details using Linkedin url"}
          link={""}
          credits={1}
        />
      </div>
      <div className="grow p-4">
        <Tabs>
          {/* <TabList className="border-b-2 border-gray">
            {tabs.map((tab, index) => (
              <Tab
                fontSize={"base"}
                fontWeight={"bold"}
                key={index}
                onClick={() => {
                  setSelectedTab(tab.value as "email" | "dynamic");
                }}
                className="flex-1"
              >
                <span
                  className={
                    tab.value === selectedTab ? "text-blue" : "text-dark"
                  }
                >
                  {tab.name}
                </span>
              </Tab>
            ))}
          </TabList> */}

          <TabPanels>
            {tabs.map((tab, index) => {
              const Component = tab.component;
              return (
                <TabPanel key={index}>
                  {
                    <Component
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      dynamicText={dynamicText}
                      setDynamicText={setDynamicText}
                    />
                  }
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={isDisabled}
        apiPayload={{
          columnId: selectedOption?.keyId,
          dynamicText,
          type: selectedTab,
        }}
      />
    </div>
  );
};

export default FindLinkedInDataWithWorkEmail;
