import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { useEffect, useMemo, useState } from "react";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { addNewColumn } from "@/utils";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { queryClient } from "@/lib/queryClient";
import { colorList } from "@/lib/utils";

const SelectColumnOptionsModal = () => {
  const toast = useToast();
  const tableId = useTableStore((state) => state.tableData._id);
  const [selectColOptions, setSelectColOptions] = useState<Option[]>([]);
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const { mutateAsync: updateColumnNameAsync, isPending } = useMutation({
    mutationFn: ({
      columnId,
      options,
    }: {
      columnId: string;
      options: Option[];
    }) =>
      tableService.updateColumnData(tableId, columnId, {
        metaData: { selectColumnOptions: options },
      }),
  });

  const existingColumnOptions = useMemo(() => {
    if (modalData.metaData) {
      return modalData.metaData?.metaData?.selectColumnOptions;
    } else {
      return [];
    }
  }, [modalData]);

  const closeModal = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const updateOptions = async () => {
    await updateColumnNameAsync(
      {
        columnId: modalData?.metaData._id,
        options: selectColOptions,
      },
      {
        onSuccess: (response) => {
          if (response.success == false) {
            toast({
              title: "Something went wrong",
              description: response.error.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            return;
          }
          closeModal();
          const updatedColumns = response.data?.columns;

          if (!updatedColumns || !updatedColumns.length) return;

          queryClient.refetchQueries({
            queryKey: ["table", tableId],
          });

          toast({
            title: `Options updated successfully`,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
        },
        onError: (error) => {
          toast({
            title: "Something went wrong",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        },
      },
    );
  };

  const addOptions = async () => {
    closeModal();
    await addNewColumn({
      columnName: "New Select Column",
      colType: "enumSingle",
      options: selectColOptions,
    });
  };

  useEffect(() => {
    if (existingColumnOptions?.length) {
      setSelectColOptions(existingColumnOptions);
    }
  }, [existingColumnOptions]);

  return (
    <Modal
      isCentered
      onClose={() => closeModal()}
      isOpen={isOpenModal && modalData.modalType === "select-column-options"}
      size={"2xl"}
      scrollBehavior="outside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader>
          {existingColumnOptions?.length
            ? `Update Options for ${modalData?.metaData?.name} Column`
            : "Add Options for Select Column"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MultiSelectDropdown
            placeholder="Options"
            options={[]}
            onChange={(selected) => {
              // @ts-ignore
              const selectedWithColors = selected?.map((item, index) => ({
                ...item,
                _color:
                  colorList[
                    index > 49 ? Math.floor(Math.random() * 50) : index
                  ],
              }));
              setSelectColOptions(selectedWithColors as Option[]);
            }}
            isMulti
            value={selectColOptions}
            isCreateAble={true}
          />
          <Button
            isLoading={isPending}
            className="mt-2"
            variant={"outline"}
            size={"sm"}
            isDisabled={!selectColOptions?.length}
            onClick={() => {
              existingColumnOptions?.length ? updateOptions() : addOptions();
            }}
          >
            {existingColumnOptions?.length
              ? "Update options"
              : "Add column with options"}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectColumnOptionsModal;
