import { Button } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";

import Icons from "@/components/Icons";
import { TableListComponent } from "../TableList";
import { useModalStore } from "@/stores/modal.store";

import {
  createTableStore,
  tableListContext,
} from "../TableList/hooks/useTableState.store";
import { useStore } from "zustand";
import AIAssistant from "@/components/AIAssistant";

const StarterSection = () => {
  const navigate = useNavigate();
  const tableListStore = useMemo(() => createTableStore(), []);
  const { updateState } = useModalStore((state) => state);
  const selectedTable = useStore(
    tableListStore,
    (state) => state.selectedTable,
  );

  useEffect(() => {
    if (!selectedTable || !selectedTable.tableItem) return;
    const { tableItem, shift } = selectedTable;
    if (shift) {
      window.open(`/table/${tableItem._id}`, "_blank");
      return;
    }

    navigate({
      to: "/table/$tableId",
      params: {
        tableId: tableItem._id,
      },
    });
  }, [selectedTable]);

  return (
    <div className="h-fit w-full bg-white">
      <div className="mx-[24px] flex items-center justify-between">
        <div className="flex items-center justify-start gap-[16px] py-[16px]">
          <Button
            rightIcon={<Icons.RightArrowIcon />}
            className="!bg-[#C03EDC] !text-[#fff]"
            onClick={() =>
              updateState({
                isOpenModal: true,
                modalData: { modalType: "get-started" },
              })
            }
          >
            Get Started
          </Button>
        </div>
      </div>
      <AIAssistant />

      {/* Table section */}
      <div className="mx-[24px] h-fit rounded-[12px] border border-[#D0D4DC] pb-[0.4em]">
        <div className="overflow-hidden py-2">
          {/* <div className="mb-4 flex items-center justify-between">
            <InputGroup width="100%" className="mr-1">
              <InputLeftElement>
                <Icons.SearchIcon size={20} color="gray" />
              </InputLeftElement>
              <Input
                style={{ borderRadius: "20px" }}
                placeholder="Search tables..."
                size="md"
                variant="outline"
                value={searchValue}
                onChange={(event) => {
                  setSearchValue({ search: event?.target.value ?? "" });
                }}
              />
            </InputGroup>
          </div> */}
          <tableListContext.Provider value={tableListStore}>
            <TableListComponent tableListStore={tableListStore} />
          </tableListContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default StarterSection;
