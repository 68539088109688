import AutoPilotLandingPage from "@/pages/AutoPilotLandingPage";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/").createRoute({
  component: AutoPilotPage,
  errorComponent: () => <div>Error</div>,
});

function AutoPilotPage() {
  return <AutoPilotLandingPage />;
}

export default AutoPilotPage;
