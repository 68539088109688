import { ReactNode, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  ExpandedIndex,
} from "@chakra-ui/react";

type SearchInputAccordionProps = {
  button: ReactNode;
  children: ReactNode;
  btnStyle?: React.CSSProperties;
  defaultIndex?: number;
  props?: AccordionProps;
  showBottomBorder?: boolean;
  numOfAppliedFilters?: number;
  isForAISDR?: boolean;
};

const SearchInputAccordion = ({
  button,
  children,
  btnStyle = {},
  defaultIndex = 1,
  props,
  showBottomBorder,
  numOfAppliedFilters = 0,
  isForAISDR = false,
}: SearchInputAccordionProps) => {
  const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>(
    numOfAppliedFilters > 0
      ? [0]
      : defaultIndex !== undefined
        ? [defaultIndex]
        : [],
  );

  useEffect(() => {
    if (numOfAppliedFilters > 0) {
      setAccordionIndex([0]);
    }
  }, [numOfAppliedFilters]);

  return (
    <Accordion
      index={accordionIndex}
      onChange={(expandedIndex) => setAccordionIndex(expandedIndex)}
      allowMultiple
      sx={{
        transition: "all 0.3s ease",
        overflow: "visible",
        border: isForAISDR && "1px solid #D0D4DC",
        borderRadius: isForAISDR && "6px",
        marginBottom: isForAISDR && "10px",
        borderTop: !isForAISDR && "1px solid #D0D4DC",
        ...(showBottomBorder && { borderBottom: "1px solid #D0D4DC" }),
      }}
      {...props}
    >
      <AccordionItem
        rounded={"md"}
        sx={{
          transition: "all 0.3s ease",
          border: "none",
        }}
      >
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                sx={{
                  padding: "12px 16px",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  borderRadius: isForAISDR && "6px 6px 0 0",
                  ...btnStyle,
                  ...(isExpanded && {
                    backgroundColor: isForAISDR ? "#fff" : "#F5F3FF",
                  }),
                }}
              >
                {button}
                {numOfAppliedFilters > 0 ? (
                  <span className="mr-1 rounded bg-[rgb(222,_233,_255)] px-[4px] py-[3px] text-xs leading-none text-[rgb(7,_113,_197)]">
                    {numOfAppliedFilters} Applied
                  </span>
                ) : null}
                <AccordionIcon color={"#693DC7"} className={"ml-auto"} />
              </AccordionButton>
            </h3>
            <AccordionPanel
              borderRadius={isForAISDR ? "6px" : 0}
              pb={4}
              fontFamily={"Inter"}
              overflow={"visible"}
              backgroundColor={isForAISDR ? "#fff" : "#F5F3FF"}
            >
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default SearchInputAccordion;
