import { useMemo } from "react";

import { useTableStore } from "@/stores/table.store";
import { useTableInfo } from "@/hooks/useTableData";
import Icons from "@/components/Icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";

const LinkedTables = () => {
  const menuDisclosure = useDisclosure();
  const tableData = useTableStore((state) => state.tableData);
  const linkedColumnIds = useMemo<string[]>(() => {
    return (
      tableData.columns
        ?.map(
          (column) => column?.metaData?.enrichmentMetaData?.dependentTableId,
        )
        .filter(Boolean) || []
    );
  }, [tableData]);
  const { data: tableInfos } = useTableInfo(linkedColumnIds);

  const tables = tableInfos
    ? Array.isArray(tableInfos.data)
      ? tableInfos.data
      : [tableInfos.data]
    : [];
  if (tables.length === 0) {
    return null;
  }
  const buttonTitle = `Linked Table${tables.length > 1 ? "s" : ""}`;

  const handleLinkedTable = (linkedTable: string) => {
    window.open(`/table/${linkedTable}?last_page=true`, "_blank");
  };
  return (
    <div className="flex cursor-pointer items-center gap-1">
      {tables.length === 1 ? (
        <div
          onClick={() =>
            tables.length === 1
              ? handleLinkedTable(tables[0]._id)
              : menuDisclosure.onToggle()
          }
        >
          <Button
            leftIcon={
              <Icons.ExternalLinkIcon className="h-auto w-4 text-[#000000]" />
            }
            size="sm"
            colorScheme="purple"
            onClick={() => handleLinkedTable(tables[0]._id)}
          >
            {buttonTitle}
          </Button>
        </div>
      ) : (
        <Menu
          onOpen={menuDisclosure.onOpen}
          onClose={menuDisclosure.onClose}
          isOpen={menuDisclosure.isOpen}
        >
          <MenuButton>
            <Button
              leftIcon={<Icons.ExternalLinkIcon className="h-auto w-4" />}
              rightIcon={<Icons.ChevronDownIcon className="h-auto w-4" />}
              size="sm"
              colorScheme="purple"
              onClick={() => handleLinkedTable(tables[0]._id)}
            >
              {buttonTitle}
            </Button>
          </MenuButton>
          <MenuList>
            {tables.map((table) => (
              <MenuItem
                onClick={() => handleLinkedTable(table._id)}
                icon={<Icons.ViewTableIcon className="h-auto w-4" />}
                key={table._id}
              >
                {table.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </div>
  );
};

export default LinkedTables;
