import { Button } from "@chakra-ui/react";

// import Icons from "@/components/Icons";
import { generateId } from "@/utils";

interface Props {
  isLoading: boolean;
  selectedTab: string;
  setSelectedTab: (val: string) => void;
  onSearch: () => void;
}

const ActionButtons = ({ isLoading, selectedTab, onSearch }: Props) => {
  // const searchOptions = [
  //   {
  //     name: "Save and search",
  //     icon: <Icons.SaveIcon className="text-xl" />,
  //   },
  //   {
  //     name: "Search",
  //     icon: <Icons.OutlineSearchIcon className="text-xl" />,
  //   },
  // ];

  return (
    <>
      {/* TODO: Add save as template feature */}
      {/* <div className="grid grid-cols-2 gap-3.5 p-4 !font-title">
        <Menu>
          <MenuButton
            as={Button}
            isLoading={isLoading}
            id={generateId("persana-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[4px] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide ${selectedTab === "search"
                ? "!bg-[#1e86ff] !text-white"
                : "!bg-[#fff] !text-[#693DC7]"
              }`}
            onClick={() => {
              setSelectedTab("search");
            }}
          >
            Search
          </MenuButton>
          <MenuList className="!text-sm !font-semibold !tracking-wide">
            {searchOptions.map((option, index, arr) => (
              <>
                <MenuItem
                  key={option.name}
                  className="flex items-center"
                  onClick={() => {
                    onSearch(option.name);
                  }}
                >
                  <span className="block w-8">{option.icon}</span>{" "}
                  <span>{option.name}</span>
                </MenuItem>
                {index !== arr.length - 1 && <MenuDivider />}
              </>
            ))}
          </MenuList>
        </Menu>
        <button
          className={`rounded-[5px] bg-[#1e86ff] px-1 py-[7px] !font-title !text-sm ${selectedTab === "savedTemplates"
              ? "bg-[#1e86ff] text-white"
              : "!bg-white !text-textPurple"
            }`}
          onClick={() => {
            setSelectedTab("savedTemplates");
          }}
        >
          <span className="font-semibold">Saved Templates</span>
        </button>
      </div> */}

      <div className="p-4 !font-title">
        <Button
          isLoading={isLoading}
          id={generateId("persana-search-button")}
          loadingText="Searching"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={`!w-full !rounded-[4px] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide ${
            selectedTab === "search"
              ? "!bg-[#1e86ff] !text-white"
              : "!bg-[#fff] !text-[#693DC7]"
          }`}
          onClick={() => {
            onSearch();
          }}
        >
          Search
        </Button>
      </div>
    </>
  );
};

export default ActionButtons;
