import { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { usePresets } from "@/hooks/usePresets";
import Podcast from "@/assets/svgs/10-podcast-appearance-image.svg";

import TemplateCard from "./TemplateCard";
import TemplateSidebar from "./TemplateSidebar";
import { allTemplates as initialTemplates, TemplateGroups } from "./constants";

// Assets & Images
import backArrowLight from "@/assets/svgs/back-arrow-light.svg";

interface Group {
  name?: string;
  icon?: React.ElementType; // For any component type
  title?: string;
}
interface Template {
  templateId: string;
  heading: string;
  title: string;
  group: Group;
  image?: string;
  isInternalRedirection: boolean;
  redirectionLink?: string;
  isPlaybookTemplate?: boolean;
  prompt?: string;
  [key: string]: unknown; // To allow additional dynamic properties
}

const WorkflowTemplates = () => {
  const navigate = useNavigate(); // Hook for navigation
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const { data: presetData } = usePresets("persana-quantum");
  const [templates, setTemplates] = useState<Template[]>(initialTemplates);
  const [quantumTemplates, setQuantumTemplates] = useState<Template[]>([]);

  // Dynamically add Quantum templates from preset data
  useEffect(() => {
    if (presetData && Array.isArray(presetData)) {
      const quantumGroup: Group = {
        name: "Quantum",
        icon: TemplateGroups.SAAS_MARKET_INSIGHTS.icon,
      };

      const quantumTemplates: Template[] = presetData.map((item) => ({
        templateId: item._id || "unknown",
        heading: item.name || "Untitled",
        title: item.description || "No description available",
        url: "", // Provide a default or dynamic URL
        image: Podcast, // Provide a default or dynamic image path
        group: quantumGroup,
        isInternalRedirection: false,
      }));

      setQuantumTemplates(quantumTemplates);

      setTemplates((prevTemplates) => [...prevTemplates, ...quantumTemplates]);
    }
  }, [presetData]);

  // Handle filtering logic (by search query and selected groups)
  const handleFilterChange = (query: string, selectedGroups: Group[]) => {
    setSearchValue(query);
    setSelectedGroups(selectedGroups);

    let filteredTemplates: Template[] = [
      ...initialTemplates,
      ...quantumTemplates, // Add quantumTemplates from the state
    ];

    // If groups are selected, filter by them (excluding "All")
    if (
      selectedGroups.length > 0 &&
      !selectedGroups.some((g) => g.name === "All")
    ) {
      filteredTemplates = filteredTemplates.filter((item) =>
        selectedGroups.some((group) => group.name === item.group.name),
      );
    }

    // Filter by search query
    if (query) {
      filteredTemplates = filteredTemplates.filter(
        (item) =>
          item.heading.toLowerCase().includes(query.toLowerCase()) ||
          item.title.toLowerCase().includes(query.toLowerCase()),
      );
    }

    setTemplates(filteredTemplates);
  };

  // Handle changes to search query
  const handleSearchChange = (query: string) => {
    handleFilterChange(query, selectedGroups);
  };

  const handleCategoryChange = (categoryId: string) => {
    if (categoryId === "All") {
      // Toggle the "All" selection on or off
      const isAllSelected = selectedGroups.some(
        (group) => group.name === "All",
      );

      const updatedGroups = isAllSelected
        ? selectedGroups.filter((group) => group.name !== "All") // Remove "All"
        : [
            { name: "All" },
            ...Object.values(TemplateGroups),
            { name: "Quantum" },
          ]; // Add "All" and all groups

      setSelectedGroups(updatedGroups);
      handleFilterChange(searchValue, updatedGroups);
    } else {
      // Handle individual group selection
      const selectedGroup =
        categoryId === "Quantum"
          ? { name: "Quantum" }
          : Object.values(TemplateGroups).find(
              (group) => group.name === categoryId,
            );

      if (!selectedGroup) return;

      // Update selected groups
      setSelectedGroups((prevGroups) => {
        const isSelected = prevGroups.some(
          (group) => group.name === selectedGroup.name,
        );
        const updatedGroups = isSelected
          ? prevGroups.filter((group) => group.name !== selectedGroup.name) // Remove if already selected
          : [
              ...prevGroups.filter((group) => group.name !== "All"), // Remove "All" if present
              selectedGroup,
            ]; // Add new group

        handleFilterChange(searchValue, updatedGroups);
        return updatedGroups;
      });
    }
  };
  return (
    <>
      <div className="h-screen overflow-hidden bg-[#F5F3FF]">
        <div className="flex flex-col gap-3 bg-[#F5F3FF] p-4">
          <h4 className="text-xl font-semibold text-black">Templates</h4>
          <p className="text-sm text-[#0F0D1C]">
            Leverage Persana’s recipes to skip the hassle of creating from
            scratch.
          </p>
        </div>
        <div className="flex h-screen bg-white">
          <TemplateSidebar
            checkboxList={[
              { id: "All", item: "All" },
              ...Object.values(TemplateGroups).map((group) => ({
                id: group.name,
                item: group.name,
              })),
              { id: "Quantum", item: "Quantum" },
            ]}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            onCategoryChange={handleCategoryChange}
            selectedGroups={selectedGroups.map((group) => ({
              ...group,
              name: group.name ?? "Default Name", // Default value if name is undefined
            }))}
          />
          <div className="h-screen flex-1 overflow-y-auto p-4 px-6">
            <div className="flex items-center justify-between">
              <h4 className="mb-4 text-base font-medium text-[#0F0D1C]">
                All templates
              </h4>
              <a href="/" className="ml-auto flex items-center gap-3">
                <img alt="back" src={backArrowLight} />
                <h5 className="text-sm font-medium text-[#D0D4DC]">Go back</h5>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {templates.map((data) => (
                <TemplateCard
                  key={data.templateId}
                  cardTitle={data.heading}
                  cardDesc={data.title}
                  groupTitle={data?.group?.title}
                  image={data?.image}
                  quantumTemplate={!data.isPlaybookTemplate}
                  onClick={() => {
                    const redirectTo = data.isInternalRedirection
                      ? data.redirectionLink || "/"
                      : data.isPlaybookTemplate
                        ? `/playbooks/${data.heading}`
                        : `/templates/${data.templateId}`;

                    // Assuming the route requires `templateId` as a parameter
                    const params = { templateId: data.templateId };

                    navigate({
                      to: redirectTo,
                      params, // Pass params if needed
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkflowTemplates;
