import { PricingType } from "@/types/pricing.types";
import { Button, Link } from "@chakra-ui/react";
import AiPricing from "@/assets/images/ai-pricing.png";
import { getCurrentDateInYYYYMM } from "@/lib/utils";

interface Props {
  pricing: PricingType;
}

export interface SelectedPriceType {
  price: number;
  credits: number;
  priceId: string;
}

const AIPriceBox = ({ pricing }: Props) => {
  return (
    <div
      className={`flex h-full flex-col rounded-[14px] bg-white !font-title`}
      style={{
        borderTop: "4px solid #5024DC",
        boxShadow: "-0.5px 0.5px 2px #C03EDC, 0.5px -0.5px 2px #5024DC",
        borderRadius: "8px",
      }}
    >
      <div className="">
        <div
          className={`relative rounded-t-[4px] ${
            pricing?.mostPopular ? "gradientBg text-white" : "bg-[#F5F3FF]"
          } p-3.5`}
        >
          {pricing.mostPopular && (
            <div className="absolute top-0 right-0 w-full h-full overflow-hidden">
              <div className="rotate-45 bg-purple text-white text-xs font-bold py-1.5 px-5 text-center absolute top-[8px] right-[-15px]">
                Popular
              </div>
            </div>
          )}
          <h2 className="text-[1rem] font-semibold">{pricing.title}</h2>
        </div>
        <div className="space-y-3.5 text-2xl font-medium">
          <img src={AiPricing} width={"100%"} />
          <div className="!px-3.5 flex flex-col gap-2">
            <Link
              style={{ textDecoration: "none" }}
              href={`https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`}
              target="_blank"
            >
              <Button
                variant={"unstyled"}
                className="!font-title !text-base !leading-none !tracking-wide !mt-3"
                sx={{
                  width: "100%",
                  bg: "#C03EDC",
                  color: "#fff",
                  borderRadius: "6px",
                  paddingY: "9px",
                  height: "auto",
                  fontWeight: "medium",
                  display: "flex",
                  justifyContent: "center",
                  _disabled: {
                    bg: "#f7f8f9",
                    color: "#bfc4cd",
                  },
                }}
              >
                Book a call
              </Button>
            </Link>
            <p className="font-bold text-center">{pricing.description}</p>
            <p className="text-[12px] text-center text-[#7a7a7a] leading-normal">
              The ultimate AI-powered assistants for orchestrating your sales
              efforts. Designed to simplify workflows, these AI employees ensure
              precision, speed, and success like never before
            </p>
          </div>
        </div>
      </div>

      <div className="h-[1.5px] w-full rounded-full bg-[#F0EFF1] mt-3"></div>
      <p className="text-[13px] px-3.5 text-center text-[#7a7a7a] leading-normal my-2">
        Interested? Book a call with our team to learn how AI employees can
        transform your process.
      </p>
    </div>
  );
};

export default AIPriceBox;
