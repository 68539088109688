import MakeAPICall from "@/lib/apiCalls";

import {
  type SearchCompanyResponseType,
  type SearchCompanyType,
  type SearchLeadsResponseType,
  type SearchPeopleType,
  type StarterListType,
  CRMType,
  HubspotListResponseDataType,
  IScraperCompanySearchPayload,
  LeadType,
} from "@/types/leads.types";

class LeadsService {
  async getStarterList() {
    const apiCall = new MakeAPICall({
      apiPath: "leads/starter-list",
    });
    const response = await apiCall.get<StarterListType[]>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async createWebhookTable() {
    const apiCall = new MakeAPICall({
      apiPath: "table/blank-table",
      payload: {
        tableName: `Webhook - ${new Date().toISOString()}`,
        columns: [
          {
            name: "Webhook",
            metaData: {
              event: "modal",
              enrichmentMetaData: {
                enrichmentName: "webhook",
              },
            },
          },
        ],
        metaData: {
          isWebhook: true,
        },
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: { tableId: string };
      message?: string;
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async uploadCSV(tableId: string, payload: FormData) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/upload-csv/${tableId}`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      message: string;
      data?: { tableId: string };
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async uploadCSVFindWorkEmail(tableId: string, payload: FormData) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/upload-csv-find-work-email/${tableId}`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      message: string;
      data?: { tableId: string };
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async uploadCSVFindMobileNumber(tableId: string, payload: FormData) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/upload-csv-find-mobile-number/${tableId}`,
      payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      message: string;
      data?: { tableId: string };
    }>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchCompany(payload: SearchCompanyType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-company",
      payload: {
        ...payload,
        page: payload.page || 1,
      },
    });
    const response = await apiCall.post<SearchCompanyResponseType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeople(payload: SearchPeopleType, apiPath?: string) {
    const apiCall = new MakeAPICall({
      apiPath: apiPath || "leads/search-people",
      payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async syncLeads({
    crmType,
    leadType,
  }: {
    leadType: LeadType;
    crmType: CRMType;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/sync-leads/${leadType}/${crmType}`,
    });
    const response = await apiCall.post();
    return response;
  }

  async addLeadsToCampaign(payload: SearchPeopleType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/add-leads-to-table",
      payload: payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async addLeadsToTable(payload: SearchPeopleType, tableId?: string) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/add-leads-to-table-2" + (tableId ? `/${tableId}` : ""),
      payload: payload,
      timeout: 20000,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runAutoPilot(
    payload?: SearchPeopleType,
    type?: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot/${type}`
        : `leads/run-auto-pilot/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runPostEngagements(
    payload: {
      linkedinUrl?: string;
      frequencyToUpdate?: string;
      postDetailsPreference?: string[];
      tableId?: string;
      sendSlackNotifications?: boolean;
      selectedChannel?: any;
      storeOnHubspot?: boolean;
      storeOnLGM?: boolean;
      audienceName?: string;
    },
    type: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot-posts-engagement/${type}`
        : `leads/run-auto-pilot-posts-engagement/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runJobOpenings(
    payload: {
      keywords?: string;
      titleIds?: string;
      locationId?: string;
      industryIds?: string;
      jobType?: string;
      onsiteRemote?: string;
      salary?: string;
      experienceLevel?: string;
      frequencyToUpdate?: string;
      tableId?: string;
      isFindPeopleAtCompany?: boolean;
      isNotifyByEmail?: boolean;
      findPeopleJobTitles?: string[];
    },
    type: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot-job-openings/${type}`
        : `leads/run-auto-pilot-job-openings/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<any>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runMultiSourceJobOpenings(
    payload: {
      jobTitle?: string;
      location?: string;
      organizationName?: string;
      frequencyToUpdate?: string;
      tableId?: string;
      isFindPeopleAtCompany?: boolean;
      isNotifyByEmail?: boolean;
      findPeopleJobTitles?: string[];
    },
    type: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot-multi-source-job-openings/${type}`
        : `leads/run-auto-pilot-multi-source-job-openings/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<any>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runGoogleJobOpenings(
    payload: {
      jobTitle?: string;
      location?: string;
      limit?: number;
      organizationName?: string;
      frequencyToUpdate?: string;
      tableId?: string;
      isNotifyByEmail?: boolean;
      isFindPeopleAtCompany?: boolean;
      isFindWorkEmail?: boolean;
      findPeopleJobTitles?: string[];
      extraVerification?: boolean;
    },
    tableId?: string,
    actionType?: "create" | "update" | "trigger",
  ) {
    const apiCall = new MakeAPICall({
      // apiPath: !tableId
      //   ? `leads/run-auto-pilot-google-job-openings/${type}`
      //   : `leads/run-auto-pilot-google-job-openings/${type}?tableId=${tableId}`,
      apiPath: `leads/trigger-auto-pilot/google-job-openings/${actionType || "create"}`,
      payload: {
        tableName: `${payload?.jobTitle} ${
          payload?.location ? ` - ${payload?.location}` : ""
        }`,
        payload,
        tableId,
      },
    });
    const response = await apiCall.post<any>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runLeadmagicJobOpenings(
    payload: {
      company_name?: string;
      company_website?: string;
      job_title?: string;
      location?: string;
      experience_level?: string;
      job_description?: string;
      has_remote?: boolean;
      posted_within?: number;
      limit?: number;
      frequencyToUpdate?: string;
      tableId?: string;
      isFindPeopleAtCompany?: boolean;
      isNotifyByEmail?: boolean;
      findPeopleJobTitles?: string[];
    },
    type: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot-leadmagic-job-openings/${type}`
        : `leads/run-auto-pilot-leadmagic-job-openings/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<any>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async runPersanaJobOpenings(
    payload: {
      query?: string;
      datePosted?: string;
      employmentTypes?: string;
      jobRequirements?: string;
      jobTitles?: string;
      companyTypes?: string;
      employer?: string;
      limit?: number;
      frequencyToUpdate?: string;
      tableId?: string;
      isFindPeopleAtCompany?: boolean;
      isNotifyByEmail?: boolean;
      findPeopleJobTitles?: string[];
    },
    type: string,
    tableId?: string,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: !tableId
        ? `leads/run-auto-pilot-persana-job-openings/${type}`
        : `leads/run-auto-pilot-persana-job-openings/${type}?tableId=${tableId}`,
      payload: payload,
    });
    const response = await apiCall.post<any>();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeopleMixrank(payload: SearchPeopleType) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-people-mixrank",
      payload: payload,
    });
    const response = await apiCall.post<any>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchCompanyIscraper(
    payload: IScraperCompanySearchPayload,
    apiPath?: string,
    params?: any,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: apiPath || "leads/search-company-linkedin",
      payload: payload,
      params,
    });
    const response = await apiCall.post<SearchLeadsResponseType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async getLeadSearchHistory(searchType: any) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/search-history/${searchType}`,
    });
    const response = await apiCall.get<any[]>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async getHubspotLists() {
    const apiCall = new MakeAPICall({
      apiPath: `leads/hubspot-list`,
    });
    const response = await apiCall.get<HubspotListResponseDataType>();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async deleteLeadSearchHistory(id: string, isDeleteRecords?: boolean) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/search-history/${id}`,
      params: { isDeleteRecords },
    });
    const response = await apiCall.delete();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async searchPeopleUsingGPT({
    context,
    prompt,
    isUseMixRank,
  }: {
    context?: string;
    prompt: string;
    isUseMixRank?: boolean;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: "leads/search-people-using-gpt",
      payload: { prompt, context, isUseMixRank },
    });
    const response = await apiCall.post();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error?.message || "Something went wrong");
    }
  }

  async salesNavImport(numberOfLeads: number, url: string) {
    const apiCall = new MakeAPICall({
      apiPath: `leads/sales-nav-import`,
      payload: {
        maxImport: numberOfLeads,
        url,
      },
      timeout: 10000,
    });
    const response = await apiCall.post();
    return response;
  }
}

const leadService = new LeadsService();
export default leadService;
