import { useEffect } from "react";

import { Button, IconButton, useToast } from "@chakra-ui/react";
import Icons from "../Icons";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { allTemplates } from "../Leads/UseTemplateModal/constants";
import tableService from "@/services/table.service";

interface ColumnPayload {
  columns: { name: string }[];
}

const TemplateDetails = ({ templateId }: { templateId: string }) => {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    handleCreateBlankTable(null);
  }, []);

  const { mutateAsync: createBlankTable } = useMutation({
    mutationFn: (payload: ColumnPayload | undefined) =>
      tableService.createBlankTable(payload),
  });
  const templateData = allTemplates.find(
    (item) => item.heading === decodeURIComponent(templateId),
  );
  const LinkedinTemplate = templateData?.heading == "Find LinkedIn URL";
  const url = new URL(templateData?.url ?? "https://default-url.com");

  // Replace only the origin (hostname + protocol)
  url.host = new URL(window.location.origin).host;
  url.protocol = new URL(window.location.origin).protocol;

  // Set query parameters if needed
  if (templateData?.templateId) {
    url.searchParams.set("templateId", templateData.templateId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCreateBlankTable = async (
    payload: ColumnPayload | null | undefined,
  ) => {
    if (payload === null || payload === undefined) {
      console.log("Payload is null or undefined, skipping API call");
      return;
    }
    await createBlankTable(payload, {
      onSuccess: (response) => {
        if (!response.success || !response.data?.success) {
          toast({
            title: "Error",
            description: response.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        const tableId = response.data?.data?.tableId;
        if (tableId) {
          // Construct the URL for the new table
          const tableUrl = `/table/${tableId}`;

          // Open the table in a new tab
          const newTab = window.open(tableUrl, "_blank");

          if (!newTab) {
            console.error("Popup blocked! Allow pop-ups for this site.");
          }
        }
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    });
  };
  return (
    <div>
      <div className="flex items-center gap-4  border border-[#F0EFF1] bg-white px-[16px] py-[8px]">
        <IconButton
          aria-label="back"
          variant="ghost"
          className="!rounded-full"
          icon={<Icons.ArrowLeftIcon size={24} />}
          onClick={() => navigate({ to: "/templates" })}
        />
        <p className="text-[24px] font-bold leading-[32px]">Templates</p>
      </div>
      {templateData && (
        <div className="my-6 flex gap-4 p-[32px]">
          <div
            className="w-[55%] cursor-pointer rounded-md border border-[#E5E7EB] p-2"
            onClick={() => {
              window.open(templateData?.url, "_blank");
            }}
          >
            <img src={templateData?.image} alt="" />
          </div>
          <div className="flex w-[45%] flex-col gap-4 px-[60px] pt-8">
            <span className="flex flex-row place-items-center gap-4">
              {<templateData.group.icon className="size-16" />}
              <p className="text-left text-[28px] font-bold leading-[40px] text-[#0F0D1C]">
                {templateData?.heading}
              </p>
            </span>
            <p className="text-[18px] text-[#4A5264]">{templateData?.title}</p>
            <Button
              w={"fit-content"}
              className="scale-[1.2] place-self-center !bg-primary-gradient !text-[24px] font-bold !leading-[40px] hover:opacity-80"
              colorScheme="primary"
              borderRadius={"6px"}
              onClick={() => {
                LinkedinTemplate && localStorage.setItem("enrich", "true");
                url.searchParams.set("templateId", templateData?.templateId);
                window.open(url.href, "_blank");
                // }
              }}
            >
              Try Now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateDetails;
