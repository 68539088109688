import { useMemo, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useModalStore } from "@/stores/modal.store";
import { useMutation } from "@tanstack/react-query";
import tableService from "@/services/table.service";
import { queryClient } from "@/lib/queryClient";
import { useTableData, useTableInfo } from "@/hooks/useTableData";

const CloneWorkflow = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [companyTableName, setCompanyTableName] = useState<string>("");
  const [peopleTableName, setPeopleTableName] = useState<string>("");
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );
  const { data, isLoading: isLoadingTableData } = useTableData(
    modalData?.metaData?.tableId || "",
    true,
  );
  const linkedColumnIds = useMemo<string[]>(() => {
    return (
      data?.tableData?.columns
        ?.map(
          (column) => column?.metaData?.enrichmentMetaData?.dependentTableId,
        )
        .filter(Boolean) || []
    );
  }, [data?.tableData?.columns, modalData?.metaData?.tableId]);

  const { data: tableInfos, isLoading: isLoadingTableInfo } =
    useTableInfo(linkedColumnIds);

  const tables = useMemo(
    () =>
      tableInfos
        ? Array.isArray(tableInfos.data)
          ? tableInfos.data
          : [tableInfos.data]
        : [],
    [tableInfos],
  );
  const dependentTableId = tables?.[0]?._id;
  const { mutateAsync: copyTableMutation, isPending } = useMutation({
    mutationFn: (tableId: string) =>
      tableService.cloneAutopilotTable(tableId, {
        peopleTableName,
        companyTableName,
        dependentTableId,
      }),
  });

  const onClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
  };

  const handleClone = () => {
    setLoading(true);
    return copyTableMutation(modalData?.metaData?.tableId, {
      onSuccess: async (response) => {
        if (response.success) {
          await queryClient.refetchQueries({
            queryKey: ["tables"],
          });
          setLoading(false);
          onClose();
          modalData?.metaData?.handleCloneCallback &&
            response?.data?.data?.table &&
            modalData?.metaData?.handleCloneCallback(
              response?.data?.data?.table || {},
            );
        } else {
          toast({
            title: "Something went wrong",
            description: response?.data?.message || "Something went wrong",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      onError: (error) => {
        setLoading(false);
        toast({
          title: "Something went wrong",
          description: error?.message || "Something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      },
    });
  };

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={isOpenModal && modalData.modalType === "clone-workflow"}
      size={"2xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="pb-4 font-poppins">
        <ModalHeader className="border-b-[1px] text-center">
          <div className="flex items-center">
            <Text className="flex-1">Clone Workflow</Text>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoadingTableData || isLoadingTableInfo ? (
            <div className="flex items-center justify-center mt-3">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </div>
          ) : (
            <>
              <div>
                <p className="mt-4 pb-1 text-sm font-semibold ">
                  {"Enter Company Table Name"}
                </p>
                <div className="relative h-full">
                  <Input
                    placeholder="Enter Company Table Name"
                    value={companyTableName}
                    onChange={(e) => setCompanyTableName(e.target.value)}
                  ></Input>
                </div>
              </div>
              {dependentTableId && (
                <div>
                  <div>
                    <p className="mt-4 pb-1 text-sm font-semibold ">
                      {"Enter People Table Name"}
                    </p>
                    <div className="relative h-full">
                      <Input
                        placeholder="Enter People Table Name"
                        value={peopleTableName}
                        onChange={(e) => setPeopleTableName(e.target.value)}
                      ></Input>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-[20px] flex w-full items-center justify-center">
                <Button
                  isDisabled={
                    dependentTableId
                      ? !peopleTableName || !companyTableName
                      : !companyTableName
                  }
                  onClick={handleClone}
                  isLoading={isPending || loading}
                  className={`w-[100px] rounded-[5px] !bg-[#693DC7] px-1 py-[7px] !font-title !text-sm !text-[#fff]`}
                >
                  <span className="font-semibold">Clone</span>
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CloneWorkflow;
