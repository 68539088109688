import tableService from "@/services/table.service";
import { useQuery } from "@tanstack/react-query";

export const useAutopilotTableList = () => {
  return useQuery({
    queryKey: ["autopilotTables"],
    queryFn: () => tableService.getAutopilotTableList(),
    staleTime: 1000 * 60 * 30, // 30 minutes
    gcTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export default useAutopilotTableList;
