import { useState, useEffect } from "react";
import { Box, Button, Flex, Stack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import misServices from "@/services/misc.services";
import icPlay from "@/assets/svgs/ic-play.svg";
import PropTypes from "prop-types";
import { convertSlateToText } from "@/utils";

// Define the type for personalization (adjust this to match your actual structure)
type Personalization = {
  type: string;
  children: { text: string }[];
};

interface RunPanelProps {
  personalization: Personalization[];
  setPersonalization: (newPersonalization: Personalization[]) => void;
  templateData: { prompt?: string | Personalization[]; description?: string };
}

const RunPanel = ({ personalization, templateData }: RunPanelProps) => {
  const toast = useToast();
  const [personalizationPromptPreview, setPersonalizationPromptPreview] =
    useState("");
  const [dynamicLabels, setDynamicLabels] = useState<string[]>([]); // To hold dynamic labels
  const [userInputs, setUserInputs] = useState<{ [key: string]: string }>({}); // To hold user inputs for placeholders

  useEffect(() => {
    // setPersonalization([]);
    if (personalization?.length > 0) {
      // Extract labels based on the personalization text
      const prompt = personalization[0]?.children[0]?.text;
      if (prompt) {
        const labels = extractPlaceholders(prompt);
        setDynamicLabels(labels);
      }
    }
  }, [personalization]);

  const { mutateAsync: previewMutateAsync, isPending: isGeneratingPreview } =
    useMutation({
      mutationFn: (forPersonalizationPromptPreview: boolean) => {
        let finalPrompt = convertSlateToText(personalization).text || "";
        // Replace all placeholders with the corresponding user inputs
        dynamicLabels.forEach((label) => {
          if (userInputs[label]) {
            const placeholder = `{{${label}}}`;
            finalPrompt = finalPrompt.replace(
              new RegExp(placeholder, "g"),
              userInputs[label],
            );
          }
        });

        return misServices.generateGeneralPreview({
          aiPersonalization: finalPrompt,
          isStatic: true,
          forPersonalizationPromptPreview,
        });
      },
      onSuccess: (response) => {
        const safeResponse = response as {
          data?: { data?: { personalizationPromptPreview?: string } };
        };
        setPersonalizationPromptPreview(
          safeResponse?.data?.data?.personalizationPromptPreview ?? "",
        );
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
    });

  // Function to extract placeholders (like {{country}}, {{website}})
  const extractPlaceholders = (prompt: string) => {
    const matches = prompt.match(/{{(.*?)}}/g); // Match all {{placeholder}} values
    return matches ? matches.map((match) => match.replace(/[{}]/g, "")) : [];
  };

  const handleInputChange = (label: string, value: string) => {
    setUserInputs((prev) => ({ ...prev, [label]: value }));
  };

  return (
    <div>
      <div className="p-4 pb-6">
        <h3 className="mb-2 text-xl font-semibold text-black">
          {templateData?.description}
        </h3>
        <p className="text-[13px] text-[#0F0D1C]">
          Create instant responses to Requests for Proposals to increase
          business opportunities.
        </p>
      </div>
      <div className="m-3 flex flex-col rounded-lg bg-white bg-gradient-to-r from-[#8E32A4] to-[#5024DC] p-4 p-[1px]">
        <div className="overflow-hidden rounded-lg bg-white p-4">
          {dynamicLabels.map((label, index) => (
            <div key={index} className="mb-3">
              <label className="mb-3 block text-sm text-[#0F0D1C]">{`Enter ${label}`}</label>
              <input
                type="text"
                value={userInputs[label] || ""}
                onChange={(e) => handleInputChange(label, e.target.value)}
                className="w-full rounded border border-[#D0D4DC] p-2"
                placeholder={`Type ${label} here...`}
              />
            </div>
          ))}
          <div className="text-end">
            <Button
              className="ml-auto mt-4 flex items-center gap-3 rounded-lg border border-[#BD90F4] !bg-[#C03EDC] p-4 py-2 !text-[#fff]"
              onClick={() => previewMutateAsync(true)}
              isLoading={isGeneratingPreview}
            >
              <img alt="play" src={icPlay} />
              Run Workflow
            </Button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <h5 className="text-base text-[#0F0D1C]">Results</h5>
        <p className="text-[13px] text-[#7E889F]">
          The results of each step in the workflow will show here once you run
          the workflow.
        </p>
        <div className="mt-6 rounded-lg bg-gradient-to-r from-[#8E32A4] to-[#5024DC] p-[1px] ">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-6">
            <Stack direction="column" spacing={4}>
              <Flex key={11} align="center" gap={6}>
                <Box className="relative flex items-center justify-center rounded-full bg-[#F5F3FF]"></Box>
                {personalizationPromptPreview || "No preview available"}
              </Flex>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

RunPanel.propTypes = {
  personalization: PropTypes.array.isRequired,
  setPersonalization: PropTypes.func.isRequired,
  templateData: PropTypes.object.isRequired,
};

export default RunPanel;
