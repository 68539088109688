import { Button, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import Icons from "@/components/Icons";
import { useEnrichStore } from "@/stores/enrich.store";
import CustomTextEditor from "../../Common/CustomTextEditor";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
] as any;

const MergeColumns = () => {
  const [columnName, setColumnName] = useState("");
  const [inputs, setInputs] = useState(() => {
    return [
      {
        id: uuidv4(),
        value: [...initialSlateValue],
      },
    ];
  });

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );

  const handleAddInput = () => {
    setInputs((prev) => [
      ...prev,
      {
        id: uuidv4(),
        value: initialSlateValue,
      },
    ]);
  };

  const handleDeleteInput = (id: string) => {
    setInputs((prev) => prev.filter((input) => input.id !== id));
  };
  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
  ) => {
    event.preventDefault();
    const draggedIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
    if (draggedIndex !== index) {
      const reorderedInputs = Array.from(inputs);
      const [movedInput] = reorderedInputs.splice(draggedIndex, 1);
      reorderedInputs.splice(index, 0, movedInput);
      setInputs(reorderedInputs);
    }
  };
  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData?.enrichmentMetaData;
      if (enrichmentData) {
        setColumnName(enrichmentData.columnName!);
        setInputs(enrichmentData.inputs!);
      }
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="overflow-y-auto p-4">
        <div className="mb-2 py-4 pb-0">
          <p className="pb-1 text-base font-semibold">Column Name</p>
          <Input
            value={columnName}
            onChange={(e) => setColumnName(e.target.value)}
            placeholder="New Column"
            disabled={viewMode === "edit"}
          />
        </div>
        <p className="mb-4 text-sm font-light text-darkTextGray">
          Combine data from different columns in a specified sequence.
        </p>
        <div className="mb-4 space-y-3">
          {inputs.map((input, index) => (
            <div
              key={input.id}
              className="input-box flex items-center justify-center gap-3"
              onDragStart={(event) => handleDragStart(event, index)}
              onDragOver={handleDragOver}
              onDrop={(event) => handleDrop(event, index)}
            >
              <span
                draggable
                className="dragging cursor-pointer"
                onMouseDown={(event) => event.stopPropagation()}
                onDragStart={(event) => handleDragStart(event as any, index)}
              >
                <Icons.DragIcon size={20} color="gray" />
              </span>
              <div className="flex-1">
                <CustomTextEditor
                  editorHeight={"4rem"}
                  slateValue={input.value}
                  setSlateValue={(value) => {
                    setInputs((prev) =>
                      prev.map((prevInput) =>
                        prevInput.id === input.id
                          ? { ...prevInput, value }
                          : prevInput,
                      ),
                    );
                  }}
                  placeholder={`{{Column Name}}`}
                />
              </div>

              <span
                className={index ? "cursor-pointer" : "cursor-not-allowed"}
                onClick={() => (index ? handleDeleteInput(input.id) : null)}
              >
                <Icons.TrashIcon size={20} color={index ? "red" : "gray"} />
              </span>
            </div>
          ))}
        </div>
        <Button
          variant="solid"
          className="gradient-border w-full !rounded-md !bg-primaryPink !text-white"
          onClick={handleAddInput}
        >
          Add Input
        </Button>
      </div>
      <SaveAndRunEnrichment
        isDisabled={!columnName || !inputs.length}
        apiPayload={{
          columnName,
          inputs,
        }}
      />
    </>
  );
};

export default MergeColumns;
