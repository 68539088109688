import { IRowMenuProps, TFolderRow } from "../types";
import {
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react";
import Icons from "@/components/Icons";
import { CgRename } from "react-icons/cg";
import {
  useDeleteTable,
  useDeleteFolder,
  useCreateEmptyFolder,
} from "../hooks/useTableData";
import { useTableState } from "../hooks/useTableState.store";
import { useModalStore } from "@/stores/modal.store";

export const RowMenu = ({ node, disclosure, ...props }: IRowMenuProps) => {
  const updateState = useTableState((state) => state.updateState);
  const { mutateAsync: deleteTable } = useDeleteTable();
  const { mutateAsync: deleteFolder } = useDeleteFolder();
  const { create } = useCreateEmptyFolder();
  const { updateState: updateModalStore } = useModalStore();
  return (
    <Menu {...disclosure} placement="bottom-end" gutter={8} variant={"persana"}>
      <MenuButton
        onClickCapture={(e) => e.preventDefault()}
        {...props}
        as={IconButton}
        size={"xs"}
        icon={<Icons.MoreIcon />}
        onClick={() => disclosure.onToggle()}
      />
      <Portal>
        <MenuList zIndex={"dropdown"} transition={"all 0s ease"} as="ul">
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              updateState({ editing: node.data?._id });
            }}
            icon={<CgRename />}
          >
            Rename
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!node.data) return;
              if (node.data.type === "folder") {
                deleteFolder(node.data._id);
              } else {
                deleteTable(node.data._id);
              }
            }}
            icon={<Icons.DeleteBinIcon className="text-red-500" />}
          >
            Delete
          </MenuItem>
          <MenuItem
            icon={<Icons.FolderClosedIcon />}
            onClick={() =>
              updateModalStore({
                isOpenModal: true,
                modalData: {
                  modalType: "add-folder",
                  metaData: node.data?._id,
                },
              })
            }
          >
            Add to Folder
          </MenuItem>
          {node.data?.type === "folder" ? (
            <>
              <Divider />
              <MenuItem
                icon={<Icons.FolderClosedIcon />}
                onClick={() => create(node.data as TFolderRow)}
              >
                Create Folder
              </MenuItem>
              <MenuItem
                hidden // show after get-started modal supports folderId
                icon={<Icons.ViewTableIcon />}
                onClick={() =>
                  updateModalStore({
                    isOpenModal: true,
                    modalData: { modalType: "get-started" },
                  })
                }
              >
                Create Lead List
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Portal>
    </Menu>
  );
};
