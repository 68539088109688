import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";
import BCHeader from "@/assets/svgs/bg-grad.svg";

const Search = () => {
  const searchInput = useEnrichStore((state) => state.searchInput);
  const updateState = useEnrichStore((state) => state.updateState);

  return (
    <>
      <div
        style={{ backgroundImage: `url(${BCHeader})` }}
        className="bg-cover bg-no-repeat"
      >
        <div className="mb-[12px] flex items-center justify-between px-8 pb-1 pt-4">
          <p className="text-[20px] leading-[24px]">Enrich your search</p>
          <Button
            colorScheme="primary"
            variant="unstyled"
            leftIcon={<Icons.ArrowBackIcon />}
            className="!flex h-[40px] items-center !text-textPurple"
            onClick={() => {
              updateState({
                isOpenEnrichModal: false,
              });
            }}
          >
            Go Back
          </Button>
        </div>
        <div className="border-b px-6">
          <InputGroup
            width="100%"
            className="mb-[12px] rounded-[20px] border bg-white"
          >
            <InputLeftElement>
              <Icons.SearchIcon size={20} color="gray" />
            </InputLeftElement>
            <Input
              style={{ borderRadius: "20px" }}
              placeholder="What you are looking for?"
              variant="outline"
              onChange={(e) => {
                updateState({
                  searchInput: e.target.value,
                  activeCategory: "",
                });
              }}
              value={searchInput}
            />
          </InputGroup>
        </div>
      </div>
    </>
  );
};

export default Search;
