import { useCallback, useEffect, useMemo } from "react";
import { CsvExportParams, RowPosition } from "ag-grid-community";
import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { getGridRoot, useSelectionStore } from "./useSelectionStore";

export const useCopyAsCSV = () => {
  const { range, gridApi, features, setZeroRange } = useSelectionStore();

  const toast = useToast({
    position: "top-right",
    isClosable: true,
    duration: 1000,
    variant: "solid",
  });
  const copyAsCSV = useCallback(
    (tsv: boolean = true) => {
      if (!features.copy) return;
      if (!range || !gridApi) return;
      const { startCell, endCell } = range;
      const isSingleCell = _.isEqual(startCell, endCell);

      const startRowIndex = Math.min(startCell.rowIndex, endCell.rowIndex);
      const endRowIndex = Math.max(startCell.rowIndex, endCell.rowIndex);

      const allColumns = gridApi.getColumns();
      if (!allColumns) return;
      const startColIndex = Math.min(
        allColumns.findIndex((col) => col.getColId() === startCell.colId),
        allColumns.findIndex((col) => col.getColId() === endCell.colId),
      );
      const endColIndex = Math.max(
        allColumns.findIndex((col) => col.getColId() === startCell.colId),
        allColumns.findIndex((col) => col.getColId() === endCell.colId),
      );

      const selectedColumns = allColumns
        .slice(startColIndex, endColIndex + 1)
        .map((col) => col.getColId());

      const rowPositions: Array<RowPosition> = [];
      for (let i = startRowIndex; i <= endRowIndex; i++) {
        const rowNode = gridApi.getDisplayedRowAtIndex(i);
        if (rowNode) {
          rowPositions.push({ rowPinned: undefined, rowIndex: i });
        }
      }

      const params: CsvExportParams = {
        columnSeparator: tsv ? "\t" : ",",
        onlySelected: false,
        skipColumnHeaders: true,
        suppressQuotes: false,
        columnKeys: selectedColumns,
        rowPositions,
      };
      let csvContent: string = "";
      if (isSingleCell) {
        const rowNode = gridApi.getDisplayedRowAtIndex(startCell.rowIndex);
        if (rowNode) {
          csvContent = rowNode.data[startCell.colId];
        }
      } else {
        csvContent = gridApi.getDataAsCsv(params) ?? "";
      }

      navigator.clipboard.writeText(csvContent).then(
        () => {
          setZeroRange();
          toast({
            title: isSingleCell
              ? "Copied"
              : tsv
                ? "Copied as TSV"
                : "Copied as CSV",
            status: "info",
          });
        },
        (err) => {
          console.error("Could not copy text: ", err);
        },
      );
    },
    [features.copy, range, gridApi, setZeroRange, toast],
  );
  return copyAsCSV;
};

export const useCopyShortcut = () => {
  const gridApi = useSelectionStore((state) => state.gridApi);
  const container = useMemo<HTMLElement | null>(() => {
    const gridRoot = getGridRoot(gridApi?.getGridId());
    return gridRoot?.querySelector(".ag-center-cols-container") ?? null;
  }, [gridApi]);

  const copyAsCSV = useCopyAsCSV();
  const handleCopy = useCallback(
    (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "c") {
        event.preventDefault();
        copyAsCSV(!event.shiftKey);
      }
    },
    [copyAsCSV],
  );

  useEffect(() => {
    if (!container) return;
    container.addEventListener("keydown", handleCopy);
    return () => {
      container.removeEventListener("keydown", handleCopy);
    };
  }, [handleCopy, container]);
  return copyAsCSV;
};
