import { useEffect } from "react";
import { ModalBody, Spinner, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import leadService from "@/services/leads.service";
import { useNavigate } from "@tanstack/react-router";

interface Props {
  onClose: () => void;
}

const Webhook = ({ onClose }: Props) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationFn: async () => leadService.createWebhookTable(),
  });

  useEffect(() => {
    mutateAsync(undefined, {
      onSuccess: (response: any) => {
        if (!response?.success) {
          toast({
            title: "Error",
            description: response?.data?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        onClose();
        const tableId = response.data?.tableId;
        if (tableId) {
          navigate({
            to: "/table/$tableId",
            params: {
              tableId,
            },
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      },
    });
  }, []);

  return (
    <ModalBody>
      <div className="mb-3 flex items-center justify-center">
        <Spinner size="lg" colorScheme="purple" />
      </div>
    </ModalBody>
  );
};

export default Webhook;
