import { Input } from "@chakra-ui/react";
import searchIcon from "@/assets/svgs/ic-search.svg";
import PropTypes from "prop-types";

// Define types for the props
interface TemplateSidebarProps {
  checkboxList: { id: string; item: string }[]; // checkboxList is an array of objects with 'id' and 'item' properties
  searchValue: string;
  onSearchChange: (value: string) => void;
  onCategoryChange: (categoryId: string) => void;
  selectedGroups: { name: string }[]; // selectedGroups is an array of objects with 'name' property
}

const TemplateSidebar: React.FC<TemplateSidebarProps> = ({
  checkboxList,
  searchValue,
  onSearchChange,
  onCategoryChange,
  selectedGroups,
}) => {
  return (
    <div className="h-screen border border-y-0 border-[#D0D4DC] p-4 px-6">
      <div>
        <h4 className="mb-4 text-base font-medium text-[#0F0D1C]">Browse</h4>
        <div className="flex items-center gap-4 rounded-full border border-[#D0D4DC] p-3 px-4">
          <img src={searchIcon} alt="search" />
          <Input
            placeholder="Search templates"
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
            className="!border-none text-sm font-medium text-[#0F0D1C]"
          />
        </div>
      </div>
      <div className="mt-4 rounded-lg border border-[#DDD8FF]">
        <h5 className="rounded-lg rounded-b-none bg-[#F5F3FF] p-4 text-sm font-medium text-[#0F0D1C]">
          Categories
        </h5>
        <ul className="space-y-3 px-3">
          {checkboxList.map((checkbox) => (
            <li key={checkbox.id} className="flex items-center gap-3 py-2">
              <input
                type="checkbox"
                id={checkbox.id}
                onChange={() => onCategoryChange(checkbox.id)}
                checked={selectedGroups.some(
                  (group) => group.name === checkbox.item,
                )}
                className="size-4 cursor-pointer"
              />
              <label
                htmlFor={checkbox.id}
                className="cursor-pointer text-sm font-medium text-[#373F51]"
              >
                {checkbox.item}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// PropTypes for runtime validation (optional)
TemplateSidebar.propTypes = {
  checkboxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      item: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  selectedGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default TemplateSidebar;
