import {
  Box,
  VStack,
  Text,
  Divider,
  CircularProgress,
  CircularProgressLabel,
  Progress,
} from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { PiCrown } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import PersanaLogo from "@/assets/images/logo.png";
import routes from "@/constants/routes";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import { RoutesType } from "@/types/routes.types";
import userService from "@/services/user.service";

// Utility to format numbers
const formatNumber = (num: number) =>
  new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(num);

const Sidebar = () => {
  const navigate = useNavigate();
  const updateState = useModalStore((state) => state.updateState);
  const creditDetails = userStore((state) => state.creditDetails);
  const currentUser = userStore((state) => state.currentUser);
  const [isExpanded, setIsExpanded] = useState(false);
  const isAdmin = currentUser?.role?.toLocaleLowerCase() === "admin";

  const handleLogout = async () => {
    localStorage.removeItem("persana-alradyShowedIntro");
    navigate({ to: "/login" });
    await userService.logout();
    window.location.reload();
  };

  const menuSections = [
    {
      items: routes
        .filter((route) => route.isHidden === false)
        .filter((route) =>
          ["Dashboard", "Search", "AI Signals", "Autopilot"].includes(
            route.name || "",
          ),
        ) as RoutesType[],
    },
    {
      items: routes
        .filter((route) => route.isHidden === false)
        .filter((route) =>
          [
            "Agents",
            "AI Digital Workers",
            "Quick Tools",
            "Chrome Extension",
            "Playbooks",
            "Templates",
          ].includes(route.name || ""),
        ) as RoutesType[],
    },
    {
      items: routes
        .filter((route) => route.isHidden === false)
        .filter((route) =>
          ["Team Overview", "Admin", "Settings"].includes(route.name || ""),
        ) as RoutesType[],
    },
  ];

  // Calculate credit percentage
  const creditUsed = creditDetails?.creditUsed || 0;
  const creditLeft = creditDetails?.creditLeft || 0;
  const creditPercentage = creditLeft > 0 ? (creditUsed / creditLeft) * 100 : 0;

  return (
    <Box
      className={`fixed left-0 top-0 flex h-screen flex-col items-center bg-white py-5 shadow-lg transition-all duration-300 ${
        isExpanded ? "w-64" : "w-20"
      }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      zIndex={100}
      style={{
        transform: "translateZ(0)", // GPU acceleration
      }}
    >
      {/* Logo */}
      <Box
        className={`relative mb-3 flex cursor-pointer items-center rounded-sm px-3 py-1 transition-all duration-200 ${isExpanded ? "" : "place-content-center"}`}
        style={{
          willChange: "background-color, box-shadow", // Hint for smoother animations
        }}
      >
        <img src={PersanaLogo} alt="Persana Logo" className="h-5 shrink-0" />
        <span
          className={`ml-3 truncate whitespace-nowrap text-[18px] font-[500] leading-tight ${
            isExpanded ? "opacity-100" : "hidden flex-none opacity-0"
          } `}
        >
          PersanaAI
        </span>
      </Box>
      {/* <Box className="relative mb-4">
        <img
          hidden={isExpanded}
          src={PersanaLogo}
          alt="Persana Logo"
          className="h-5 shrink-0"
        />
        <img
          hidden={!isExpanded}
          src={PersanaLogoFull}
          alt="Persana Logo"
          className="h-5 shrink-0"
        />
      </Box> */}

      {/* Menu Sections with Dividers */}
      {menuSections.map((section, sectionIndex) => (
        <VStack key={sectionIndex} align="stretch" w="full" gap={1}>
          {section.items.map((item) => {
            const isActive = location.pathname === item.path;

            return item.name === "Agents" ? (
              <Box
                onClick={() => {
                  updateState({
                    isOpenModal: true,
                    modalData: { modalType: "agents" },
                  });
                }}
                key={item.path}
                className={`relative flex cursor-pointer items-center rounded-sm px-3 py-2 transition-all duration-200 ${
                  isActive
                    ? "rounded-r-none border-r-4 border-r-[#0084fe] bg-gradient-to-r from-[#693DC7] to-[#BF72E1] text-white"
                    : "hover:bg-[#DDD8FF] hover:shadow-sm"
                } ${isExpanded ? "" : "place-content-center"}`}
                style={{
                  willChange: "background-color, box-shadow", // Hint for smoother animations
                }}
              >
                <item.icon size={18} className="h-[1.25em] shrink-0" />
                <span
                  className={`ml-3 truncate whitespace-nowrap text-[15px] leading-tight transition-all duration-200 ${
                    isExpanded ? "opacity-100" : "hidden flex-none opacity-0"
                  }`}
                >
                  {item.name}
                </span>
              </Box>
            ) : (
              <Link
                key={item.path}
                to={`${item.path}`}
                params={{}}
                className={`${item.isForAdmin && !isAdmin ? "hidden" : "block"}`}
              >
                <Box
                  className={`relative flex cursor-pointer items-center rounded-sm px-3 py-2 transition-all duration-200 ${
                    isActive
                      ? "rounded-r-none border-r-4 border-r-[#0084fe] bg-gradient-to-r from-[#693DC7] to-[#BF72E1]"
                      : "hover:bg-[#DDD8FF] hover:shadow-sm"
                  } ${isExpanded ? "" : "place-content-center"}`}
                  style={{
                    willChange: "background-color, box-shadow", // Hint for smoother animations
                  }}
                >
                  <item.icon
                    size={18}
                    className={`h-[1.25em] shrink-0 ${isActive ? "text-white" : "text-black"}`}
                  />
                  <span
                    className={`ml-3 truncate whitespace-nowrap text-[15px] leading-tight ${
                      isExpanded ? "opacity-100" : "hidden flex-none opacity-0"
                    } ${isActive ? "text-white" : "text-black"}`}
                  >
                    {item.name}
                  </span>
                </Box>
              </Link>
            );
          })}
          {sectionIndex !== menuSections.length - 1 && <Divider mb={2} />}
        </VStack>
      ))}

      <Divider my={3} />

      <CollapsedCreditUI
        creditPercentage={creditPercentage}
        creditLeft={creditLeft}
        isExpanded={isExpanded}
      />

      <Box className={`w-full px-5 ${isExpanded ? "block" : "hidden"}`}>
        <Progress
          borderRadius="full"
          value={creditPercentage}
          size="sm"
          mb={1}
          sx={{
            "& > div": {
              background:
                "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) !important",
            },
          }}
        />
        <Text
          fontSize="sm"
          color="gray.600"
          className="truncate whitespace-nowrap"
        >
          {creditUsed}/{creditLeft} Credits
        </Text>
      </Box>

      <Divider my={3} />

      <VStack align="stretch" w="full">
        <Box
          className={`relative flex cursor-pointer items-center rounded-md bg-[#EBFFEF] px-3 py-1 text-[#00B41D] hover:shadow-xl ${
            isExpanded ? "" : "place-content-center"
          }`}
          onClick={() =>
            updateState({
              isOpenModal: true,
              modalData: { modalType: "pricing", action: "change" },
            })
          }
        >
          <PiCrown className="shrink-0 text-[#00B41D]" />
          <span
            className={`ml-3 truncate whitespace-nowrap ${
              isExpanded ? "opacity-100" : "hidden opacity-0"
            }`}
          >
            Upgrade
          </span>
        </Box>

        <Box
          onClick={handleLogout}
          className={`${
            isExpanded ? "" : "place-content-center"
          } flex cursor-pointer items-center rounded-md px-3 py-1 hover:bg-[#DDD8FF]`}
        >
          <BiLogOutCircle className="shrink-0" />
          <span
            className={`ml-3 truncate whitespace-nowrap ${
              isExpanded ? "opacity-100" : "hidden opacity-0"
            }`}
          >
            Logout
          </span>
        </Box>
      </VStack>
      <Box
        display="flex"
        alignItems="center"
        mt="auto"
        w="full"
        py={1}
        px={3}
        className={`${isExpanded ? "" : "place-content-center"}`}
      >
        {/* <Avatar
          background={"#DDD8FF"}
          size="sm"
          name={`${currentUser?.profileInfo?.firstName} ${currentUser?.profileInfo?.lastName}`}
        /> */}
        <Box ml={1} className={`flex flex-col ${isExpanded ? "" : "hidden"}`}>
          {/* <Text fontSize="sm">
            {`${currentUser?.profileInfo?.firstName} ${currentUser?.profileInfo?.lastName || ""}`}
          </Text> */}
          <Text fontSize="xs" color="gray.500">
            {currentUser?.Email}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;

export function CollapsedCreditUI({
  creditPercentage,
  creditLeft,
  isExpanded,
}: {
  creditPercentage: number;
  creditLeft: number;
  isExpanded: boolean;
}) {
  if (isExpanded) return null;
  return (
    <Box className="relative flex items-center justify-center">
      <CircularProgress
        value={creditPercentage}
        size="40px"
        thickness="8px"
        color="purple.500"
      >
        <CircularProgressLabel
          fontSize="xs"
          fontWeight="bold"
          color="purple.700"
        >
          {formatNumber(creditLeft)}
        </CircularProgressLabel>
      </CircularProgress>
    </Box>
  );
}
