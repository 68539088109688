import { Tabs, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react";
import { useModalStore } from "@/stores/modal.store";
import EditPreset from "./EditPreset";
import RunPanel from "./RunPanel";
import { useState, useEffect } from "react";
import GetStartedModal from "@/components/Leads/GetStartedModal";

interface TemplateDataPayload {
  prompt?: string;
  [key: string]: unknown;
}

interface TemplateData {
  payload?: TemplateDataPayload;
  prompt?: string;
  [key: string]: unknown;
}

interface TemplateTabsProps {
  templateData: TemplateData;
}

const TemplateTabs: React.FC<TemplateTabsProps> = ({ templateData }) => {
  const [activeTab, setActiveTab] = useState(1); // Default to "Run" tab
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  // Local state to manage personalization
  const [personalization, setPersonalization] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  // Ensure activeTab switches back when modal closes
  useEffect(() => {
    if (!isOpenModal && activeTab === 2) {
      updateState({
        isOpenModal: false,
        modalData: { modalType: "" },
      });
      setActiveTab(1); // Reset to "Run" tab when modal closes
    }
  }, [isOpenModal, activeTab]);

  // Populate personalization when templateData updates
  useEffect(() => {
    const promptText = templateData?.payload?.prompt || templateData?.prompt;
    if (promptText) {
      setPersonalization([
        {
          type: "paragraph",
          children: [{ text: promptText }],
        },
      ]);
    }
  }, [templateData]);

  // Handle tab switching
  const handleTabChange = (index: number) => {
    setActiveTab(index);

    if (index === 2) {
      updateState({
        isOpenModal: true,
        modalData: { modalType: "get-started" },
      });
    }
  };

  return (
    <>
      <Tabs
        index={activeTab}
        onChange={handleTabChange}
        className="flex-1 bg-[#F9F8F9]"
      >
        <TabList className="flex items-center gap-4 border border-[#D0D4DC] px-8 py-4">
          <Tab className="text-base font-medium text-[#373F51]">
            Edit Workflow
          </Tab>
          <Tab className="text-base font-medium text-[#C03EDC]">Run</Tab>
          <Tab className="text-base font-medium text-[#373F51]">Bulk Run</Tab>
        </TabList>
        <TabPanels className="p-12 py-4">
          <TabPanel>
            <EditPreset
              personalization={personalization}
              setPersonalization={setPersonalization}
            />
          </TabPanel>
          <TabPanel>
            <RunPanel
              personalization={personalization}
              setPersonalization={setPersonalization}
              templateData={templateData}
            />
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>

      {/* Modal Rendering - This will automatically close when isOpenModal becomes false */}
      {isOpenModal && modalData?.modalType === "get-started" && (
        <GetStartedModal />
      )}
    </>
  );
};

export default TemplateTabs;
