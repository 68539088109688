export const CATEGORY_OPTIONS = [
  { label: "Advertising and Marketing", value: "ADVERTISING_AND_MARKETING" },
  {
    label: "Agriculture and Natural Resources",
    value: "AGRICULTURE_AND_NATURAL_RESOURCES",
  },
  { label: "Automotive", value: "AUTOMOTIVE" },
  {
    label: "Business Products and Services",
    value: "BUSINESS_PRODUCTS_AND_SERVICES",
  },
  {
    label: "Computer Hardware and Semiconductors",
    value: "COMPUTER_HARDWARE_AND_SEMICONDUCTORS",
  },
  {
    label: "Conglomerates, Shell, and Holding Companies",
    value: "CONGLOMERATES_SHELL_AND_HOLDING_COMPANIES",
  },
  { label: "Construction", value: "CONSTRUCTION" },
  { label: "Consumer Products", value: "CONSUMER_PRODUCTS" },
  { label: "Consumer Services", value: "CONSUMER_SERVICES" },
  { label: "Defense and Aerospace", value: "DEFENSE_AND_AEROSPACE" },
  { label: "Education", value: "EDUCATION" },
  { label: "Energy", value: "ENERGY" },
  { label: "Engineering", value: "ENGINEERING" },
  { label: "Environmental Services", value: "ENVIRONMENTAL_SERVICES" },
  { label: "Financial Services", value: "FINANCIAL_SERVICES" },
  { label: "Food and Beverage", value: "FOOD_AND_BEVERAGE" },
  { label: "Government Services", value: "GOVERNMENT_SERVICES" },
  { label: "Healthcare", value: "HEALTHCARE" },
  { label: "Human Resources", value: "HUMAN_RESOURCES" },
  { label: "Insurance", value: "INSURANCE" },
  { label: "IT Services", value: "IT_SERVICES" },
  { label: "Legal", value: "LEGAL" },
  {
    label: "Logistics and Transportation",
    value: "LOGISTICS_AND_TRANSPORTATION",
  },
  { label: "Manufacturing", value: "MANUFACTURING" },
  { label: "Media", value: "MEDIA" },
  { label: "Pharmaceuticals", value: "PHARMACEUTICALS" },
  { label: "Real Estate", value: "REAL_ESTATE" },
  { label: "Retail", value: "RETAIL" },
  { label: "Security", value: "SECURITY" },
  { label: "Software", value: "SOFTWARE" },
  { label: "Telecommunications", value: "TELECOMMUNICATIONS" },
  { label: "Travel and Hospitality", value: "TRAVEL_AND_HOSPITALITY" },
];
// constants.ts

export const COUNTRY_OPTIONS = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "IN", label: "India" },
  { value: "GB", label: "United Kingdom" },
  { value: "AU", label: "Australia" },
  { value: "SG", label: "Singapore" },
  { value: "DE", label: "Germany" },
  { value: "FR", label: "France" },
  { value: "IT", label: "Italy" },
  { value: "JP", label: "Japan" },
  { value: "CN", label: "China" },
  { value: "BR", label: "Brazil" },
  { value: "ZA", label: "South Africa" },
  { value: "MX", label: "Mexico" },
  { value: "RU", label: "Russia" },
  { value: "ES", label: "Spain" },
  { value: "KR", label: "South Korea" },
  { value: "NL", label: "Netherlands" },
  { value: "SE", label: "Sweden" },
  { value: "FI", label: "Finland" },
  { value: "NO", label: "Norway" },
  { value: "DK", label: "Denmark" },
  { value: "CH", label: "Switzerland" },
  { value: "BE", label: "Belgium" },
  { value: "PL", label: "Poland" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "MY", label: "Malaysia" },
  { value: "PH", label: "Philippines" },
  { value: "TH", label: "Thailand" },
  { value: "ID", label: "Indonesia" },
  { value: "NG", label: "Nigeria" },
  { value: "EG", label: "Egypt" },
  { value: "PK", label: "Pakistan" },
  { value: "KR", label: "South Korea" },
  { value: "VN", label: "Vietnam" },
  { value: "CL", label: "Chile" },
  { value: "PE", label: "Peru" },
  { value: "AR", label: "Argentina" },
  { value: "CO", label: "Colombia" },
  { value: "EC", label: "Ecuador" },
  { value: "PE", label: "Paraguay" },
  { value: "UY", label: "Uruguay" },
  { value: "TR", label: "Turkey" },
  { value: "KR", label: "South Korea" },
  { value: "BD", label: "Bangladesh" },
  { value: "RO", label: "Romania" },
  { value: "BG", label: "Bulgaria" },
  { value: "HR", label: "Croatia" },
  { value: "SI", label: "Slovenia" },
  { value: "UA", label: "Ukraine" },
  { value: "KE", label: "Kenya" },
  { value: "TZ", label: "Tanzania" },
  { value: "UG", label: "Uganda" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];
