import { Box, Text } from "@chakra-ui/react";
import PersanaExpertBg from "@/assets/images/expert-card.png";
import Icons from "@/components/Icons";

const ExpertCard = () => {
  return (
    <Box className="relative size-full max-w-2xl overflow-hidden rounded-[32px] mt-[8px] mb-[24px]">
      <img
        src={PersanaExpertBg}
        alt="Persana Expert"
        className="absolute inset-0 size-full object-cover"
      />

      <Box className="relative p-12" style={{ zIndex: 1 }}>
        <Box className="space-y-3">
          <Text className="text-[24px] font-medium leading-tight tracking-tight">
            Learn Persana
          </Text>
          <Text
            className="cursor-pointer bg-clip-text text-[14px] font-normal text-transparent flex items-center gap-2"
            style={{
              background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            onClick={() =>
              window.open(
                "https://persana.ai/learn-persana/overview-of-persana",
                "_blank",
              )
            }
          >
            Watch Demo
            <Icons.PlayIcon className="text-[#5024DC]" />
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpertCard;
