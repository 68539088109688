import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";

import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Icons from "../../Icons";
import { userStore } from "@/stores/user.store";

const CustomSelection = ({
  showLabel = false,
}: {
  showLabel?: "min" | "full" | false;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const [value, setValue] = React.useState("range-selection");
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const totalLeadsCount = searchLeadsResult?.profiles.length || 1000;
  const selectedLeadsCount = selectedLeads.length;
  const [rangeSelectionCount, setRangeSelectionCount] = useState<number>(
    () => selectionCount || 0,
  );

  const handleApplyClick = () => {
    if (value === "select-page") {
      updateLeadState({
        selectionAction: "select-page",
      });
    } else if (value === "select-all") {
      updateLeadState({
        selectionAction: "select-all",
      });
    } else if (value === "range-selection") {
      updateLeadState({
        selectionAction: "range-selection",
        selectionCount: rangeSelectionCount,
      });
    }
    onClose();
  };

  const getSelectionText = (extended = false) => {
    if (selectionAction === "select-all") {
      return extended ? "All Selected" : "All";
    }
    if (selectionAction === "range-selection") {
      return `${selectionCount} selected`;
    }
    if (selectedLeads.length) {
      return `${selectedLeads.length} selected`;
    }
    return "";
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <Tooltip label={getSelectionText(true)}>
        <MenuButton
          as={Button}
          className="joyride-select-leads relative !h-[35px] text-clip !text-[#5024DC] hover:!bg-[transparent]"
          onClick={onOpen}
        >
          <div className="!flex !items-center gap-1">
            <Checkbox
              sx={{
                "& .chakra-checkbox__control": {
                  background: selectedLeads.length ? "#5024DC" : "#fff",
                },
              }}
              isChecked={
                selectedLeadsCount === totalLeadsCount && totalLeadsCount > 0
              }
              isIndeterminate={
                selectedLeadsCount > 0 && selectedLeadsCount < totalLeadsCount
              }
            />
            {showLabel && (
              <p
                className="font-[500]] relative text-[14px] text-[#5024DC]"
                key={Math.random()}
              >
                {getSelectionText(showLabel === "full")}
              </p>
            )}

            <Icons.ArrowDownIcon />
          </div>
        </MenuButton>
      </Tooltip>

      <div className="portal-menu !z-[99999]">
        <MenuList className="!min-w-[400px] !max-w-[400px] !bg-white !p-0">
          <div className="py-6 px-4">
            <RadioGroup
              className="flex flex-col gap-3"
              onChange={(e) => setValue(e)}
              value={value}
            >
              {/* Range selection */}
              <Radio value="range-selection">Select number of people</Radio>
              {value === "range-selection" && (
                <div className="ml-6">
                  <Input
                    size={"md"}
                    value={rangeSelectionCount}
                    onChange={(e) => {
                      const val = e.target.value;
                      const numberVal = Number(val);
                      if (!isNaN(numberVal)) {
                        setRangeSelectionCount(numberVal);
                      }
                    }}
                  />
                  <p className="text-xs text-darkTextGray mt-2">
                    {planType?.toLocaleLowerCase() === "free" ? (
                      <>
                        Free users can export max 25 leads. For more leads
                        please upgrade.
                      </>
                    ) : (
                      <>
                        Paid users can export max 1000 leads. For more leads
                        please contact us.
                      </>
                    )}
                  </p>
                </div>
              )}

              {/* Page Selection */}
              <Radio value="select-page">
                Select this page{" "}
                <span className="text-xs text-darkGray">(20)</span>
              </Radio>

              {/* All Selection */}
              <Radio value="select-all">
                Select all{" "}
                <span className="text-xs text-darkGray">
                  {planType?.toLocaleLowerCase() === "free" ? `(25)` : `(1000)`}
                </span>
              </Radio>
            </RadioGroup>
          </div>

          <Divider />

          <div className="py-2 px-4 flex items-center justify-between">
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                updateLeadState({
                  selectionAction: "clear-all",
                });
                setValue("range-selection");
                setRangeSelectionCount(0);
                onClose();
              }}
            >
              Clear all
            </Button>
            <ButtonGroup>
              <Button size={"sm"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                size={"sm"}
                className={`!rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </ButtonGroup>
          </div>
        </MenuList>
      </div>
    </Menu>
  );
};

export default CustomSelection;
