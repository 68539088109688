import React, { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import RenderOptions from "./RenderOptions";

import { useTableStore } from "@/stores/table.store";
import type { CellsArr, Column } from "@/types/table.types";
import type { TSelectedColumnOption } from "@/types/common.types";

export type SetSelectedOption = React.Dispatch<
  React.SetStateAction<TSelectedColumnOption | null>
>;

interface Props {
  type?: "noInput" | "";
  selectedOption: TSelectedColumnOption | null;
  setSelectedOption:
    | SetSelectedOption
    | ((value: TSelectedColumnOption | null) => void);
  noInputFilteredColumns?: Column[];
  notInportal?: boolean;
  callFrom?: string;
  onNoMatch?: () => void;
  searchTextNoInput?: string | null;
  setContainerWidth?: (val: number) => void;
  hideInnerContent?: boolean;
  includeAIPersonalizationOption?: boolean;
}

const SelectColumnDropdown = ({
  type = "",
  selectedOption,
  setSelectedOption,
  notInportal = false,
  callFrom,
  onNoMatch,
  searchTextNoInput = null,
  setContainerWidth,
  hideInnerContent = false,
  includeAIPersonalizationOption = false,
}: Props) => {
  const inputContainerRef = React.useRef<HTMLDivElement>(null);
  const columns = useTableStore((state) => state.tableData.columns);
  const rowsData = useTableStore((state) => state.rowsData.slice(0, 10));

  const [availableColumns, setAvailableColumns] = useState(() => columns || []);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const personalizationOption = {
    name: "ai_personalization",
    metaData: {},
    _id: "",
  };
  useEffect(() => {
    if (typeof searchTextNoInput === "string") {
      handleSearchOption(searchTextNoInput);
    }
  }, [searchTextNoInput]);
  useEffect(() => {
    if (availableColumns.length === 0) {
      onNoMatch?.();
      return;
    }
  }, [availableColumns]);
  const handleSearchOption = (inputText: string) => {
    setSearchText(inputText);

    const filteredOptions = columns?.filter((column) =>
      !inputText.trim()
        ? true
        : column.name.toLowerCase().includes(inputText.toLowerCase()),
    );
    setAvailableColumns(filteredOptions);
  };
  const clearState = () => {
    setSearchText("");
    setIsSelectMenuOpen(false);
  };

  const SelectedOptionIcon = selectedOption?.iconType
    ? tableActionIcons[selectedOption?.iconType]
    : null;
  const isNoInput = type === "noInput";

  const rowsCells = rowsData.map((row) => row.cells);
  const rowsObjData = rowsCells.reduce((prevValue, currentValue) => {
    Object.entries(currentValue || {}).forEach(([key, value]) => {
      if (!prevValue[key]) {
        prevValue[key] = [];
      }
      prevValue[key].push(value);
    });

    return prevValue;
  }, {} as CellsArr);

  const enrichementColumns = columns?.filter(
    (column) => column?.metaData?.event === "modal",
  );
  const fullObjAddableColumns =
    callFrom === "use-ai" || callFrom === "ai-draft-email"
      ? enrichementColumns?.map((column) => {
          return {
            ...column,
            name: `${column.name} (Pass Full Value)`,
          };
        })
      : [];

  useEffect(() => {
    if (inputContainerRef.current && setContainerWidth)
      setContainerWidth(inputContainerRef?.current?.offsetWidth);
  }, [inputContainerRef, setContainerWidth]);

  return (
    <div className="relative w-full">
      <div className="relative" ref={inputContainerRef}>
        {!isNoInput && (
          <>
            {!isSelectMenuOpen ? (
              <div
                className={`h-[40px] w-full !min-w-fit border  border-primary px-2 py-1.5 text-base font-medium text-[#1D2026] ${
                  isSelectMenuOpen ? "border-b-gray-300 rounded-t" : "rounded"
                } flex items-center gap-x-2 pr-[3em]`}
                onClick={() => {
                  setTimeout(() => {
                    setIsSelectMenuOpen(true);
                  }, 200);
                }}
              >
                {!!SelectedOptionIcon && (
                  <SelectedOptionIcon className="h-auto w-4 text-[#676d7e]" />
                )}
                {selectedOption?.key || "Start typing or select a column"}
              </div>
            ) : (
              <div>
                <Input
                  className={`h-[40px] w-full  border border-primary px-2 py-1.5 text-base text-[#1D2026] focus:outline-none ${
                    isSelectMenuOpen ? "border-b-gray-300 rounded-t" : "rounded"
                  }`}
                  value={searchText}
                  onChange={(e) => {
                    handleSearchOption(e.target.value);
                  }}
                  onFocus={() => setIsSelectMenuOpen(true)}
                  onBlur={() => {
                    setSearchText("");
                    setTimeout(() => {
                      setIsSelectMenuOpen(false);
                    }, 300);
                  }}
                  autoFocus
                />
              </div>
            )}
            <Icons.ArrowDownIcon
              className={`absolute right-3.5 top-[50%] translate-y-[-50%] text-xl text-[#1D2026] duration-300${
                isSelectMenuOpen ? "rotate-180" : ""
              }`}
            />
            <Icons.CloseIcon
              onClick={() => {
                setSelectedOption(null);
              }}
              className="absolute right-[35px] top-[50%] translate-y-[-50%] cursor-pointer"
            />
          </>
        )}
      </div>
      <RenderOptions
        isNoInput={isNoInput}
        isSelectMenuOpen={isSelectMenuOpen}
        columns={
          includeAIPersonalizationOption
            ? [
                personalizationOption,
                ...availableColumns,
                ...fullObjAddableColumns,
              ]
            : [...availableColumns, ...fullObjAddableColumns]
        }
        setSelectedOption={setSelectedOption}
        clearState={clearState}
        rowsObjData={rowsObjData}
        selectedOption={selectedOption}
        inputContainerRef={inputContainerRef}
        notInportal={notInportal}
        hideInnerContent={hideInnerContent}
      />
    </div>
  );
};

export default React.memo(SelectColumnDropdown);
